import React from 'react';
import { Notification, User, UserProfile } from '../../../types';
import { ScheduledNotificationType } from '../../../types/scheduledNotification';
import 'flatpickr/dist/themes/airbnb.css';
import 'rc-time-picker/assets/index.css';

import './style.less';
import {
    fetchAllDeviceNotifications,
    // sendAlexaProactiveSuggestion,
    sendPersistentVisualAlert,
    sendAlexaDeviceNotification,
    deleteDeviceNotification,
    editDeviceNotification,
} from '../../../services/Notifications';
import { fetchAllActiveUsers } from '../../../services/Users';
import { fetchOneFacility, fetchResidentRooms } from '../../../services/Facilities';
import { fetchRoomGroups } from '../../../services/RoomGroups';
import { getScheduledNotifications, updateScheduledNotifications } from '../../../services/ScheduledNotifications';
import {
    Dimmer,
    Loader,
    Comment,
    Header,
    Message,
    Tab,
    Checkbox,
    List,
    Button,
    TransitionablePortal,
    Modal,
} from 'semantic-ui-react';
import NotificationItem from './NotificationItem';
import NotificationsForm from './Form';
import { AppState } from '../../../reducers';
import { connect } from 'react-redux';
import { deleteRoomGroup, createRoomGroup } from '../../../services/RoomGroups';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { toast } from 'react-toastify';
import { formatAndExportDataForPrint } from '../../../util/jsonTocsvDownloader';
import moment from "moment-timezone"
import { rrulestr } from 'rrule';
interface State {
    notifications: Notification[];
    users: User[];
    isFetching: boolean;
    error: string | null;
    isSaving: boolean;
    rooms: object[];
    roomsToNotify: number;
    notifQueue: number;
    roomGroups: object[];
    activeTabIndex: number;
    notificationsContent?: any;
    notificationsContentDisabled: boolean;
    scheduledNotifications: ScheduledNotificationType;
    showEditModal: boolean;
    showCreateModal: boolean;
    selectedNotification?: Notification;
    showDetailModal: boolean;
    selectedDate?: number;
    printBtnSpinner: boolean; 
    facilityTimeZone: string;
}

interface Props {
    profile: UserProfile | null;
    view: 'list' | undefined;
    residentA4hRoomId?: string;
}

class DeviceNotifications extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    state: State = {
        notifications: [],
        users: [],
        isFetching: false,
        error: null,
        isSaving: false,
        rooms: [{ key: 'all', text: 'All apartments', value: 'all' }],
        roomsToNotify: 0,
        notifQueue: 0,
        roomGroups: [],
        activeTabIndex: 0,
        notificationsContentDisabled: false,
        scheduledNotifications: {
            alerts: [],
        },
        showEditModal: false,
        showCreateModal: false,
        selectedNotification: undefined,
        showDetailModal: false,
        selectedDate: undefined,
        printBtnSpinner: false, 
        facilityTimeZone: ""
    };

    formatAndSortRooms(rooms) {
        const formattedRooms = rooms.map((obj) => {
            return {
                key: `${obj.RoomId}`,
                text: `${obj.Resident}`,
                value: `${obj.RoomId}`,
            };
        });

        const sortedRooms = formattedRooms.sort((a, b) => {
            const A = a.text.toUpperCase();
            const B = b.text.toUpperCase();

            if (A < B) {
                return -1;
            }
            if (A > B) {
                return 1;
            }
            return 0;
        });

        return sortedRooms;
    }

    formatAndSortRoomGroups(groups) {
        const formattedRoomGroups = groups.map((obj) => {
            return {
                key: `${obj._id}`,
                text: `${obj.Name} group`,
                value: `${[obj.RoomIds]}`,
            };
        });

        const sortedRoomGroups = formattedRoomGroups.sort((a, b) => {
            const A = a.text.toUpperCase();
            const B = b.text.toUpperCase();

            if (A < B) {
                return -1;
            }
            if (A > B) {
                return 1;
            }
            return 0;
        });

        this.setState({ roomGroups: sortedRoomGroups });

        return sortedRoomGroups;
    }

    async componentDidMount() {
        this.setState({ isFetching: true, error: null });
        try {
            const [_, users, roomGroups, residentRooms, { FacilityTimeZone = "America/New_York" }] = await Promise.all([
                this.refreshNotifications(),
                fetchAllActiveUsers(),
                fetchRoomGroups(),
                fetchResidentRooms(),
                // The below conditional code check for profile is to prevent ts error. If no profile exists, the user can't access this page. 
                fetchOneFacility(this.props.profile ? this.props.profile.Facility : "")
            ]);

            const sortedRooms = this.formatAndSortRooms(residentRooms);
            const sortedRoomGroups = this.formatAndSortRoomGroups(roomGroups);
            this.setState({
                facilityTimeZone: FacilityTimeZone
            })

            const roomOptions = [{ key: 'all', text: 'All apartments', value: 'all' }]
                .concat(sortedRoomGroups)
                .concat(sortedRooms);
            this.setState({ isFetching: false, users, rooms: roomOptions });
        } catch (e) {
            this.setState({ isFetching: false, error: e.message });
        }
    }

    async refreshNotifications() {
        this.setState({ isFetching: true });
        const rooms = await fetchResidentRooms();
        const notifications = await fetchAllDeviceNotifications();
        notifications.forEach((n) => {
            const roomNameRoomIdMap = rooms.reduce((acc, room) => {
                acc[room.RoomId] = room.Resident;
                return acc;
            }, {});

            const RoomNames = n.RoomIds.map((roomId) => roomNameRoomIdMap[roomId]);
            Object.assign(n, { RoomNames });
        });
        const scheduledNotifications = (await getScheduledNotifications({
            facilityId: this.props.profile && this.props.profile.Facility,
        })) || { alerts: [] };

        this.setState({ notifications, scheduledNotifications, isFetching: false });
    }

    async refreshRoomOptions() {
        this.setState({ isFetching: true, error: null });
        try {
            const [rooms, roomGroups] = await Promise.all([fetchResidentRooms(), fetchRoomGroups()]);

            const sortedRooms = this.formatAndSortRooms(rooms);
            const sortedRoomGroups = this.formatAndSortRoomGroups(roomGroups);

            const roomOptions = [{ key: 'all', text: 'All apartments', value: 'all' }]
                .concat(sortedRoomGroups)
                .concat(sortedRooms);
            this.setState({ isFetching: false, rooms: roomOptions });
        } catch (e) {
            this.setState({ isFetching: false, error: e.message });
        }
    }

    async handleSend(subject, message, roomIds, startDate, 
        // endDate
        ) {
        if (roomIds[0] === 'all') {
            roomIds = this.state.rooms.slice(1);
            roomIds = roomIds.map((room) => {
                return room.value;
            });
            roomIds = roomIds.filter((room) => {
                if (room.indexOf(',') === -1) return room;
            });
        }
        this.setState({ isSaving: true, error: null, roomsToNotify: roomIds.length });
        try {
            //notify 1 room at a time to escape lambda timeout
            // for (let i = 0; i < roomIds.length; i++) {
            // this.setState({ notifQueue: i });
            await sendAlexaDeviceNotification(message, subject, roomIds, startDate, 
                // endDate
                );

            try {
                this.setState({ error: null });
                // await sendAlexaProactiveSuggestion(subject, message, [roomIds[i]], startDate, endDate);
                await sendPersistentVisualAlert(message, roomIds, 5, startDate); 
                    // endDate,
                     
            } catch (e) {
                console.error(e);
                const errMsg =
                    (e.message !== '' && typeof e.message === 'string' && e.message) ||
                    'persistent visual alert failed';
                this.setState({ error: errMsg });
            }
            // }
            this.setState({ isSaving: false, showCreateModal: false });
            await this.refreshNotifications();
        } catch (e) {
            console.error(e);
            this.setState({ isSaving: false, error: e.message });
        }
    }

    findNotificationUser(notification: Notification): User | UserProfile | null {
        const res = this.state.users.find((u) => u._id === notification.AddedBy) || null;
        if (!res && this.props.profile) {
            return this.props.profile._id === notification.AddedBy ? this.props.profile : null;
        }
        return res;
    }

    async deleteRoomGroup(id) {
        await deleteRoomGroup(id);
        this.refreshRoomOptions();
    }

    async createRoomGroup(name, roomIds) {
        await createRoomGroup(name, roomIds);
        this.refreshRoomOptions();
    }

    handleMonthChange() {
        console.log('handle month change here');
    }

    handleDateClick(payload) {
        const todayDateStr = moment().startOf('day');
        const selectedDateMoment = moment(payload.dateStr);
        if (selectedDateMoment.isSameOrAfter(todayDateStr)) {
            const selectedDate = selectedDateMoment.hour(9).minute(0).second(0).toDate();
            this.setState({
                showCreateModal: true,
                selectedDate: selectedDate,
                selectedNotification: undefined,
            });
        } else {
            toast.warn("can't create notification in the past", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }

    handleEventClick(payload: any) {
        const selectedNotification = this.state.notifications.filter(
            (event) => event._id === payload.event._def.publicId,
        )[0];

        this.setState({
            selectedNotification,
            showDetailModal: true,
        });
    }

    panes = [{ menuItem: 'Active' }, { menuItem: 'History' }];

    setNotificationsContent = ((item, isChecked) => {
        let tempList = this.state.scheduledNotifications.alerts;
        if (isChecked) {
            tempList.push(item);
        } else {
            tempList = tempList.filter((listItem) => {
                return listItem !== item;
            });
        }
        this.setState({
            scheduledNotifications: {
                ...this.state.scheduledNotifications,
                alerts: tempList,
            },
        });
    }).bind(this);

    async handleNotificationsContentSubmit() {
        try {
            this.setState({ notificationsContentDisabled: true });
            const res = await updateScheduledNotifications({
                ...this.state.scheduledNotifications,
                facilityId: this.props.profile && this.props.profile.Facility, ///don't allow submit if facilityId doesn't exist
            });
            this.setState({ notificationsContentDisabled: false });
            console.log(res);
        } catch (e) {
            console.error(e);
        }
    }

    async deleteClickHandler(_id: string) {
        this.setState({
            isFetching: true,
        });
        try {
            await deleteDeviceNotification(_id);
            this.setState({
                showEditModal: false,
                showDetailModal: false,
            });
            toast.success('Successfully deleted notification', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            await this.refreshNotifications();
            this.setState({
                isFetching: false,
            });
        } catch (e) {
            console.log({ e });
            toast.error(e instanceof Error ? e.message : 'Something went wrong while deleting activity', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            this.setState({
                isFetching: false,
                showEditModal: false,
                showDetailModal: false,
            });
        }
    }

    async editClickHandler(id: string) {
        const selectedNotification = this.state.notifications.filter((e) => e._id === id)[0];
        console.log({ selectedNotification });
        this.setState({
            selectedNotification: selectedNotification,
            showEditModal: true,
        });
    }

    closeEditModalHandler() {
        this.setState({
            showEditModal: false,
        });
    }

    closeCreateModalHandler() {
        this.setState({
            showCreateModal: false,
        });
    }

    closeDetailModalHandler() {
        this.setState({
            showDetailModal: false,
        });
    }

    async handleEdit(subject: string, message: string, roomIds, startDateISO: string, 
        // endDateISO: string,
         _id) {
        console.log('entered edit');
        this.setState({
            showDetailModal: false,
        });

        if (roomIds[0] === 'all') {
            roomIds = this.state.rooms.slice(1);
            roomIds = roomIds.map((room) => {
                return room.value;
            });
            roomIds = roomIds.filter((room) => {
                if (room.indexOf(',') === -1) return room;
            });
        }
        this.setState({ isSaving: true, error: null });
        try {
            await editDeviceNotification({
                _id,
                subject,
                message,
                roomIds,
                triggerTimestampISO: startDateISO,
                // endDate: endDateISO,
            });
            this.setState({ isSaving: false, showEditModal: false });
            await this.refreshNotifications();
        } catch (e) {
            console.error(e);
            this.setState({ isSaving: false, error: e.message });
            toast.error(e.message, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }

    outerPanes = [
        {
            menuItem: 'Calendar',
            render: () => {
                return (
                    <div className="calendar-view">
                        <FullCalendar
                            plugins={[dayGridPlugin, interactionPlugin]}
                            initialView="dayGridMonth"
                            headerToolbar={{
                                right: 'today prev,next',
                            }}
                            customButtons={{
                                // comment out print button for now as it is not required for now but we might need it later.
                                // print: {
                                //     text: this.state.printBtnSpinner ? 'Loading...' : 'Print Calendar',
                                //     click: async (e) => {
                                //         e.preventDefault();
                                //         if (this.state.printBtnSpinner) {
                                //             toast.warn("Task in progress, please wait until it's completed.", {
                                //                 position: 'bottom-center',
                                //                 autoClose: 5000,
                                //                 hideProgressBar: false,
                                //                 closeOnClick: true,
                                //                 pauseOnHover: true,
                                //             });
                                //             return;
                                //         }
                                //         if (this.state.notifications && this.state.notifications.length) {
                                //             this.setState({ printBtnSpinner: true });
                                //             const recurrenceEvents: Notification[] = this.state.notifications
                                //                 .flatMap((event) => {
                                //                     if (!event.Recurrence) {
                                //                         return [];
                                //                     }
                                //                     const rule = event.Recurrence;
                                //                     const dates = rrulestr(rule).all();

                                //                     return dates.map((date) => ({
                                //                         ...event,
                                //                         dateAdded: date.getTime(),
                                //                         startTime: date.getTime(),
                                //                     }));
                                //                 });
                                //             const nonRecurringEvents = this.state.notifications.map((event) => {
                                //                 const startTime =
                                //                     event.triggerTimestampISO && new Date(event.triggerTimestampISO);
                                //                 return {
                                //                     ...event,
                                //                     ...(startTime ? { startTime } : { startTime: event.DateAdded }),
                                //                 };
                                //             });
                                //             const printCalendarPayload = [
                                //                 ...recurrenceEvents,
                                //                 ...nonRecurringEvents,
                                //             ];

                                //             const formattedExportData = printCalendarPayload.filter(notification => notification).map(notifcation => {
                                //                 return {
                                //                     Subject: notifcation && notifcation.Message,
                                //                     StartDate: notifcation && (notifcation.triggerTimestampISO || notifcation.DateAdded),
                                //                     StartTime: notifcation && (notifcation.triggerTimestampISO || notifcation.DateAdded)
                                //                 }
                                //             })

                                //             await formatAndExportDataForPrint(formattedExportData, 'Notifications', this.state.facilityTimeZone, "activity");
                                //             this.setState({ printBtnSpinner: false });
                                //         } else {
                                //             toast.warn("No notifications available for printing.", {
                                //                 position: 'bottom-center',
                                //                 autoClose: 5000,
                                //                 hideProgressBar: false,
                                //                 closeOnClick: true,
                                //                 pauseOnHover: true,
                                //             });
                                //             return;
                                //         }
                                //     },
                                // },
                            }}
                            events={[
                                ...this.state.notifications.map((event) => {
                                    const start = event.triggerTimestampISO || event.DateAdded;
                                    return {
                                        title: `${event.Subject ? ` ${event.Subject}  - ` : ''}   ${event.Message}`,
                                        start: new Date(start),
                                        // end: new Date(event.EndDate || ''),
                                        id: event._id,
                                    };
                                }),
                            ]}
                            datesSet={this.handleMonthChange.bind(this)}
                            dateClick={this.handleDateClick.bind(this)}
                            eventClick={this.handleEventClick.bind(this)}
                        />
                    </div>
                );
            },
        },
        {
            menuItem: 'Notifications',
            render: () => {
                const sortedNotification = this.state.notifications.sort((a, b) => b.DateAdded - a.DateAdded);
                const notificationItems = sortedNotification.map((n) => {
                    const notificationUser = this.findNotificationUser(n);
                    const userName = notificationUser
                        ? `${notificationUser.FirstName} ${notificationUser.LastName}`
                        : null;
                    const imageUrl = notificationUser ? notificationUser.Icon : null;

                    const now = new Date().toISOString();
                    // const event = n.EndDate || '';
                    const event = n.triggerTimestampISO || '';
                    //0: active, 1: history
                    return this.state.activeTabIndex
                        ? //only return past notifications
                        event < now && (
                            <NotificationItem
                                key={n._id}
                                userName={userName}
                                imageUrl={imageUrl}
                                notification={n}
                                deleteClickHandler={this.deleteClickHandler.bind(this)}
                                editNotification={this.editClickHandler.bind(this)}
                            />
                        )
                        : //only return active notifications
                        event > now && (
                            <NotificationItem
                                key={n._id}
                                userName={userName}
                                imageUrl={imageUrl}
                                notification={n}
                                deleteClickHandler={this.deleteClickHandler.bind(this)}
                                editNotification={this.editClickHandler.bind(this)}
                            />
                        );
                });

                console.log('props.view', this.props.view);
                return (
                    <>
                        {this.props.view === 'list' ? (
                            <div>
                                <Dimmer active={this.state.isFetching} inverted>
                                    <Loader active={this.state.isFetching} />
                                </Dimmer>
                                {this.state.error && <Message negative>{this.state.error}</Message>}

                                {this.state.notifications.length ? (
                                    <Comment.Group>
                                        <Tab
                                            menu={{ secondary: true, pointing: true }}
                                            panes={this.panes}
                                            onTabChange={(e, d) =>
                                                this.setState({ activeTabIndex: Number(d.activeIndex) })
                                            }
                                        />
                                        {notificationItems}
                                    </Comment.Group>
                                ) : (
                                    <></>
                                )}
                            </div>
                        ) : (
                            <div>
                                <Dimmer active={this.state.isFetching} inverted>
                                    <Loader active={this.state.isFetching} />
                                </Dimmer>
                                {this.state.error && <Message negative>{this.state.error}</Message>}

                                <Comment.Group>
                                    {this.state.isSaving && (
                                        <Header as="h4">
                                            Notifying room {this.state.notifQueue + 1} of {this.state.roomsToNotify}...
                                        </Header>
                                    )}
                                    {this.state.notifications.length && this.state.users.length ? (
                                        <>
                                            <Tab
                                                menu={{ secondary: true, pointing: true }}
                                                panes={this.panes}
                                                onTabChange={(e, d) =>
                                                    this.setState({ activeTabIndex: Number(d.activeIndex) })
                                                }
                                            />
                                            {notificationItems}
                                        </>
                                    ) : null}
                                </Comment.Group>
                            </div>
                        )}
                    </>
                );
            },
        },
    ];

    render() {
        return (
            <>
                <Dimmer active={this.state.isFetching} inverted>
                    <Loader active={this.state.isFetching} />
                </Dimmer>
                <div className="Notifications">
                    <NotificationsForm
                        residentA4hRoomId={this.props.residentA4hRoomId}
                        onSubmit={this.handleSend.bind(this)}
                        isSaving={this.state.isSaving}
                        roomOptions={this.state.rooms}
                        roomGroups={this.state.roomGroups}
                        deleteRoomGroup={this.deleteRoomGroup.bind(this)}
                        createRoomGroup={this.createRoomGroup.bind(this)}
                    />
                    {/* <Comment.Group>
                        {this.state.isSaving && (
                            <Header as="h4">
                                Notifying room {this.state.notifQueue + 1} of {this.state.roomsToNotify}...
                            </Header>
                        )}
                    </Comment.Group> */}
                    <Tab menu={{ secondary: true, pointing: true }} panes={this.outerPanes} />
                    <TransitionablePortal
                        open={this.state.showEditModal}
                        onClose={this.closeEditModalHandler.bind(this)}
                        size="large"
                        closeOnDocumentClick={false}
                    >
                        <Modal
                            open={this.state.showEditModal}
                            closeOnEscape
                            onClose={this.closeEditModalHandler.bind(this)}
                            size="large"
                            className="px-4"
                        >
                            <NotificationsForm
                                residentA4hRoomId={this.props.residentA4hRoomId}
                                onSubmit={this.handleEdit.bind(this)}
                                isSaving={this.state.isSaving}
                                roomOptions={this.state.rooms}
                                roomGroups={this.state.roomGroups}
                                deleteRoomGroup={this.deleteRoomGroup.bind(this)}
                                createRoomGroup={this.createRoomGroup.bind(this)}
                                selectedNotification={this.state.selectedNotification}
                            />
                        </Modal>
                    </TransitionablePortal>

                    <TransitionablePortal
                        open={this.state.showCreateModal}
                        onClose={this.closeCreateModalHandler.bind(this)}
                        size="large"
                        closeOnDocumentClick={false}
                    >
                        <Modal
                            open={this.state.showCreateModal}
                            closeOnEscape
                            onClose={this.closeCreateModalHandler.bind(this)}
                            size="large"
                            className="px-4"
                        >
                            <NotificationsForm
                                residentA4hRoomId={this.props.residentA4hRoomId}
                                onSubmit={this.handleSend.bind(this)}
                                isSaving={this.state.isSaving}
                                roomOptions={this.state.rooms}
                                roomGroups={this.state.roomGroups}
                                deleteRoomGroup={this.deleteRoomGroup.bind(this)}
                                createRoomGroup={this.createRoomGroup.bind(this)}
                                selectedNotification={this.state.selectedNotification}
                                selectedDate={this.state.selectedDate}
                            />
                        </Modal>
                    </TransitionablePortal>

                    <TransitionablePortal
                        open={this.state.showDetailModal}
                        onClose={this.closeDetailModalHandler.bind(this)}
                        closeOnDocumentClick={false}
                    >
                        <Modal
                            open={this.state.showDetailModal}
                            closeOnEscape
                            onClose={this.closeDetailModalHandler.bind(this)}
                            className="p-4"
                        >
                            {this.state.selectedNotification && (
                                <Comment.Group>
                                    <NotificationItem
                                        userName={
                                            this.state.selectedNotification.creatorInfo
                                                ? `${this.state.selectedNotification.creatorInfo.firstName} ${this.state.selectedNotification.creatorInfo.lastName}`
                                                : 'Unkown user'
                                        }
                                        imageUrl={
                                            this.state.selectedNotification.creatorInfo
                                                ? this.state.selectedNotification.creatorInfo.icon
                                                : ''
                                        }
                                        notification={this.state.selectedNotification}
                                        deleteClickHandler={this.deleteClickHandler.bind(this)}
                                        editNotification={this.editClickHandler.bind(this)}
                                        loading={this.state.isFetching}
                                    />
                                </Comment.Group>
                            )}
                        </Modal>
                    </TransitionablePortal>
                </div>
            </>
        );
    }
}

function mapStateToProps({ authReducer }: AppState) {
    return {
        profile: authReducer.profile,
    };
}

function mapDispatchToProps() {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceNotifications);
