import React from 'react'
import AssertAddress from '../Assertions/Address'
import AssertWifi from '../Assertions/Wifi'
import { Grid } from 'semantic-ui-react'

const Settings = () => {
    return (
        <div>
            <Grid columns={2} >
                <Grid.Row >
                    <Grid.Column style={{ border: "2px solid #DEDEDF", borderRadius: "10px", padding: "15px" }} width={5}>
                        <h3>Assert Address</h3>
                        <AssertAddress />
                    </Grid.Column>
                    <Grid.Column style={{ border: "2px solid #DEDEDF", borderRadius: "10px", padding: "15px", marginLeft: "20px" }}>
                        <h3>Assert Wifi</h3>
                        <AssertWifi />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    )
}

export default Settings