import React from 'react';
import ServicesTypesForm from '../../../../components/ServiceTypesForm';
import { Button } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
import { cookbook } from '../../../../util';
interface ServicesProps extends RouteComponentProps {
    source?: string;
}

const Services: React.FC<ServicesProps> = ({ history, source = "" }) => {
    return (
        <>
            <Button
                size="tiny"
                basic
                onClick={() => history.push(source === cookbook ? '/admin/cookbook' : '/admin/services/list')}>
                {source === cookbook ? 'Back To Cook Book' : 'Back to Services'}
            </Button>
            <ServicesTypesForm source={source} />
        </>
    );
};

export default withRouter(Services);
