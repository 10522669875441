import API from './API'
import { Unit, Identifiable } from '../types'

export async function fetchUnits(): Promise<Unit[]> {
    /*
        Updated to use lambdaPost because the back-end
        architecture of lambdaGet doesn't allow to pass filters
    */
    const res = await API.lambdaPost('/units/list', {
        Filter: { IsActive: 1 },
    })
    const units = res.Result as Unit[]
    return units.filter(u => u.IsActive === 1)
}

export async function fetchPaginatedUnits(page_no = 1, page_size = 100, facilityId = ''): Promise<Unit[]> {
    console.log('Page_no', page_no);
    const reqBody = {
        Filter: {
            IsActive: 1,
            Facility: facilityId
        },
        QueryOptions: {
            page_no,
            page_size,
            sort: [{ Name: "desc" }], // sort by Name in ascending order
        },
    };

    const res = await API.lambdaPost('/units/list', reqBody);

    const units = res.Result as Unit[]
    return units
}

export async function fetchAllUnits(fId = ''): Promise<Unit[]> {
    let page_no = 1;
    let units: any = [];
    let rooms: any = [];
    do {
        rooms = await fetchPaginatedUnits(page_no, 100, fId);
        if (rooms && rooms.length) {
            units.push(...rooms);
        }
        page_no = page_no + 1;
    } while (rooms.length === 100);

    return units;
}

export async function fetchOneUnit(id: string): Promise<Unit> {
    const res = await API.lambdaGet(`/units/get?_id=${id}`)
    return res as Unit
}

export async function fetchOneUnitWithA4hRoomId(a4hRoomId: string): Promise<Unit> {
    const Filter = {a4hRoomId}
    const res = await API.lambdaPost(`/units/get`, { Filter })
    return res as Unit
}

export async function createUnit(unit: Partial<Unit>): Promise<Unit> {
    const id = await API.lambdaPost('/units/add', unit)
    const created = await fetchOneUnit(id)
    return created
}

export async function updateUnit(Unit: Partial<Unit> & Identifiable): Promise<Unit> {
    if (!Unit._id) throw new Error('Updated Unit object must contain _id field')
    await API.lambdaPost('/units/update', Unit)
    const updated = await fetchOneUnit(Unit._id)
    return updated
}

export async function deleteUnit(unitId: String): Promise<any> {
    await API.lambdaPost('/units/update', {
        _id: unitId,
        IsActive: 0,
    })
}

export async function validateMoveRoom(destinationUnit :Unit): Promise<boolean> {
    return await API.lambdaPost('/units/validateMove', destinationUnit)
}

export async function fetchUnitsUsingParams(params, page_no, page_size) {
    try {
        const QueryOptions = {
            page_no,
            page_size,
        }
        const res = await API.lambdaPost('/units/list', { Filter: params, QueryOptions })
        return res.Result as Unit[]
    } catch (error) {
        console.error(error)
        throw error
    }
}
