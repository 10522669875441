import React, {FC, useEffect, useState} from 'react'
import { 
    Grid,
    Image,
    Dimmer,
    Loader
 } from 'semantic-ui-react'
import PhotoModal from './PhotoModal'

interface Props {
    imageUrls: string[]
    onImageClick?: (imageUrl: string) => void
}


const PhotoGrid: FC<Props> = ({imageUrls, onImageClick}) => {

    const [isPhotoModalOpen, setIsPhotoModalOpen] = useState<boolean>(false)
    const [imageLoadedStatus, setImageLoadedStatus] = useState<boolean>(true)
    const [photoModalImageUrl, setPhotoModalImageUrl] = useState<any | undefined>()
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState<number | undefined>()

    // Change this constant to change the number of columns in the photo grid
    const maxColumnsConst = 4
    
    const getPhotoRows = (photoUrls, maxCols) => {
        let photoRows:any[] = []
        let row:any[] = []
        const quotient = Math.floor(photoUrls.length/maxCols)
        for (let rowCtr = 0; rowCtr < quotient; ++rowCtr) {
            row = []
            for (let colCtr = 0; colCtr < maxCols; ++colCtr) {
                row.push(photoUrls[rowCtr*maxCols + colCtr])
            }
            photoRows.push(row)
        }

        const remainder = photoUrls.length % maxCols
        row = []
        for (let ctr = remainder; ctr > 0; --ctr) {
            row.push(photoUrls[photoUrls.length - ctr])
        }
        photoRows.push(row)

        return photoRows
    }

    const showPreviousPhoto = () => {
        const localCurrentPhotoIndex = getCurrentPhotoIndex(imageUrls, photoModalImageUrl)
        if (localCurrentPhotoIndex !== 0) {
            setPhotoModalImageUrl(imageUrls[localCurrentPhotoIndex - 1])
            setCurrentPhotoIndex(localCurrentPhotoIndex - 1)
        }
    }

    const showNextPhoto = () => {
        const localCurrentPhotoIndex = getCurrentPhotoIndex(imageUrls, photoModalImageUrl)
        if (localCurrentPhotoIndex !== (imageUrls.length - 1)) {
            setPhotoModalImageUrl(imageUrls[localCurrentPhotoIndex + 1])
            setCurrentPhotoIndex(localCurrentPhotoIndex + 1)
        }
    }

    const getCurrentPhotoIndex = (imgUrls, currentUrl) => {
        return imgUrls.findIndex(url => {
            return url === currentUrl
        })
    }

    if (imageUrls && imageUrls.length) {
        return (
            <>
                <Grid>
                <Dimmer active={imageLoadedStatus} inverted>
                    <Loader active={imageLoadedStatus} />
                </Dimmer>
                    {getPhotoRows(imageUrls, maxColumnsConst).map((row, rowIndex) => {
                        return (
                            <Grid.Row columns={maxColumnsConst} >
                                {row.map(imageUrl => {
                                    return (
                                        <Grid.Column 
                                            key={imageUrl} 
                                            onClick={e => {
                                                if(onImageClick) {
                                                    onImageClick(e.target.src)
                                                    return
                                                }
                                                setPhotoModalImageUrl(e.target.src)
                                                setCurrentPhotoIndex(getCurrentPhotoIndex(imageUrls, e.target.src))
                                                setIsPhotoModalOpen(true)
                                            }}
                                        >
                                        <Image rounded src={imageUrl} onLoad={() => setImageLoadedStatus(false)}/>
                                        </Grid.Column>
                                    )
                                })}
                            </Grid.Row>
                        )
                    })}
                </Grid>
                <PhotoModal
                    imageUrl={photoModalImageUrl}
                    onModalOpen={() => {
                        setIsPhotoModalOpen(true)
                    }}
                    onModalClose={() => {
                        setIsPhotoModalOpen(false)
                    }}
                    isPhotoModalOpen={isPhotoModalOpen}
                    showPreviousPhoto={showPreviousPhoto}
                    showNextPhoto={showNextPhoto}
                    disableModalPreviousButton={currentPhotoIndex === 0}
                    disableModalNextButton={currentPhotoIndex === (imageUrls.length - 1)}
                />
            </>
        )
    }
    return (
        <p style={{color: 'gray'}}>No photos yet</p>
    )
}

export default PhotoGrid
