import React, { FC, useEffect, useState } from 'react'
import { Dimmer, Loader, Tab } from 'semantic-ui-react'
import { upsertResidentInterests } from '../../services/Registrants';
import PrintExportIcons from '../PrintExportIcons';
import CheckboxItems from './CheckboxItems';
import useCheckbox from './useCheckbox';

interface Props {
    residentId: string
    residentName: string
    inactiveResident?: boolean
}

const ResidentProfile: FC<Props> = ({ residentId, residentName, inactiveResident = false }) => {
    const [checkboxItems, dispatch, loading] = useCheckbox(residentId, false);
    const [isLoading, setIsLoading] = useState(false)
    const [isFirstRender, setIsFirstRender] = useState(true)
    const [activeTab, setActiveTab] = useState(0)
    const [activeSubTab, setActiveSubTab] = useState({})

    const upsertResidentInterestsHandler = async () => {
        setIsLoading(true)
        await upsertResidentInterests(checkboxItems, residentId);
        setIsLoading(false)
    }

    useEffect(() => {
        if (isFirstRender) {
            setIsFirstRender(false)
        } else {
            (async () => {
                upsertResidentInterestsHandler()
            })()

        }
    }, [checkboxItems])


    const intellectualPanes = [
        {
            menuItem: 'Arts',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        readonly={inactiveResident}
                        category="Intellectual"
                        subCategory='arts'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Technology',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        readonly={inactiveResident}
                        category="Intellectual"
                        subCategory='tech'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },

        {
            menuItem: 'Science',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        readonly={inactiveResident}
                        category="Intellectual"
                        subCategory='science'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },

        {
            menuItem: 'US and World',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        readonly={inactiveResident}
                        category="Intellectual"
                        subCategory='USAndWorld'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },


        {
            menuItem: 'Challenge',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        readonly={inactiveResident}
                        category="Intellectual"
                        subCategory='challenge'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },

        {
            menuItem: 'Media',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        readonly={inactiveResident}
                        category="Intellectual"
                        subCategory='media'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        }

    ]

    const occupationalPanes = [
        {
            menuItem: 'Community Engagement',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        readonly={inactiveResident}
                        category="Occupational"
                        subCategory='communityEngagement'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Intergenerational',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        readonly={inactiveResident}
                        category="Occupational"
                        subCategory='intergenerational'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },

        {
            menuItem: 'Art and Craft',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        readonly={inactiveResident}
                        category="Occupational"
                        subCategory='artAndCraft'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
    ]

    const physicalPanes = [
        {
            menuItem: 'Dance',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        readonly={inactiveResident}
                        category="Physical"
                        subCategory='dance'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Fitness',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        readonly={inactiveResident}
                        category="Physical"
                        subCategory='fitness'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },

        {
            menuItem: 'Games',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        readonly={inactiveResident}
                        category="Physical"
                        subCategory='games'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Self-Care',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        readonly={inactiveResident}
                        category="Physical"
                        subCategory='selfCare'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
    ]

    const socialPanes = [
        {
            menuItem: 'Community',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        readonly={inactiveResident}
                        category="Social"
                        subCategory='community'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Clubs',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        readonly={inactiveResident}
                        category="Social"
                        subCategory='clubs'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },

        {
            menuItem: 'Outings',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        readonly={inactiveResident}
                        category="Social"
                        subCategory='outings'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Games',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        readonly={inactiveResident}
                        category="Social"
                        subCategory='games'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Physical Games',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        readonly={inactiveResident}
                        category="Social"
                        subCategory='physicalGames'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
    ]
    const emotionalPanes = [
        {
            menuItem: 'Music',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        readonly={inactiveResident}
                        category="Emotional"
                        subCategory='music'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Family',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        readonly={inactiveResident}
                        category="Emotional"
                        subCategory='family'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },

        {
            menuItem: 'Nurturing',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        readonly={inactiveResident}
                        category="Emotional"
                        subCategory='nurturing'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },

        {
            menuItem: 'Reminiscence',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        readonly={inactiveResident}
                        category="Emotional"
                        subCategory='reminiscence'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },

        {
            menuItem: 'Therapies',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        readonly={inactiveResident}
                        category="Emotional"
                        subCategory='therapies'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        },
    ]

    const spiritualPanes = [
        {
            menuItem: 'Spiritual',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        readonly={inactiveResident}
                        category="Spiritual"
                        subCategory='spiritual'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        }
    ]

    const environmentalPanes = [
        {
            menuItem: 'Environmental',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        readonly={inactiveResident}
                        category="Environmental"
                        subCategory='environmental'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        }
    ]

    const virtualPanes = [
        {
            menuItem: 'Virtual',
            render: () => (
                <Tab.Pane attached={false}>
                    <CheckboxItems
                        readonly={inactiveResident}
                        category="Virtual"
                        subCategory='virtual'
                        checkboxItems={checkboxItems}
                        dispatch={dispatch}
                    />
                </Tab.Pane>
            ),
        }
    ]

    const panes = [
        {
            menuItem: 'Intellectual',
            render: () => (
                <Tab.Pane attached={false}>
                    <Tab
                        panes={intellectualPanes}
                        activeIndex={activeSubTab[0] || 0}
                        onTabChange={(e, { activeIndex }) => {
                            setActiveSubTab((prev) => {
                                return {
                                    ...prev,
                                    [0]: activeIndex
                                }
                            })
                        }}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Occupational',
            render: () => (
                <Tab.Pane attached={false}>
                    <Tab
                        panes={occupationalPanes}
                        activeIndex={activeSubTab[1] || 0}
                        onTabChange={(e, { activeIndex }) => {
                            setActiveSubTab((prev) => {
                                return {
                                    ...prev,
                                    [1]: activeIndex
                                }
                            })
                        }}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Physical',
            render: () => (
                <Tab.Pane attached={false}>
                    <Tab
                        panes={physicalPanes}
                        activeIndex={activeSubTab[2] || 0}
                        onTabChange={(e, { activeIndex }) => {
                            setActiveSubTab((prev) => {
                                return {
                                    ...prev,
                                    [2]: activeIndex
                                }
                            })
                        }}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Social',
            render: () => (
                <Tab.Pane attached={false}>
                    <Tab
                        panes={socialPanes}
                        activeIndex={activeSubTab[3] || 0}
                        onTabChange={(e, { activeIndex }) => {
                            setActiveSubTab((prev) => {
                                return {
                                    ...prev,
                                    [3]: activeIndex
                                }
                            })
                        }}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Emotional',
            render: () => (
                <Tab.Pane attached={false}>
                    <Tab
                        panes={emotionalPanes}
                        activeIndex={activeSubTab[4] || 0}
                        onTabChange={(e, { activeIndex }) => {
                            setActiveSubTab((prev) => {
                                return {
                                    ...prev,
                                    [4]: activeIndex
                                }
                            })
                        }}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Spiritual',
            render: () => (
                <Tab.Pane attached={false}>
                    <Tab
                        panes={spiritualPanes}
                        activeIndex={activeSubTab[5] || 0}
                        onTabChange={(e, { activeIndex }) => {
                            setActiveSubTab((prev) => {
                                return {
                                    ...prev,
                                    [5]: activeIndex
                                }
                            })
                        }}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Environmental',
            render: () => (
                <Tab.Pane attached={false}>
                    <Tab
                        panes={environmentalPanes}
                        activeIndex={activeSubTab[6] || 0}
                        onTabChange={(e, { activeIndex }) => {
                            setActiveSubTab((prev) => {
                                return {
                                    ...prev,
                                    [6]: activeIndex
                                }
                            })
                        }}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Virtual',
            render: () => (
                <Tab.Pane attached={false}>
                    <Tab
                        panes={virtualPanes}
                        activeIndex={activeSubTab[7] || 0}
                        onTabChange={(e, { activeIndex }) => {
                            setActiveSubTab((prev) => {
                                return {
                                    ...prev,
                                    [7]: activeIndex
                                }
                            })
                        }}
                    />
                </Tab.Pane>
            ),
        }
    ]

    const getExportData = () => {
        const exportData: {} = {};

        const subKeys = ["Emotional", "Environmental", "Intellectual", "Physical", "Social", "Spiritual", "Virtual"];
        const selectedSubCategories = Object.keys(checkboxItems).filter((checkboxItem) =>
            subKeys.includes(checkboxItem)
        );

        selectedSubCategories.forEach((key) => {
            const subEntries = Object.entries(checkboxItems[key]).map(([subKey, subValue]) => ({
                key: subKey,
                value: subValue
            }));
            subEntries.forEach((subEntry: any) => {
                Object.entries(subEntry.value).forEach(([mainKey, mainValue]) => {
                    exportData[`${key}.${subEntry.key}.${mainKey}`] = mainValue;
                });
            });
        });
        return [exportData];
    };

    return (
        <div style={{ margin: "15px 5px", position: "relative" }}>
            <Dimmer active={isLoading || loading} inverted>
                <Loader active={isLoading || loading} />
            </Dimmer>
            <div style={{ margin: "15px 5px" }}>
                <PrintExportIcons disablePrintButton={true} exportData={getExportData()} exportFileName={`${residentName}-profile`} onPrintClick={() => { }} disableExportButton={isLoading} />
            </div>
            <Tab
                panes={panes}
                activeIndex={activeTab}
                onTabChange={(e, { activeIndex }) => {
                    if (activeIndex !== undefined) { // can't use !activeIndex as that will not work for 0th tab
                        setActiveTab(Number(activeIndex))
                    }
                }}
            />
        </div>
    )
}

export default ResidentProfile