import React, { Fragment } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// import Requests from './pages/StaffApp/Requests/Unassigned'
import StaffDashboard from './pages/StaffApp/Requests/StaffDashboard'
import SingleRequest from './pages/StaffApp/Requests/SingleRequest'
import Login from './pages/Login'
import NewPassword from './pages/NewPassword'
import ProfileSettings from './pages/ProfileSettings'
import Navigation from './components/Navigation'
import ProtectedRoute from './components/ProtectedRoute'

import './App.css'
import 'semantic-ui-less/semantic.less'

import { AppState } from './reducers'
import * as actions from './actions/auth'
import { connect } from 'react-redux'
import { Sidebar, Segment, Dimmer, Loader, Grid } from 'semantic-ui-react'
import { CurrentUser, UserProfile } from './types'
import MyRequests from './pages/StaffApp/Requests/MyRequests'

import AdminHome from './pages/AdminPanel/Home'
import AdminSingleRequest from './pages/AdminPanel/Requests/Single'
import {
    canManageRequests,
    canManageUsers,
    canManageRegistrants,
    canManageFacilities,
    canAccessFacilityForms,
    canManageNotifications,
    isSysAdmin,
    canManageDailyActivities,
    canManageMenus,
    canSeeDashboard,
    canManageUniversityTrainings,
    canManageStandardUniversityTrainings,
    canManageCheckin,
    isFacilityTabEnabled
} from './services/Permissions'
import UsersHome from './pages/AdminPanel/Staff/Home'
import CreateUser from './pages/AdminPanel/Staff/Create'
import EditUser from './pages/AdminPanel/Staff/Edit'
import CreateRegistrant from './pages/AdminPanel/Registrants/Create'
import EditRegistrant from './pages/AdminPanel/Registrants/Edit'
import ListRegistrants from './pages/AdminPanel/Registrants/List'
import ListFacilities from './pages/AdminPanel/Facilities/List'
import EditFacility from './pages/AdminPanel/Facilities/Edit'
import CreateFacility from './pages/AdminPanel/Facilities/Create'
import Notifications from './pages/AdminPanel/Notifications'
import DeviceNotifications from './pages/AdminPanel/DeviceNotifications'
import { ThunkDispatch } from 'redux-thunk'
import FormsIndex from './pages/AdminPanel/FormsIndex'
import DailyActivities from './pages/AdminPanel/DailyActivities'
import Menu from './pages/AdminPanel/Menu'
import CheckInPage from './pages/AdminPanel/Checkin'
import Reports from './pages/AdminPanel/Reports'
import CloneData from './pages/AdminPanel/CloneData'
import ListUniversityTrainings from './pages/AdminPanel/UniversityTrainings/List'
import CreateUniversityTrainings from './pages/AdminPanel/UniversityTrainings/Create'
import EditUniversityTrainings from './pages/AdminPanel/UniversityTrainings/Edit'
import ListStandardUniversityTrainings from './pages/AdminPanel/StandardUniversityTrainings/List'
import CreateStandardUniversityTrainings from './pages/AdminPanel/StandardUniversityTrainings/Create'
import EditStandardUniversityTrainings from './pages/AdminPanel/StandardUniversityTrainings/Edit'
import Speak2FamilyBroadcastIncomingList from './pages/AdminPanel/Speak2FamilyBroadcast/List'
import ResidentOfTheMonth from './pages/AdminPanel/ResidentOfTheMonth'
import StaffOfTheMonth from './pages/AdminPanel/StaffOfTheMonth'
import DailyAffirmations from './pages/AdminPanel/DailyAffirmations'
import RequestsHome from './pages/AdminPanel/Requests'
import ManageSkills from './pages/AdminPanel/ManageSkills'
import Birthdays from './pages/AdminPanel/Birthdays'
import Signage from './pages/AdminPanel/Signage'
import CustomSidebar from './components/CustomSidebar/CustomSidebar'
import Devices from './pages/AdminPanel/Devices'
import Services from './pages/AdminPanel/Services/Upsert';
import ListServiceTypes from './pages/AdminPanel/Services/List';
import Assets from './pages/AdminPanel/Assets';
import WebRTCToggle from './pages/AdminPanel/WebRTCToggle';
import TURNBilling from './pages/AdminPanel/TURNBilling'
import KioskQuestions from './components/kioskQuestions';
import './assets/fontawesome/css/fontawesome.css'; // parent css class required for child css to work
import './assets/fontawesome/css/light.css'; // import the relevant child css classes manually according to usage e.g fa-thin, fa-sharp etc.
import './assets/fontawesome/css/thin.css';
import './assets/fontawesome/css/sharp-light.css'
import Groups from './pages/AdminPanel/Groups';
import Docs from './pages/AdminPanel/Docs';
import Notepad from './pages/AdminPanel/Notepad';
import CreateRequestModal from './components/Requests/CreateRequestModal';
import Update from './pages/AdminPanel/Services/Update';
import SvcInstanceDetailedView from './components/ServiceInstances/SvcInstanceDetailedView';
import DisabledContentPage from './pages/StaffApp/Requests/StaffDashboard/DisabledContentPage';
import Dining from './pages/AdminPanel/Dining';
import Cookbook from './pages/AdminPanel/Cookbook';
import { cookbook } from './util/index';
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
import Settings from './pages/AdminPanel/Settings/Settings'
import CommunityLiaisons from './pages/AdminPanel/CommunityLiaisons';

toast.configure({ autoClose: 1000 })

interface Props {
    isAuthenticated: boolean
    currentUser: CurrentUser | null
    profile: UserProfile | null
    passwordChangeRequired: boolean
    onLogOut: (profile: UserProfile | null) => void
    isLoading: boolean
}
interface State {
    showSidebar: boolean
}

class App extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            showSidebar: false,
        }
    }

    setShowSidebar(to: boolean) {
        this.setState({
            showSidebar: to,
        })
    }

    render() {
        let HomePage: any = canManageRequests(this.props.profile)
            ? (props: any) => <AdminHome profile={this.props.profile} />
            : (props: any) => isFacilityTabEnabled(this.props.profile,"requests") ? <StaffDashboard profile={this.props.profile}/> : <DisabledContentPage/>
        if (isSysAdmin(this.props.profile)) HomePage = (props: any) => <UsersHome />

        return (
            <CacheBuster
                currentVersion={version}
                isEnabled={true} //If false, the library is disabled.
            >
                <Fragment>
                    <Router>
                        <Sidebar.Pushable as={Segment} className="App">
                            <Navigation
                                showSidebar={this.state.showSidebar}
                                setShowSidebar={this.setShowSidebar.bind(this)}
                                currentUser={this.props.currentUser}
                                profile={this.props.profile}
                                logOut={(userprofile) => this.props.onLogOut(userprofile)}
                                isAuthenticated={this.props.isAuthenticated}
                            />
                            <div style={this.props.profile ? { maxWidth: '100%', overflowX: 'auto', height: '88.9vh' } : {}}>
                                <Grid padded>
                                    <Grid.Row columns={2} style={{ width: '1600px', minWidth: '1400px', padding: 0 }}>
                                        {this.props.profile ? (
                                            <Grid.Column width={2} style={{ padding: '20px 0 0 14px' }}>
                                                <CustomSidebar />
                                            </Grid.Column>
                                        ) : (
                                            <></>
                                        )}
                                        <Grid.Column width={14} style={{ padding: '20px 0 0 0' }}>
                                            <Sidebar.Pusher>
                                                <Switch>
                                                    <ProtectedRoute
                                                        path="/"
                                                        exact
                                                        render={HomePage}
                                                        condition={this.props.isAuthenticated}
                                                        redirect="/login"
                                                    />
                                                    <ProtectedRoute
                                                        path="/myrequests"
                                                        exact
                                                        component={MyRequests}
                                                        condition={this.props.isAuthenticated}
                                                        redirect="/login"
                                                    />
                                                    <ProtectedRoute
                                                        path="/request/:id"
                                                        exact
                                                        component={SingleRequest}
                                                        condition={this.props.isAuthenticated}
                                                        redirect="/login"
                                                    />
                                                    <ProtectedRoute
                                                        path="/newpassword"
                                                        component={NewPassword}
                                                        condition={this.props.passwordChangeRequired}
                                                        redirect="/"
                                                    />
                                                    <ProtectedRoute
                                                        path="/profile"
                                                        component={ProfileSettings}
                                                        condition={this.props.isAuthenticated}
                                                        redirect="/"
                                                    />
                                                    <ProtectedRoute
                                                        path="/admin/request/:id"
                                                        exact
                                                        component={AdminSingleRequest}
                                                        condition={canManageRequests(this.props.profile) && isFacilityTabEnabled(this.props.profile, 'requests')}
                                                        redirect="/"
                                                    />
                                                    <ProtectedRoute
                                                        path="/admin/requests/create"
                                                        exact
                                                        component={() =>
                                                            <Segment style={{ width: '40%', margin: 'auto' }}>
                                                                <CreateRequestModal
                                                                    facilityId={this.props.profile ? this.props.profile.Facility : ''}
                                                                />
                                                            </Segment>}
                                                        condition={canManageRequests(this.props.profile) && isFacilityTabEnabled(this.props.profile, 'requests')}
                                                        redirect="/"
                                                    />
                                                    <ProtectedRoute
                                                        path="/admin/requests"
                                                        exact
                                                        component={() => <RequestsHome profile={this.props.profile} />}
                                                        condition={this.props.isAuthenticated && isFacilityTabEnabled(this.props.profile, 'requests')}
                                                        redirect="/"
                                                    />

                                                    <ProtectedRoute
                                                        path="/admin/users/list"
                                                        exact
                                                        component={UsersHome}
                                                        condition={canManageUsers(this.props.profile)}
                                                        redirect="/"
                                                    />
                                                    <ProtectedRoute
                                                        path="/admin/users/create"
                                                        exact
                                                        render={(props: any) => (
                                                            <CreateUser {...props} profile={this.props.profile} />
                                                        )}
                                                        condition={canManageUsers(this.props.profile)}
                                                        redirect="/"
                                                    />
                                                    <ProtectedRoute
                                                        path="/admin/user/:id"
                                                        exact
                                                        component={UsersHome}
                                                        condition={canManageUsers(this.props.profile)}
                                                        redirect="/"
                                                    />
                                                     <ProtectedRoute
                                                        path="/admin/settings"
                                                        exact
                                                        component={Settings}
                                                        condition={canManageUsers(this.props.profile)}
                                                        redirect="/"
                                                    />
                                                    <ProtectedRoute
                                                        path="/admin/registrants/list"
                                                        exact
                                                        component={ListRegistrants}
                                                        condition={canManageRegistrants(this.props.profile)}
                                                        redirect="/"
                                                    />
                                                    {/* For resident search on home page */}
                                                    <ProtectedRoute
                                                        path="/admin/registrants/list/:residentToSearch"
                                                        exact
                                                        component={ListRegistrants}
                                                        condition={canManageRegistrants(this.props.profile)}
                                                        redirect="/"
                                                    />
                                                    <ProtectedRoute
                                                        path="/admin/registrants/create"
                                                        exact
                                                        component={CreateRegistrant}
                                                        condition={canManageRegistrants(this.props.profile)}
                                                        redirect="/"
                                                    />
                                                    <ProtectedRoute
                                                        path="/admin/registrant/list/:id"
                                                        exact
                                                        component={ListRegistrants}
                                                        condition={canManageRegistrants(this.props.profile)}
                                                        redirect="/"
                                                    />

                                                    <ProtectedRoute
                                                        path="/admin/facilities/list"
                                                        exact
                                                        component={ListFacilities}
                                                        condition={canManageFacilities(this.props.profile)}
                                                        redirect="/"
                                                    />
                                                    <ProtectedRoute
                                                        path="/admin/facilities/create"
                                                        exact
                                                        component={CreateFacility}
                                                        condition={canManageFacilities(this.props.profile)}
                                                        redirect="/"
                                                    />
                                                    <ProtectedRoute
                                                        path="/admin/facility/:id"
                                                        exact
                                                        component={EditFacility}
                                                        condition={canManageFacilities(this.props.profile)}
                                                        redirect="/"
                                                    />
                                                    <ProtectedRoute
                                                        path="/admin/forms/"
                                                        component={FormsIndex}
                                                        condition={canAccessFacilityForms(this.props.profile)}
                                                        redirect="/"
                                                    />
                                                    <ProtectedRoute
                                                        path="/admin/announcements"
                                                        exact
                                                        component={Notifications}
                                                        condition={canManageNotifications(this.props.profile)}
                                                        redirect="/"
                                                    />
                                                    <ProtectedRoute
                                                        path="/admin/notifications"
                                                        exact
                                                        component={DeviceNotifications}
                                                        condition={canManageNotifications(this.props.profile)}
                                                        redirect="/"
                                                    />
                                                    <ProtectedRoute
                                                        path="/admin/dailyactivities"
                                                        exact
                                                        component={() => <DailyActivities profile={this.props.profile} />}
                                                        condition={canManageDailyActivities(this.props.profile)}
                                                        redirect="/"
                                                        profile={this.props.profile}
                                                    />
                                                    <ProtectedRoute
                                                        path={'/admin/dailyactivities/serviceinstance/:id'}
                                                        exact
                                                        component={() => <SvcInstanceDetailedView source="DailyActivities" />}
                                                        redirect="/"
                                                        condition={canManageUsers(this.props.profile)}
                                                    />
                                                    <ProtectedRoute
                                                        path={'/admin/dailyactivities/serviceinstance/:id'}
                                                        exact
                                                        component={() => <SvcInstanceDetailedView source="DailyActivities" />}
                                                        redirect="/"
                                                        condition={canManageDailyActivities(this.props.profile)}
                                                    />
                                                    <ProtectedRoute
                                                        path="/admin/dailyactivities/:id/:recurringActivities"
                                                        exact
                                                        component={() => <DailyActivities profile={this.props.profile} />}
                                                        condition={canManageDailyActivities(this.props.profile)}
                                                        redirect="/"
                                                        profile={this.props.profile}
                                                    />
                                                    <ProtectedRoute
                                                        path="/admin/menu"
                                                        exact
                                                        component={() => <Menu />}
                                                        condition={canManageMenus(this.props.profile)}
                                                        redirect="/"
                                                        profile={this.props.profile}
                                                    />
                                                    <ProtectedRoute
                                                        path={'/admin/menu/serviceinstance/:id'}
                                                        exact
                                                        component={() => <SvcInstanceDetailedView source="Menu" />}
                                                        redirect="/"
                                                        condition={canManageMenus(this.props.profile)}
                                                    />
                                                    <ProtectedRoute
                                                        path="/admin/menu/:id/:recurringActivities"
                                                        exact
                                                        component={() => <Menu />}
                                                        condition={canManageMenus(this.props.profile)}
                                                        redirect="/"
                                                        profile={this.props.profile}
                                                    />
                                                    <ProtectedRoute
                                                        path="/admin/checkin"
                                                        exact
                                                        component={() => <CheckInPage profile={this.props.profile} />}
                                                        condition={canManageCheckin(this.props.profile)}
                                                        redirect="/"
                                                        profile={this.props.profile}
                                                    />
                                                    <ProtectedRoute
                                                        path="/admin/reports"
                                                        exact
                                                        condition={canSeeDashboard(this.props.profile)}
                                                        redirect="/"
                                                        component={() => <Reports profile={this.props.profile} />}
                                                        profile={this.props.profile}
                                                    />
                                                    <ProtectedRoute
                                                        path="/admin/clone-data"
                                                        exact
                                                        component={CloneData}
                                                        condition={canManageFacilities(this.props.profile)}
                                                        redirect="/"
                                                    />
                                                    <ProtectedRoute
                                                        path="/admin/devices"
                                                        exact
                                                        component={Devices}
                                                        condition={canManageUsers(this.props.profile)}
                                                        redirect="/"
                                                    />
                                                    <ProtectedRoute
                                                        path="/admin/toggle-webrtc"
                                                        exact
                                                        component={WebRTCToggle}
                                                        condition={canManageUsers(this.props.profile)}
                                                        redirect="/"
                                                    />

                                                    <ProtectedRoute
                                                        path="/admin/university/trainings/list"
                                                        exact
                                                        component={() => (
                                                            <ListUniversityTrainings profile={this.props.profile} />
                                                        )}
                                                        condition={canManageUniversityTrainings(this.props.profile)}
                                                        redirect="/"
                                                        profile={this.props.profile}
                                                    />
                                                    <ProtectedRoute
                                                        path="/admin/university/trainings/create"
                                                        exact
                                                        component={() => (
                                                            <CreateUniversityTrainings profile={this.props.profile} />
                                                        )}
                                                        condition={canManageUniversityTrainings(this.props.profile)}
                                                        redirect="/"
                                                        profile={this.props.profile}
                                                    />
                                                    <ProtectedRoute
                                                        path="/admin/university/trainings/:id"
                                                        exact
                                                        component={() => (
                                                            <EditUniversityTrainings profile={this.props.profile} />
                                                        )}
                                                        condition={canManageUniversityTrainings(this.props.profile)}
                                                        redirect="/"
                                                        profile={this.props.profile}
                                                    />
                                                    <ProtectedRoute
                                                        path="/admin/university/standard-trainings/list"
                                                        exact
                                                        component={() => (
                                                            <ListStandardUniversityTrainings profile={this.props.profile} />
                                                        )}
                                                        condition={canManageStandardUniversityTrainings(this.props.profile)}
                                                        redirect="/"
                                                        profile={this.props.profile}
                                                    />
                                                    <ProtectedRoute
                                                        path="/admin/university/standard-trainings/create"
                                                        exact
                                                        component={() => (
                                                            <CreateStandardUniversityTrainings
                                                                profile={this.props.profile}
                                                            />
                                                        )}
                                                        condition={canManageStandardUniversityTrainings(this.props.profile)}
                                                        redirect="/"
                                                        profile={this.props.profile}
                                                    />
                                                    <ProtectedRoute
                                                        path="/admin/university/standard-trainings/:id"
                                                        exact
                                                        component={() => (
                                                            <EditStandardUniversityTrainings profile={this.props.profile} />
                                                        )}
                                                        condition={canManageStandardUniversityTrainings(this.props.profile)}
                                                        redirect="/"
                                                        profile={this.props.profile}
                                                    />
                                                    <ProtectedRoute
                                                        path="/admin/speak2-pals"
                                                        exact
                                                        component={Speak2FamilyBroadcastIncomingList}
                                                        condition={canManageUsers(this.props.profile)}
                                                        redirect="/"
                                                    />
                                                    <ProtectedRoute
                                                        path="/admin/resident-of-the-month"
                                                        exact
                                                        component={ResidentOfTheMonth}
                                                        condition={canManageUsers(this.props.profile)}
                                                        redirect="/"
                                                    />
                                                    <ProtectedRoute
                                                        path="/admin/staff-of-the-month"
                                                        exact
                                                        component={StaffOfTheMonth}
                                                        condition={canManageUsers(this.props.profile)}
                                                        redirect="/"
                                                    />
                                                    <ProtectedRoute
                                                        path="/admin/community-messages"
                                                        exact
                                                        component={DailyAffirmations}
                                                        condition={canManageUsers(this.props.profile)}
                                                        redirect="/"
                                                    />
                                                    <ProtectedRoute
                                                        path="/sysadmin/skills"
                                                        exact
                                                        component={() => <ManageSkills managePropertiesSkills={true} />}
                                                        condition={isSysAdmin(this.props.profile)}
                                                        redirect="/"
                                                    />
                                                    <ProtectedRoute
                                                        path={'/admin/birthdays'}
                                                        exact
                                                        component={() => <Birthdays />}
                                                        redirect="/"
                                                        condition={canManageUsers(this.props.profile)}
                                                    />
                                                    <ProtectedRoute
                                                        path={'/admin/signage'}
                                                        exact
                                                        component={() => <Signage />}
                                                        redirect="/"
                                                        condition={canManageUsers(this.props.profile) && isFacilityTabEnabled(this.props.profile, 'signage')}
                                                    />
                                                    <ProtectedRoute
                                                        path={'/admin/notepad'}
                                                        exact
                                                        component={() => <Notepad />}
                                                        redirect="/"
                                                        condition={canManageUsers(this.props.profile) && isFacilityTabEnabled(this.props.profile, 'notepad')}
                                                    />
                                                    <ProtectedRoute
                                                        path={'/admin/assets'}
                                                        exact
                                                        component={() => <Assets profile={this.props.profile} />}
                                                        redirect="/"
                                                        condition={canManageUsers(this.props.profile) && isFacilityTabEnabled(this.props.profile, 'assets')}
                                                    />
                                                    <ProtectedRoute
                                                        path={'/admin/dining'}
                                                        exact
                                                        component={() => <Dining profile={this.props.profile} />}
                                                        redirect="/"
                                                        condition={canManageUsers(this.props.profile) && isFacilityTabEnabled(this.props.profile, 'dining')}
                                                    />
                                                    <ProtectedRoute
                                                        path={'/admin/cookbook'}
                                                        exact
                                                        render={(props) => <Cookbook {...props} profile={this.props.profile} />}
                                                        redirect="/"
                                                        condition={canManageUsers(this.props.profile) && isFacilityTabEnabled(this.props.profile, 'dining')}
                                                    />
                                                    <ProtectedRoute
                                                        path={'/admin/cookbook/create'}
                                                        exact
                                                        component={() => <Services source={cookbook} />}
                                                        redirect="/"
                                                        condition={canManageUsers(this.props.profile) && isFacilityTabEnabled(this.props.profile, 'dining')}
                                                    />
                                                    <ProtectedRoute
                                                        path={'/admin/cookbook/:id'}
                                                        exact
                                                        component={() => <Update source={cookbook} />}
                                                        redirect="/"
                                                        condition={canManageUsers(this.props.profile) && isFacilityTabEnabled(this.props.profile, 'dining')}
                                                    />
                                                    <ProtectedRoute
                                                        path={'/admin/services/create'}
                                                        exact
                                                        component={() => <Services />}
                                                        redirect="/"
                                                        condition={canManageUsers(this.props.profile) && isFacilityTabEnabled(this.props.profile, 'services')}
                                                    />
                                                    <ProtectedRoute
                                                        path={'/admin/turn-billing'}
                                                        exact
                                                        component={TURNBilling}
                                                        redirect="/"
                                                        condition={canManageUsers(this.props.profile)}
                                                    />
                                                    <ProtectedRoute
                                                        path={'/admin/kiosk'}
                                                        exact
                                                        component={KioskQuestions}
                                                        redirect="/"
                                                        condition={canManageUsers(this.props.profile) && isFacilityTabEnabled(this.props.profile, 'kiosk')}
                                                    />
                                                    <ProtectedRoute
                                                        path={'/admin/services/list'}
                                                        exact
                                                        component={ListServiceTypes}
                                                        redirect="/"
                                                        condition={canManageUsers(this.props.profile) && isFacilityTabEnabled(this.props.profile, 'services')}
                                                    />
                                                    <ProtectedRoute
                                                        path={'/admin/services/list/:id'}
                                                        exact
                                                        component={Update}
                                                        redirect="/"
                                                        condition={canManageUsers(this.props.profile) && isFacilityTabEnabled(this.props.profile, 'services')}
                                                    />
                                                    <ProtectedRoute
                                                        path={'/admin/groups'}
                                                        exact
                                                        component={() => <Groups profile={this.props.profile} />}
                                                        redirect="/"
                                                        condition={canManageUsers(this.props.profile)}
                                                    />
                                                    <ProtectedRoute
                                                        path={'/admin/docs'}
                                                        exact
                                                        component={() => <Docs profile={this.props.profile} />}
                                                        redirect="/"
                                                        condition={canManageUsers(this.props.profile)}
                                                    />
                                                    <ProtectedRoute
                                                        path={'/sysadmin/communityliaisons'}
                                                        exact
                                                        component={() => <CommunityLiaisons profile={this.props.profile} />}
                                                        redirect="/"
                                                        condition={isSysAdmin(this.props.profile)}
                                                    />
                                                </Switch>
                                            </Sidebar.Pusher>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                            <Route
                                path="/login"
                                component={Login}
                            // condition={!this.props.isAuthenticated}
                            // redirect="/"
                            />
                        </Sidebar.Pushable>
                    </Router>
                    <ToastContainer />
                    <Dimmer active={this.props.isLoading} inverted>
                        <Loader active={this.props.isLoading} />
                    </Dimmer>
                </Fragment>
            </CacheBuster >
        );
    }
}

function mapStateToProps({ authReducer }: AppState) {
    return {
        isAuthenticated: authReducer.isAuthenticated,
        currentUser: authReducer.currentUser,
        profile: authReducer.profile,
        passwordChangeRequired: authReducer.passwordChangeRequired,
        isLoading: authReducer.isLoading
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, actions.AuthAction>) {
    return {
        onLogOut: (profile: UserProfile | null) => dispatch(actions.logOut(profile)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
