import React, { FC, useEffect, useState } from 'react'
import { Message, Table, Dimmer, Loader } from 'semantic-ui-react'
import { ResponseForQuestionOfTheDay } from '../../../src/types/responseForQuestionOfTheDay'
import { listAllResponsesForQuestionsOfTheDayForOneResident } from '../../services/QuestionOfTheDay'
import moment from 'moment-timezone';

interface Props {
    residentId: string
    residentName: string
}
const ResidentQOTDResponses: FC<Props> = (props) => {
  const [isLoadingResponsesForQuestionsOfTheDay, setIsLoadingResponsesForQuestionsOfTheDay] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [responsesToQuestionOfTheDay, setResponsesToQuestionOfTheDay] = useState <Partial<ResponseForQuestionOfTheDay>[]>([]);
  
  useEffect(() => {
      if (props.residentId) {
          (async () => {
              setIsLoadingResponsesForQuestionsOfTheDay(true);
              await fetchQuestionOfTheDay(props.residentId);
              setIsLoadingResponsesForQuestionsOfTheDay(false);
          })();
      }
  }, []);

  const fetchQuestionOfTheDay = async (registrantId: string) => {
      setIsLoadingResponsesForQuestionsOfTheDay(true);
      try {
          const resp = await listAllResponsesForQuestionsOfTheDayForOneResident(registrantId);
          setResponsesToQuestionOfTheDay(resp)
          setIsLoadingResponsesForQuestionsOfTheDay(false)
      } catch (error) {
        setErrorMessage('Could not fetch questions of the day - please try after sometime')
        setIsLoadingResponsesForQuestionsOfTheDay(false)
      }
  };

  return (
    <div> <Dimmer active={isLoadingResponsesForQuestionsOfTheDay} inverted>
    <Loader active={isLoadingResponsesForQuestionsOfTheDay}/>
</Dimmer>
{errorMessage !== ""? <Message negative>{errorMessage}</Message>: <></>}
{responsesToQuestionOfTheDay && responsesToQuestionOfTheDay.find((entry) => entry !== null) ? (
    <Table fixed>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Question</Table.HeaderCell>
                <Table.HeaderCell>Response</Table.HeaderCell>
            </Table.Row>
        </Table.Header>

        <Table.Body>
            {responsesToQuestionOfTheDay && responsesToQuestionOfTheDay.map(
                (entry) =>
                    entry && (
                        <Table.Row>
                            <Table.Cell>{moment(entry.date, 'D/M/YYYY').format('MM/DD/YYYY')}</Table.Cell>
                            <Table.Cell>{entry.question}</Table.Cell>
                            <Table.Cell>{entry.response}</Table.Cell>
                        </Table.Row>
                    ),
            )}
        </Table.Body>
    </Table>
) : (
    <Message>
        There are no responses by this resident for Question of the day as of now
    </Message>
)}</div>
  )
}

export default ResidentQOTDResponses