import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Checkbox, Dimmer, Dropdown, Form, Grid, Icon, Loader, Menu, Segment, Message } from 'semantic-ui-react';
import { fetchAllActiveFacilityRegistrants } from '../../services/Registrants';
import { AuthState, Registrant, User } from '../../types';
import CategoryIconlist from '../../pages/AdminPanel/Requests/CategoryIconList';
import { fetchAllActiveServicesTypes } from '../../services/service';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import { Service, ServiceAddOn } from '../../types/Service';
import { toast } from 'react-toastify';
import { createRequestInstance, fetchOrdersFromOrderId, reopenServiceRequest, unacceptServiceRequest, updateRequestInstance } from '../../services/RequestInstances';
import { uploadRequestsInstancesImageToS3 } from '../../services/ImageUpload';
import './style.less';
import { formatDateWithTZ, getDurationInMs, getFormattedEndDateString } from '../../util/timezone';
import { sortByKey } from '../../util/sortData';
import { FetchOrderFromOrderIdParams, RequestInstance } from '../../types/RequestInstance';
import { useSelector } from 'react-redux';
import AssignButton from '../AssignButton';
import UserItem from '../UserItem';
import { sendToast } from '../../util';
import { closeAllRequests } from '../../services/Requests';
import CloseAssociatedOrdersModal from './CloseAssociatedOrdersModal';
import { fetchServiceInstance } from '../../services/ServiceInstances';

interface Props {
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    facilityId: string;
    refresh?: () => void;
    resident?: string;
    cancelClickHandler?: () => void;
    smallIcons?: boolean;
    request?: RequestInstance;
    parentLoading?: boolean;
    staffUsers?: User[];
    loadingStaffUsers?: boolean;
    locationStateAssignButton?: boolean;
    handleAssignSubmit?: (value: User | null) => Promise<void>;
    handleOnClose?: (requestId: string | undefined) => Promise<void>
    handleOnAccept?: (requestId: string | undefined) => Promise<void>
}

const CreateRequestModal: React.FC<Props> = ({ setOpen, facilityId, refresh, resident, smallIcons, cancelClickHandler, request, parentLoading, staffUsers, loadingStaffUsers, locationStateAssignButton, handleAssignSubmit, handleOnClose, handleOnAccept }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [residentOptions, setResidentOptions] = useState<Registrant[]>([]);
    const [orderName, setOrderName] = useState<string>('');
    const [selectedResidentId, setSelectedResidentId] = useState<string>("");
    const [selectedResident, setSelectedResident] = useState<Registrant>();
    const [description, setDescription] = useState<string>('');
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const [services, setServices] = useState<Service[]>([]);
    const [selectedService, setSelectedService] = useState<string>('');
    const [selectedSvcInstanceId, setSelectedSvcInstanceId] = useState<string>('');
    const [serviceAddOns, setServiceAddOns] = useState<ServiceAddOn[]>([]);
    const [selectedServiceAddOns, setSelectedServiceAddOns] = useState<ServiceAddOn[]>([]);
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [nowRequest, setNowRequest] = useState<boolean>(false);
    const [imageLink, setImageLink] = useState<string>('');
    const [currentImage, setCurrentImage] = useState<string>('');
    const [duration, setDuration] = useState<number | undefined>(undefined);
    const [assignedStaff, setAssignedStaff] = useState<User | null>(null);
    const [assignedStaffName, setAssignedStaffName] = useState<string | null>((request && request.OriginalServiceId && request.AcceptedByName) || null);
    const [associatedOrders, setAssociatedOrders] = useState<RequestInstance[]>([]);
    const [openCloseAssociatedOrdersModal, setOpenCloseAssociatedOrdersModal] = useState<boolean>(false);
    const profile = useSelector(({ authReducer }: { authReducer: AuthState }) => {
        return authReducer.profile;
    });

    const isNowRequestAndEditView = request && request.RequestedTime && (!request.ScheduledTime || !Object.keys(request.ScheduledTime).length) ? true : false;

    useEffect(() => {
        const getData = async () => {
            try {
                setLoading(true);
                const residents = await fetchAllActiveFacilityRegistrants(facilityId, true, true);
                const Filter = { Facility: facilityId, active: true };
                const Result = await fetchAllActiveServicesTypes({ Filter });
                const servicesWithDepartments = Result.filter((service: Service) => { if (service.Department) return service; });
                if (resident) {
                    const residentObj = residents.find((item: Registrant) => item._id === resident);
                    if (residentObj) {
                        setSelectedResidentId(residentObj._id);
                    }
                }
                if(request && request.ResidentId) {
                    const residentObj = residents.find((item: Registrant) => item._id === request.ResidentId);
                    if (residentObj) {
                        setSelectedResident(residentObj);
                    }
                }
                setServices(servicesWithDepartments);
                let serviceInstance: Service | null = null;
                if (request && request.OriginalServiceInstanceId) {
                    serviceInstance = await fetchServiceInstance({ Filter: { _id: request.OriginalServiceInstanceId } });
                }               
                setResidentOptions(residents);
                request && setInitialStates(servicesWithDepartments, serviceInstance); // we pass it to the function explicitly so that we don't have to wait for the services state to update
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
        getData();
    }, []);

    useEffect(() => {
        const service = services.find((service) => service._id == selectedService);
        if (service && service.category) {
            setSelectedCategory(service.category);
        }
    }, [selectedService]);

    useEffect(() => {
        if (startDate && duration !== undefined) {
            const updatedEndDate = getFormattedEndDateString(startDate, duration, 'YYYY-MM-DDTHH:mm:ss');
            setEndDate(updatedEndDate);
        }
    }, [startDate]);

    useEffect(() => {
        if (endDate) { // here there is no check for start date as it will be always there when end date is there
            const updatedDuration = getDurationInMs(startDate, endDate);
            setDuration(updatedDuration);
        }
    }, [endDate]);

    useEffect(() => {
        if (request && request.OrderId) {
            fetchAllAssociatedOrders();
        }
    }, [request]);

    const fetchAllAssociatedOrders = async () => {
        try {
            const orderFilter: FetchOrderFromOrderIdParams = {
                statusArr: ['Open', 'Accepted'],
                residentId: String(request && request.ResidentId),
                OrderId: (request && request.OrderId) || '',
            };
            const response = await fetchOrdersFromOrderId(orderFilter);
            if(!response){
                throw new Error('Failed to fetch associated orders');
            }
            setAssociatedOrders(response);
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : 'Failed to fetch associated orders');
        }
    };

    const formatSvcRequestTime = (time: number | Date | undefined) => {
        // collects time in timestamp and returns a string in the format "at 12:00 PM on Mar 19, 2021"
        if (!time) return "";
        const facilityTimezone = profile && profile.FacilityTimeZone || "";
        if (!facilityTimezone) {
            return ""
        }
        const formatString = 'h:mm A on MMM D, YYYY';
        const formattedTime = 'at ' + moment.tz(time, facilityTimezone).format(formatString);
        return formattedTime;
    }

    const handleAssignOnChange = (value: User | null) => {
        setAssignedStaff(value);
        const staffFullName = (value && value.FirstName || '') + ' ' + (value && value.LastName || '').trim();
        setAssignedStaffName(staffFullName);
    }

    const setInitialStates = (servicesWithDepartments: Service[] = [], serviceInstance: Service | null) => { // common function to set the initial states for the modal which will trigger on page load and when we click cancel button for edit view
        if (request) {
            setSelectedResidentId(request.ResidentId);
            setSelectedCategory(request.Category);
            if (request.OriginalServiceId) {
                setSelectedService(request.OriginalServiceId);
            } else if (request.OriginalServiceInstanceId) {
                setSelectedSvcInstanceId(request.OriginalServiceInstanceId);
            }
            setOrderName(request.OrderName);
            setDescription(request.Description);
            if (request.ScheduledTime) {
                setStartDate(request.ScheduledTime.startDate);
                setEndDate(request.ScheduledTime.endDate);
            } else {
                setStartDate('');
                setEndDate('');
            }
            let allAddOns: ServiceAddOn[] = [];
            if (request.OriginalServiceInstanceId && serviceInstance) {
                allAddOns = serviceInstance.ServiceAddOns ? serviceInstance.ServiceAddOns : [];
            } else {
                const currServices = servicesWithDepartments.length > 0 ? servicesWithDepartments : services; // for the case when we don't pass servicesWithDepartments we take the services from the state
                const svc = currServices.find((service) => service._id == request.OriginalServiceId);
                allAddOns = svc && svc.ServiceAddOns ? svc.ServiceAddOns : [];
            }
            setServiceAddOns(allAddOns);
            // if addons are present in the request object we take them 
            // else we take the addons from the service and only mark the required ones as checked and the rest as unchecked
            if (request.ServiceAddOns && Array.isArray(request.ServiceAddOns) && request.ServiceAddOns.length > 0)
                setSelectedServiceAddOns(request.ServiceAddOns);
            else {
                const requiredAddons: ServiceAddOn[] = [];
                allAddOns.map((addon: ServiceAddOn) => { 
                    if (addon.required)
                        requiredAddons.push(addon);
                });
                setSelectedServiceAddOns(requiredAddons);
            }
            if (request.ImageName && request.ImageUrl) {
                setImageLink(request.ImageUrl);
                setCurrentImage(request.ImageName);
            } else {
                setImageLink('');
                setCurrentImage('');
            }
            setSelectedImage(null);
            (document.getElementById('imageInput') as HTMLInputElement).value = ''; // reset the value of the input html element unless it caches the value and doesn't allow us to upload the same image again
        }
    };

    const compareInitialAndUpdatedValues = () => {
        const addOns = JSON.parse(JSON.stringify(selectedServiceAddOns)); // deep copy of the selectedServiceAddOns state array
        const data: Partial<RequestInstance> = {
            OrderName: orderName,
            Description: description,
            ...(startDate && endDate && {
                ScheduledTime: {
                    startDate: moment(startDate).format('YYYY-MM-DDTHH:mm:ss'),
                    endDate: moment(endDate).format('YYYY-MM-DDTHH:mm:ss'),
                }
            }),
            ServiceAddOns: addOns,
            ...(selectedImage && { ImageName: selectedImage.name }),
        };
        return returnChangedValues(request, data);
    };

    const returnChangedValues = (initialObj = {}, updatedObj = {}) => {
        const finalData = {};

        // Add new keys found in updatedObj to finalData
        Object.keys(updatedObj).forEach((key) => {
            if (!initialObj.hasOwnProperty(key)) {
                finalData[key] = updatedObj[key];
            }
        });

        // Update existing keys if there are any changes
        Object.keys(initialObj).forEach((key) => {
            const updatedValue = updatedObj[key];

            // For nested objects and arrays
            if (typeof initialObj[key] === 'object' && initialObj[key] !== null) {
                if (Array.isArray(initialObj[key])) {
                    const initialArr = initialObj[key];
                    const updatedArr = updatedObj[key];

                    const hasLengthChanged = updatedArr && (initialArr.length !== updatedArr.length);

                    // Check for changes or new elements in the array
                    const hasArrayChanged = updatedArr && Array.isArray(updatedArr) && updatedArr.some((element, index) =>
                        index >= initialArr.length ||
                        Object.keys(returnChangedValues(initialArr[index], element)).length > 0
                    );

                    if (hasArrayChanged || hasLengthChanged) {
                        finalData[key] = updatedArr;
                    }
                } else {
                    // Check for changes in nested objects
                    const nestedDiffs = returnChangedValues(initialObj[key], updatedObj[key]);
                    if (Object.keys(nestedDiffs).length > 0) {
                        finalData[key] = updatedObj[key];
                    }
                }
            } else if (initialObj[key] !== updatedValue && updatedValue !== undefined) {
                finalData[key] = updatedValue; // Add changes for other types of values
            }
        });

        return finalData; // Return the final object with changes
    };

    const handleSubmit = async () => {

        if (startDate === "Invalid date" || endDate === "Invalid date") {
            toast.warn('Please select a valid start/end date', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            return;
        }

        if (selectedCategory && (selectedService || selectedSvcInstanceId) && selectedResidentId) {
                const addOns = JSON.parse(JSON.stringify(selectedServiceAddOns)); // deep copy of the selectedServiceAddOns state array
                if (request) {
                    const updatedRequestObj = compareInitialAndUpdatedValues();
                    if (assignedStaff && Object.keys(updatedRequestObj).length === 0)
                        return toast.success('Request updated successfully', {
                            position: 'bottom-center',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    if (Object.keys(updatedRequestObj).length === 0) {
                        toast.warn('No items to change', {
                            position: 'bottom-center',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                        return;
                    }
                    if (selectedImage) {
                        await uploadImageFile(request._id);
                    }
                    const res = await updateRequestInstance({ ...updatedRequestObj, _id: request._id });
                    if (!res.updated) { // backend will return updated as false with a message as to why updated is false
                        return toast.warn(res.message ? res.message : 'Something went wrong while updating request', {
                            position: 'bottom-center',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    }
                    refresh && refresh();
                    toast.success('Request updated successfully', {
                        position: 'bottom-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                } else {
                    const requestObject = {
                        Category: selectedCategory,
                        ResidentId: selectedResidentId,
                        OrderName: orderName,
                        Description: description,
                        ...(!nowRequest && {
                            ScheduledTime: {
                                startDate: moment(startDate).format('YYYY-MM-DDTHH:mm:ss'),
                                endDate: moment(endDate).format('YYYY-MM-DDTHH:mm:ss'),
                            },
                        }),
                        ...(nowRequest && { RequestedTime: moment().valueOf() }),
                        Facility: facilityId,
                        RequestChannel: "Web",
                        ServiceAddOns: addOns,
                    };
                    const createdRequestId = await createRequestInstance({
                        ...requestObject,
                        OriginalServiceId: selectedService,
                    });
                    if (selectedImage) {
                        await uploadImageFile(createdRequestId);
                        const res = await updateRequestInstance({
                            _id: createdRequestId,
                            ImageName: selectedImage.name,
                        });
                        if (!res.updated && res.message) { // backend will return updated as false with a message as to why updated is false
                            return toast.warn(res.message, {
                                position: 'bottom-center',
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                    }
                    toast.success('Request added successfully', {
                        position: 'bottom-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    setOpen && setOpen(false);
                    if (refresh)
                        refresh();
                    setSelectedCategory('');
                    setSelectedResidentId('');
                    setSelectedService('');
                    setOrderName('');
                    setDescription('');
                    setStartDate('');
                    setEndDate('');
                    setSelectedImage(null);
                    setNowRequest(false);
                    setServiceAddOns([]);
                    setSelectedServiceAddOns([]);
                }
        } else {
            toast.warn('Please Fill all required fields marked with *', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    };
    const uploadImageFile = async (requestInstanceId: string): Promise<void> => {
        if (selectedImage) {
            await uploadRequestsInstancesImageToS3(requestInstanceId, selectedImage.name, selectedImage);
        } else {
            throw new Error('Image file is not provided');
        }
    };
    const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const selectedFile = event.target.files[0];
            console.log('Selected file', selectedFile);
            if (selectedFile) {
                setSelectedImage(selectedFile);
                setCurrentImage('');
                setImageLink('');
            }
        }
    };
    const handleServiceDropDownChange = (value) => {
        setLoading(true);
        if (services.length > 0) {
            services.find((item: any) => {
                if (item._id === value) {
                    setSelectedService(value as string);
                    if (item.ServiceAddOns && item.ServiceAddOns.length > 0) {
                        const requiredAddons: ServiceAddOn[] = [];
                        item.ServiceAddOns.map((addon: ServiceAddOn) => {
                            if (addon.required)
                                requiredAddons.push(addon); //  push the required addons to selectedServiceAddOns array
                        });
                        setSelectedServiceAddOns(requiredAddons);
                        setServiceAddOns(item.ServiceAddOns);
                    }
                    else {
                        setServiceAddOns([]);
                        setSelectedServiceAddOns([]);
                    }
                }
            });
        }
        setLoading(false);
    };
    const handleNowRequest = () => {
        if (nowRequest) {
            setNowRequest(false);
        } else {
            setStartDate('');
            setEndDate('');
            setNowRequest(true);
        }
    };

    const handleUnacceptServiceRequest = async () => {
        if (request) {
            setLoading(true);
            try {
                const res = await unacceptServiceRequest(request._id);
                if (!res || res.Error) {
                    throw new Error(res.Error ? res.Error : 'Something went wrong while unaccepting request');
                }
                refresh && refresh();
                toast.success('Unaccepted request successfully', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            } catch (error) {
                console.error('Something went wrong', error);
                toast.warn(error instanceof Error ? error.message : 'Something went wrong', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            } finally {
                setLoading(false);
            }
        }
    }

    const handleReopenServiceRequest = async () => {
        if (request) {
            setLoading(true);
            try {
                const res = await reopenServiceRequest(request._id);
                if (!res || res.Error) {
                    throw new Error(res.Error ? res.Error : 'Something went wrong while unaccepting request');
                }
                refresh && refresh();
                toast.success('Reopened request successfully', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            } catch (error) {
                console.error('Something went wrong', error);
                toast.warn(error instanceof Error ? error.message : 'Something went wrong', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            } finally {
                setLoading(false);
            }
        }
    }

    const handleServiceAddons = (id: string, value: boolean) => {
        let updatedAddOns = JSON.parse(JSON.stringify(selectedServiceAddOns)); // deep copy of the selectedServiceAddOns state array
        if (value) {
            serviceAddOns.forEach(((addon) => {
                if (addon.id === id) {
                    updatedAddOns.push(addon);
                }
            }));
        } else {
            updatedAddOns = updatedAddOns.filter((addon: ServiceAddOn) => addon.id !== id);
        }
        setSelectedServiceAddOns(updatedAddOns);
    };

    const handleCategorySelection = (category) => {
        setSelectedService("");
        setServiceAddOns([]);
        setSelectedServiceAddOns([]);
        if (category != selectedCategory) {
            setSelectedCategory(category);
        } else {
            setSelectedCategory('');
        }
    };

    const handleResidentDropdownChange = (residentId: string) => {
        const resident = residentOptions.find((item) => item._id === residentId);
        if (resident) {
            setSelectedResidentId(resident._id);
        } else {
            toast.warn('Resident not found', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    };

    const dropdownOptions = () => {
        if (request && request.OriginalServiceInstanceId) {
            return [{
                key: request.OriginalServiceInstanceId,
                value: request.OriginalServiceInstanceId,
                text: `${request.ServiceName}`,
            }];
        }
        const filteredServices = selectedCategory ? services.filter((serviceItem) => {
            if (selectedCategory && serviceItem.category === selectedCategory)
                return serviceItem;
        }) : services;
        return filteredServices
            .map((item) => {
                return {
                    key: item._id,
                    value: item._id,
                    text: `${item.name}`,
                };
            }).sort((a, b) => a.text.localeCompare(b.text));
    }
    
    const cancelAllAssociatedRequest = () => {
        setOpenCloseAssociatedOrdersModal(true);
    };

    const onClose = () => {
        setOpenCloseAssociatedOrdersModal(false);
    };

    const cancelAllOpenAndAcceptedOrders = async () => {
        if (associatedOrders && associatedOrders.length > 0) {
            try {
                setLoading(true);
                const mealDeliveryOrderIds: string[] = [];
                const nonDeliveryOrderIds: string[] = [];
                associatedOrders.forEach(order => {
                    if (order.RequestType === 'Meal Delivery') {
                        mealDeliveryOrderIds.push(order._id);
                    } else if (order.RequestType !== 'Meal Delivery') {
                        nonDeliveryOrderIds.push(order._id);
                    }
                });
                await closeAllRequests(nonDeliveryOrderIds);
                await closeAllRequests(mealDeliveryOrderIds);
                sendToast('success', 'Orders cancelled successfully');
                setOpenCloseAssociatedOrdersModal(false);
                refresh && refresh();
            } catch (error) {
                sendToast('error', error instanceof Error ? error.message : 'Failed to cancel orders');
            } finally {
                setLoading(false);
            }
        } else {
            sendToast('warn', 'No request to cancel');
        }
    }

    return (
        <div className="staff-request-modal">
            <Dimmer inverted active={loading || parentLoading}>
                <Loader active={loading || parentLoading} />
            </Dimmer>
            <Form autoComplete="false" onSubmit={async () => {
                try {
                    setLoading(true);
                    await handleSubmit();
                    if (assignedStaff) {
                        handleAssignSubmit && await handleAssignSubmit(assignedStaff);
                    }
                } catch (error) {
                    sendToast('error', error instanceof Error ? error.message : 'Something went wrong');
                } finally {
                    setLoading(false);
                }
            }}
                style={{ marginTop: '10px' }}>
                {/* Categories list */}
                {request ? // Dont show category icons for edit view
                    (
                        <></>
                    ) : (
                        <div className="CreateRequest">
                            <Menu
                                borderless
                                style={{ display: 'flex', justifyContent: 'center', border: 'none', boxShadow: 'none' }}
                            >
                                <CategoryIconlist
                                    selectedCategory={selectedCategory}
                                    handleCategorySelection={request ? () => {} : handleCategorySelection}
                                    smallSize={smallIcons ? true : false}
                                />
                            </Menu>
                        </div>
                    )
                }
                {/* Resident dropdown/detail view */}
                {request && selectedResident ? ( // Show resident detailed label for edit view and dropdown for create view 
                // request - check if its edit view, 
                // selectedResident - check if resident is fetched and present in state - this is to avoid state not refreshing
                        <Grid>
                            <Grid.Row columns={3}>
                                <Grid.Column width={3} style={{textAlign: 'left'}}>
                                    {/* spacer */}
                                </Grid.Column> 
                                <Grid.Column width={10} style={{textAlign: 'left'}}>
                                    <UserItem
                                        id={(selectedResident && String(selectedResident._id)) || ''}
                                        name={
                                            (selectedResident &&
                                                `${selectedResident.FirstName} ${selectedResident.LastName}`) ||
                                            ''
                                        }
                                        details={
                                            (selectedResident &&
                                                (selectedResident.SpecialHandling || selectedResident.Notes)) ||
                                            ''
                                        }
                                        avatarUrl={(selectedResident && (selectedResident.Image || selectedResident.alisImage || selectedResident.ExternalResidentImage)) || ''}
                                        onClick={() => {}}
                                        source="requests"
                                        roomName={
                                            (selectedResident && selectedResident.Unit && selectedResident.Unit.Name) || ''
                                        }
                                        residentPremise={selectedResident && selectedResident.onPremise}
                                        residentSocialOptOut={selectedResident && selectedResident.Private || false}
                                        isExternalResident={selectedResident.ExternalIntegrationSrc ? true : false}
                                        isAlisResident={selectedResident.alisResidentId ? true : false}
                                        externalIntegrationSource={selectedResident.ExternalIntegrationSrc || ''}
                                    />
                                </Grid.Column>
                                <Grid.Column width={3} style={{textAlign: 'left'}}>
                                    {/* spacer */}
                                </Grid.Column> 
                            </Grid.Row>
                        </Grid>

                ) : (
                    <Form.Field>
                        <Dropdown
                            style={{ marginTop: '10px' }}
                            placeholder={'Resident *'}
                            required={true}
                            closeOnEscape
                            value={selectedResidentId}
                            search
                            scrolling
                            selection
                            disabled={request ? true : false}
                            options={sortByKey(
                                residentOptions.map((item) => {
                                    return {
                                        key: item._id,
                                        value: item._id,
                                        text: `${item.FirstName} ${item.LastName}`,
                                    };
                                }),
                            )}
                            onChange={(e, { value }) => handleResidentDropdownChange(value as string)}
                        />
                    </Form.Field>
                )}
                {/* Service dropdown */}
                <Form.Field>
                    { // if its an edit view and the service is deleted we show a message that the service is deleted as we don't have the service name in the request object
                        request && (request.OriginalServiceId || request.OriginalServiceInstanceId) && !request.ServiceName ? <p style={{ color: "red" }}>Service Deleted</p> :
                            <Dropdown
                                placeholder={'Service *'}
                                closeOnEscape
                                value={ selectedService || selectedSvcInstanceId}
                                search
                                scrolling
                                selection
                                disabled={request ? true : false}
                                options={dropdownOptions()}
                                onChange={(e, { value }) => handleServiceDropDownChange(value)}
                            />
                    }
                </Form.Field>
                {/* Add ons */}
                {serviceAddOns.length > 0 // support total and cost display
                    ? <>
                        <Segment vertical>
                            {serviceAddOns.map((item: any) => {
                                const isAddonPresentInRequest = !!selectedServiceAddOns.find((addon) => addon.id == item.id); // we check if the addon is present in the request object array if its there we mark it as checked else we mark is as unchecked
                                return (
                                    <div
                                        key={item.id}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            margin: '5px 0px',
                                            padding: '0px 20px',
                                        }}
                                    >
                                        <div>
                                            <label style={{ flexGrow: 1, marginRight: '10px' }}>{item.itemName}</label>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div>
                                                <Checkbox
                                                    disabled={item.required}
                                                    checked={item.required || isAddonPresentInRequest}
                                                    onChange={(event: React.FormEvent<HTMLInputElement>, { checked }) =>
                                                        handleServiceAddons(item.id, checked as boolean)
                                                    }
                                                />
                                            </div>
                                            <div style={{ marginLeft: '40px', width: '60px' }}>
                                                <span >{item.itemCost} <b>$</b></span>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </Segment>
                        <Segment vertical>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <span style={{ margin: '0px 20px 5px 0' }}>
                                    <b>Total </b> : {selectedServiceAddOns.reduce((acc, addon) => acc + addon.itemCost, 0)} <b>$</b>
                                </span>
                            </div>
                        </Segment>
                    </>
                    : ''}
                {/* Order name */}
                <Form.Field>
                    <Form.Input
                        required={true}
                        value={orderName || ''}
                        placeholder="Request Name *"
                        onChange={(e) => setOrderName(e.target.value)}
                    />
                </Form.Field>
                {/* Order description */}
                <Form.Field>
                    <Form.TextArea
                        value={description}
                        onChange={(e) => setDescription(e.currentTarget.value)}
                        placeholder=""
                    />
                </Form.Field>
                {/* Scheduled time and buttons */}
                <Segment>
                    {/* Segement with scheduled time and buttons (assign/unaccept & update) */}
                    {/* Scheduled time */}
                    <Form.Field>
                        <Grid columns={2} centered>
                            <Grid.Column>
                                <DatePicker
                                    customInput={
                                        <div
                                            style={{
                                                position: 'relative',
                                                boxShadow: '0px 1px 2px 0 rgba(34, 36, 38, 0.15)',
                                            }}
                                        >
                                            <Icon
                                                name="calendar alternate outline"
                                                style={{
                                                    position: 'absolute',
                                                    top: '45%',
                                                    left: '10px',
                                                    transform: 'translateY(-50%)',
                                                }}
                                            />
                                            <input
                                                disabled={nowRequest && !request}
                                                style={{ paddingLeft: '30px' }}
                                                placeholder="Schedule start *"
                                                required={request ? false : true}
                                                value={startDate ? moment(startDate).format('M.D.Y h:mm A') : isNowRequestAndEditView && request ? formatDateWithTZ(request.RequestedTime, "M.D.Y h:mm A", profile && profile.FacilityTimeZone || "") : ''}
                                            />
                                        </div>
                                    }
                                    minDate={new Date()} // Set the minimum to today
                                    disabled={nowRequest}
                                    dateFormat="M.d.Y h:mm aa"
                                    showTimeSelect
                                    timeIntervals={15}
                                    selected={startDate ? Date.parse(startDate || new Date().toISOString()) : null}
                                    onChange={(date: Date) => {
                                        if (!date) return;
                                        if (!nowRequest) {
                                            const dateTime = moment(date).format('YYYY-MM-DDTHH:mm:ss');
                                            setStartDate(dateTime);
                                            if (!endDate) {
                                                const startDatePlus15Min = moment(dateTime)
                                                    .add(15, 'minutes')
                                                    .format('YYYY-MM-DDTHH:mm:ss');
                                                setEndDate(startDatePlus15Min);
                                            }
                                        } else {
                                            setStartDate('');
                                        }
                                    }}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <DatePicker
                                    customInput={
                                        <div
                                            style={{
                                                position: 'relative',
                                                boxShadow: '0px 1px 2px 0 rgba(34, 36, 38, 0.15)',
                                            }}
                                        >
                                            <Icon
                                                name="calendar alternate outline"
                                                style={{
                                                    position: 'absolute',
                                                    top: '45%',
                                                    left: '10px',
                                                    transform: 'translateY(-50%)',
                                                }}
                                            />
                                            <input
                                                disabled={nowRequest || !startDate}
                                                style={{ paddingLeft: '30px' }}
                                                placeholder="Schedule end *"
                                                required={request ? false : true}
                                                value={endDate ? moment(endDate).format('M.D.Y h:mm A') : ''}
                                            />
                                        </div>
                                    }
                                    minDate={startDate ? new Date(startDate) : new Date()} // Set the minimum to today or start date
                                    minTime={startDate ? new Date(moment(startDate).add(15, 'minutes').valueOf()) : null} // Set the minTime her
                                    maxTime={
                                        startDate
                                            ? new Date(startDate).setHours(23, 59, 59) // Set the maxTime to end of the day
                                            : null
                                    }
                                    disabled={nowRequest || !startDate}
                                    dateFormat="M.d.Y h:mm aa"
                                    showTimeSelect
                                    timeIntervals={15}
                                    selected={endDate ? Date.parse(endDate || new Date().toISOString()) : null}
                                    onChange={(date: Date) => {
                                        if (!date) return;
                                        if (!nowRequest) {
                                            const dateTime = moment(date).format('YYYY-MM-DDTHH:mm:ss');
                                            setEndDate(dateTime);
                                        } else {
                                            setEndDate('');
                                        }
                                    }}
                                />
                            </Grid.Column>
                        </Grid>
                    </Form.Field>
                    {/* Case : create request form  - show "Now" and "Send" buttons */}
                    {!request && (
                        // Show "Now *" and "Send" buttons
                        <Form.Field>
                            <Grid columns={2} centered>
                                <Grid.Column>
                                    <Segment
                                        style={{
                                            margin: 'auto',
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: '37px',
                                            cursor: 'pointer',
                                            backgroundColor: nowRequest ? '#00b5ad' : 'white',
                                        }}
                                        onClick={() => handleNowRequest()}
                                    >
                                        <Icon name="calendar alternate outline" style={{ marginTop: '-6px' }} />
                                        <div style={{ color: nowRequest ? 'white' : 'grey', marginLeft: '5px' }}>Now *</div>
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column>
                                    <Button
                                        disabled={
                                            !selectedCategory ||
                                            !selectedResidentId ||
                                            !selectedService ||
                                            !orderName ||
                                            (!startDate && !endDate && !nowRequest) && !request
                                        }
                                        primary
                                        fluid
                                        size="medium"
                                        type="submit"
                                    >
                                        Send
                                    </Button>
                                </Grid.Column>
                            </Grid>
                        </Form.Field>
                    )}
                    {/* Case : edit request form - show "Assign" and "Update" buttons */}
                    {request && handleAssignSubmit && (
                        // Show "Assign" and "Update" buttons
                        <Form.Field>
                            <Grid columns={2} centered>
                                <Grid.Column>
                                    <AssignButton
                                        users={staffUsers || []}
                                        loading={loadingStaffUsers}
                                        onSelectionChange={handleAssignOnChange}
                                        locationState={locationStateAssignButton}
                                        disabled={loading || !!parentLoading || !!(request && !!(request.ClosedTime))}
                                        customButtonText={assignedStaffName}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Button
                                        disabled={
                                            !selectedCategory ||
                                            !selectedResidentId ||
                                            !(selectedService || selectedSvcInstanceId) ||
                                            !orderName ||
                                            (!startDate && !endDate && !nowRequest) && !request
                                        }
                                        primary
                                        fluid
                                        size="medium"
                                        type="submit"
                                    >
                                        Update
                                    </Button>
                                </Grid.Column>
                            </Grid>
                        </Form.Field>
                    )}
                    { /* Case : edit request form - show Accept/Unaccept and Update buttons */}
                    {request && handleOnAccept && (
                        // Show Accept and Update buttons
                        <Form.Field>
                            <Grid columns={2} centered>
                                {request && profile && (request.Status === 'Accepted') &&
                                    <Grid.Column>
                                        <Button
                                            type='button'
                                            size="medium"
                                            basic
                                            color='red'
                                            fluid
                                            disabled={loading || parentLoading || !(request.IsAccepted && profile && (request.StaffId === profile._id))}
                                            loading={loading || parentLoading}
                                            onClick={() => handleUnacceptServiceRequest()}
                                        >
                                            <b>Unaccept</b>
                                        </Button>
                                    </Grid.Column>
                                }
                                {request && profile && (request.Status === 'Open') &&
                                    <Grid.Column>
                                        <Button
                                            primary
                                            fluid
                                            size="medium"
                                            type="button"
                                            onClick={() => handleOnAccept(request._id)}
                                        >
                                            Accept
                                        </Button>
                                    </Grid.Column>
                                }
                                <Grid.Column>
                                    <Button
                                        disabled={
                                            !selectedCategory ||
                                            !selectedResidentId ||
                                            !(selectedService || selectedSvcInstanceId) ||
                                            !orderName ||
                                            (!startDate && !endDate && !nowRequest) && !request
                                        }
                                        primary
                                        fluid
                                        size="medium"
                                        type="submit"
                                    >
                                        Update
                                    </Button>
                                </Grid.Column>
                            </Grid>
                        </Form.Field>
                    )}
                </Segment>
                {/* Close, Cancel buttons row */}
                <div style={{ display: 'flex', justifyContent: 'space-evenly', margin: '20px 0px' }}>
                    {/* Unaccept button - shows up on edit accepted request view only */}
                    {request && profile && (request.Status === 'Accepted') && !(handleOnAccept) && (
                        <Button
                            type='button'
                            disabled={loading || parentLoading || !(request.IsAccepted && profile && (request.StaffId === profile._id))}
                            loading={loading || parentLoading}
                            onClick={() => handleUnacceptServiceRequest()}
                        >
                            <b>Unaccept</b>
                        </Button>
                    )}
                    {/* Close request button - shows up on edit view only */}
                    {/* Show Close button only for open requests */}
                    {/** We will only show the modal if the length of the  associatedOrders is more than one or else we will close the request*/}
                    {request && profile && (request.Status === 'Open' || request.Status === 'Accepted') &&
                        <Button
                            basic
                            color='red'
                            type='button'
                            disabled={(request && request.Status === 'Closed') || loading || !!parentLoading}
                            loading={loading || parentLoading}
                            onClick={() => associatedOrders && associatedOrders.length > 1 ? cancelAllAssociatedRequest() : handleOnClose && handleOnClose(request && request._id)}
                        >
                            <b>Close</b>
                        </Button>
                    }
                    {/* Reopen button - shows up on edit closed request view only */}
                    {request && profile && (request.Status === 'Closed') && (
                        <Button
                            color='red'
                            basic
                            type='button'
                            disabled={loading || parentLoading || request.ClosedByResident}
                            loading={loading || parentLoading}
                            onClick={() => handleReopenServiceRequest()}
                        >
                            <b>Reopen</b>
                        </Button>
                    )}
                    {(setOpen || cancelClickHandler) && (
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                setOpen && setOpen(false);
                                cancelClickHandler && cancelClickHandler();
                            }}
                        >
                            Cancel
                        </Button>
                    )}
                </div>
                {/* Image input component */}
                <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                    <input
                        id="imageInput"
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleImageChange}
                    />
                    {currentImage && !selectedImage && imageLink ? (
                        <label htmlFor="imageInput" style={{ cursor: 'pointer' }}>
                            <img src={imageLink} style={{ maxWidth: '100px', maxHeight: '100px' }} />
                        </label>
                    ) : (
                        <div style={{ cursor: 'pointer' }}>
                            {!selectedImage && (
                                <label htmlFor="imageInput" style={{ cursor: 'pointer' }}>
                                    <Icon name="images" size="huge" />
                                </label>
                            )}
                            {selectedImage && (
                                <label htmlFor="imageInput" style={{ cursor: 'pointer' }}>
                                    <img
                                        src={URL.createObjectURL(selectedImage)}
                                        style={{ maxWidth: '100px', maxHeight: '100px' }}
                                    />
                                </label>
                            )}
                        </div>
                    )}
                </div>
                {/* Request details segment */}
                {request && <Message size='small' style={{textAlign: 'left'}}>
                    <strong>Request Id: {request && request._id}</strong>
                    <br />
                    <br />
                    {
                        request && request.OrderId &&
                        <>
                            <strong>Order Id: {request && request.OrderId}</strong>
                            <br />
                            <br />
                        </>
                    }
                    <strong>
                        Created {formatSvcRequestTime(request && request.DateAdded)} by{' '} {request && (
                                ( (request.Registrant_FirstName || '') + ' ' + (request.Registrant_LastName || '')
                                ).trim()
                        )}
                    </strong>
                    <br />
                    {request && request.AcceptedTime && (
                        <strong>
                            <br />
                            Accepted by: {(request && request.AcceptedByName) || ''}{' '}
                            {formatSvcRequestTime(request && request.AcceptedTime)}
                            <br />
                        </strong>
                    )}
                    {request && request.ClosedTime && (
                        <strong>
                            <br />
                            Closed by: {(request && request.ClosedByName) || ''}{' '}
                            {formatSvcRequestTime(request && request.ClosedTime)}
                            <br />
                        </strong>
                    )}
                </Message>}
            </Form>
            <CloseAssociatedOrdersModal
                open={openCloseAssociatedOrdersModal}
                onClose={onClose}
                cancelRequest={handleOnClose}
                cancelAllOpenAndAcceptedOrders={cancelAllOpenAndAcceptedOrders}
                associatedOrders={associatedOrders}
                facilityTimezone={profile && profile.FacilityTimeZone || ""}
                loading={loading}
                requestId={request && request._id || ""}
                parentLoading={parentLoading}
            />
        </div>
    );
};

export default CreateRequestModal;
