import { SignageContent } from '../types/signage';
import API from './API'

export async function sendSignageProactiveSuggestion(subject: string, message: string, roomIds: string[], signageURL: string | undefined, startDate: string, endDate: string): Promise<any> {
    console.log('sending signage', subject, message, roomIds, signageURL, startDate, endDate);
    const proactiveSuggestionResp =  await API.lambdaPost('/facilities/notifications/proactiveSuggestion', {
        Subject: subject,
        Message: message,
        RoomIds: roomIds,
        SignageURL: signageURL,
        StartDate: startDate,
        EndDate: endDate
    });

    return proactiveSuggestionResp
}

export async function addSignage(subject: string, message: string, roomIds: string[], signageURL: string | undefined, startDate: string, endDate: string, allRooms: boolean): Promise<any> {
    const insertionResponse =  await API.lambdaPost('/signage/add', {
        Subject: subject,
        Message: message,
        RoomIds: roomIds,
        ImageUrl: signageURL,
        StartDate: startDate,
        EndDate: endDate,
        allRooms
    })
    return insertionResponse
}

export async function fetchAllActiveSignage() {
    const { Result } = await API.lambdaPost('/signage/list', {})
    return Result
}
export async function fetchPaginatedActiveSignage(page_no: Number = 1, page_size: Number = 10, filter = {}) {
    const { Result } = await API.lambdaPost('/signage/list', { page_no, page_size, Filter: { ...filter } });
    return Result;
}
export async function fetchSignageContent(facilityId: string | null): Promise<SignageContent> {
    const res  = await API.lambdaPost("/signage/content/get", {
        Facility: facilityId
    })
    return res
}

export async function updateSignageContent(type: string, isActive: boolean, _id: string, Facility: string) {
    const res = await API.lambdaPost("/signage/content/upsert", {
        type,
        isActive,
        _id, 
        Facility
    })

    return res
}

export async function deleteSigange(_id: string) {
    const res = await API.lambdaDeleteById("/signage/delete", { _id })
    return res
}
// export async function fetchFutureSignageContent(Facility: string, upcomingDuration: number = 1000*60*60*24) { // default upcomingDuration to 24 hours
//     const res = await API.lambdaPost("/signage/content/fetchUpcomingSignageContent", {
//         Facility, 
//         upcomingDuration
//     })
//     return res
// }