import React, { useEffect, useState } from 'react';
import { Button, Dimmer, Grid, Loader, Modal, Segment, Image, Dropdown, DropdownProps, Header, Input } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import {
    fetchPaginatedServicesTypes,
    fetchServiceTypesImage,
    getServiceCategories,
    queryServicesTypes,
    removeServiceType,
} from '../../../../services/service';
import { useSelector } from 'react-redux';
import { AuthState } from '../../../../types';
import LoadMore from '../../../../components/LoadMore';
import { toast } from 'react-toastify';
import { Service } from '../../../../types/Service';
import { sortByKey } from '../../../../util/sortData';

const ListServiceTypes = ({ history }) => {
    const [serviceLists, setServiceLists] = useState<Service[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [selectedService, setSelectedService] = useState<Service | null>(null);
    const profile = useSelector(({ authReducer }: { authReducer: AuthState }) => {
        return authReducer.profile;
    });
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [selectedServiceToDelete, setSelectedServiceToDelete] = useState<Service | null>(null);
    const [page, setPage] = useState<number>(1);
    const [hasMorePages, setHasMorePages] = useState<boolean>(false);
    const [serviceImages, setServiceImages] = useState<Record<string, string>>({});
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [pagelimit, setPageLimit] = useState<number>(12);
    const [categoryOptions, setCategoryOptions] = useState<string[]>([]);

    useEffect(() => {
        if (page === 1) {
            setServiceLists([]);    // Reset the service list when the page is 1
        }
        refreshSvcList(true);
    }, [page, selectedCategory]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const categories = await getServiceCategories({ sortAlphabetically: true });
                setCategoryOptions(categories);
            } catch (error) {
                toast.warn(error instanceof Error ? error.message : "Failed to get categories", {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        };
        fetchCategories();
    }, []);

    const categoriesDropdownOptions = categoryOptions && categoryOptions.length > 0 && categoryOptions.map((category) => {
        return {
            key: category,
            text: category,
            value: category
        };
    }
    ) || []; 

    const handleButtonClick = () => {
        history.push('/admin/services/create');
    };

    const handleDeleteService = async (serviceId: string) => {
        try {
            if (serviceId) {
                setOpenModal(false);
                setIsFetching(true);
                setSelectedService(null);
                await removeServiceType(serviceId);
                setServiceLists((prevServiceLists) => prevServiceLists.filter((service) => service._id !== serviceId));
                history.push('/admin/services/list');
                toast.success('Successfully Deleted Service', {
                    position: 'bottom-center',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        } catch (error) {
            console.error('error in deleteing', error);
            toast.error('Something went wrong, please try again', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setIsFetching(false);
            setOpenModal(false);
        }
    };
    
    const fetchServiceImages = async (services, profile) => {
        const servicesWithImages = services.filter((service) => service.image);
        const imagePromises = servicesWithImages.map(async (service) => {
            if (service.image) {
                const imageUrl = await fetchServiceTypesImage(
                    service._id,
                    service.image,
                    (profile && profile.Facility) || '',
                    service.universal
                );
                return { [service._id]: imageUrl };
            }
        });
    
        // Wait for all image promises to resolve
        const imageResults = await Promise.all(imagePromises);
        return Object.assign({}, ...imageResults);
    };

    const refreshSvcList = async (loadMore = false) => {
        try {
            if (!loadMore) {
                setServiceLists([]);
            }
            setIsFetching(true);
            // Include both searchQuery and selectedCategory when fetching data
            const services = searchQuery === ''
                ? await fetchPaginatedServicesTypes(page, pagelimit, (profile && profile.Facility) || '', selectedCategory)
                : await queryServicesTypes((profile && profile.Facility) || '', searchQuery, false, selectedCategory);

            // Fetch images and update the state
            const imagesMap = await fetchServiceImages(services.Result, profile);
            setServiceImages((prevImages) => ({ ...prevImages, ...imagesMap }));

            if (searchQuery === '') {
                // If there's no search query, append to existing list and avoid duplicates
                setServiceLists((prevServiceLists) => {
                    const newServiceList = services.Result.filter(
                        (service) => !prevServiceLists.some((prevService) => prevService._id === service._id)
                    );
                    const sortedServiceList = [...prevServiceLists, ...newServiceList].sort((a, b) =>
                        a.name.localeCompare(b.name)
                    );
                    return sortedServiceList;
                });
                setHasMorePages(!(services.Result.length < pagelimit));
            } else {
                // If there's a search query, update the state with sorted search results
                const sortedServiceList = services.Result.sort((a, b) => a.name.localeCompare(b.name));
                setServiceLists(sortedServiceList);
                setHasMorePages(false);
            }
            setIsFetching(false);
        } catch (error) {
            setIsFetching(false);
            toast.warn(error instanceof Error ? error.message : 'Failed to fetch data', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            console.error('error fetching paginated service library items', error);
        }
    };

    const handleCategoryChange = (e: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        const { value = "" } = data;
        setSelectedCategory(value as string);
        setSearchQuery(''); // Reset search query when the category changes
        if (value === "all") {
            setSelectedCategory("");
        }
        setPage(1);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
        if(event.target.value === '') {
            setPageLimit(12);
        }
    };

    const handleSearchClick = () => {
        // Trigger search when the search icon is clicked
        if (searchQuery === '') {
            setPageLimit(12);
        } else {
            setPageLimit(100);
        }
        setPage(1);
        refreshSvcList();
    };

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input
                    action={{ icon: 'search', onClick: handleSearchClick }}
                    placeholder='Search service...'
                    value={searchQuery}
                    onChange={handleSearchChange}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSearchClick();
                        }
                    }}
                />
                <Dropdown
                    style={{ marginLeft: '10px', marginRight: '5px', width: '250px' }}
                    placeholder='Category'
                    search
                    selection
                    options={[
                        { value: 'all', text: 'All Services', key: "all" },
                        ...sortByKey(categoriesDropdownOptions)
                    ]}
                    onChange={handleCategoryChange}
                    value={selectedCategory == '' ? 'all' : selectedCategory} 
                />
                <Button size='tiny' style={{ 'height': '36px', marginLeft: '5px' }} primary onClick={handleButtonClick}>
                    Create Service
                </Button>
            </div>
            <Dimmer active={isFetching} inverted>
                <Loader active={isFetching} size='small'>
                    Loading
                </Loader>
            </Dimmer>
            <Grid columns={4} stackable style={{ 'marginTop': '10px' }}>
                {serviceLists.map((service) => (
                    <Grid.Column key={service._id}>
                        <Segment
                            style={{
                                cursor: 'pointer',
                                background: selectedService && selectedService._id === service._id ? '#e1e1e1' : 'white',
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '10px',
                                height: '200px',
                            }}
                            onClick={() => {
                                history.push(`/admin/services/list/${service._id}`);
                            }}
                        >
                            <div style={{ flex: '1' }}>
                                <h3 style={{ textTransform: 'capitalize' }}>{service.name}</h3>
                                <p style={{ textTransform: 'capitalize', maxHeight: '50px', overflow: 'hidden' }}>{service.shortDescription}</p>
                                <p style={{ textTransform: 'capitalize' }}>{service.category}</p>
                                <Button
                                    size='tiny'
                                    color='red'
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setOpenModal(true);
                                        setSelectedServiceToDelete(service);
                                    }}
                                >
                                    Delete
                                </Button>
                            </div>
                            {service.image && service.image !== '' && (
                                <Image src={service.image !== '' && serviceImages[service._id]} size='small' style={{ 'height': '100px' }} floated='right' />
                            )}
                        </Segment>
                    </Grid.Column>
                ))}
                {serviceLists && serviceLists.length === 0 &&
                    <div style={{ justifyContent: 'center', width: '100%', height: '90vh', display: 'flex', paddingTop: '55px' }}>
                        <Header as='h3' disabled>No Services Available</Header>
                    </div>
                }
                <Modal size='mini' open={openModal} onClose={() => setOpenModal(false)} dimmer={true}>
                    <Modal.Content>
                        <p>
                            Are you sure you want to delete { selectedServiceToDelete && selectedServiceToDelete.name}?
                        </p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => setOpenModal(false)}>
                            Cancel
                        </Button>
                        <Button
                            color='blue'
                            onClick={() => {
                                selectedServiceToDelete && handleDeleteService(selectedServiceToDelete._id || '');
                            }}
                        >
                            Ok
                        </Button>
                    </Modal.Actions>
                </Modal>
            </Grid>
            <LoadMore next={()=> setPage((prev) => prev + 1)} isLoading={isFetching} hasMore={hasMorePages} />
        </>
    );
};

export default withRouter(ListServiceTypes);
