import { Identifiable } from '../types'
import { Birthday } from '../types/Birthday'
import API from './API'

// will need GET if we have to fetch one Birthday, meanwhile commented out
// export async function fetchOneBirthday(id: string) {
//     try {
//         const res = await API.lambdaGet(`/birthdays/get?_id=${id}`)
//         return res
//     } catch (error) {
//         console.log(error)
//         throw new Error(`${error}`)
//     }
// }

export async function createBirthday(birthday: Partial<Birthday>) {
    console.log(birthday, 'birthday')
    try {
        const id = await API.lambdaPost('/birthdays/add', birthday)
        return id
    } catch (error) {
        console.error(error)
        throw new Error(`${error}`)
    }
}

export async function updateBirthday(birthday: Partial<Birthday> & Identifiable): Promise<Birthday> {
    try {
        if (!birthday._id) throw new Error('Updated birthday object must contain _id field')
        const res = await API.lambdaPut('/birthdays/update', birthday)
        console.log(res, 'response from update ')
        return res
    } catch (error) {
        throw new Error(`${error}`)
    }
}

export async function deleteBirthday(id: string) {
    await API.lambdaDeleteById('/birthdays/delete', { _id: id })
}

export async function getAllBirthdays(dateRange) {
    const res = await API.lambdaGet('/birthdays/list', {
        ...dateRange
    })
    return res
}

export async function getBirthdayMonth(month){
    console.log("month from api", month)
}

export async function copyS3BirthdayImage(copiedFromBirthdayId:string, copiedToBirthdayId:string, imageName:string) {
   try {
        const res = await API.lambdaPost("/birthdays/copybirthdayimage", {
            copiedFromBirthdayId, 
            copiedToBirthdayId, 
            imageName
        })

        return res
   } catch (error) {
        console.log("error copyS3BirthdayImage", error)
        throw new Error(error)
   }
}
