import React from 'react'
import { UserProfile } from '../../types'
import { ScheduledNotificationType } from '../../types/scheduledNotification'
import { Dimmer, Loader, Header, Checkbox, List, Button, Segment, Table, Icon, Modal, Form, Input, Grid, Message } from 'semantic-ui-react'
import { AppState } from '../../reducers'
import { connect } from 'react-redux'
import { createFacilityQuestionOfTheDay, deleteFacilityQuestionOfTheDay, listFacilityQuestionsOfTheDay, updateFacilityQuestionOfTheDay } from '../../services/QuestionOfTheDay'
import { QuestionOfTheDay } from '../../types/questionOfTheDay'
import { toast } from 'react-toastify'
interface State {
    isFetching: boolean
    error: string | null
    isSaving: boolean
    contentBtnDisabled: boolean,
    Menu: boolean
    Activities: boolean
    CommunityMessages: boolean
    Rotm: boolean
    Sotm: boolean
    Birthdays: boolean
    QuestionOfTheDay: boolean
    facilityQuestions: Partial<QuestionOfTheDay>[]
    createQotdModal: boolean
    question: string
    questionSearchTerm: string
    selectedFacilityQuestion: Partial<QuestionOfTheDay> | null
    errorMessage: string | null
}

interface Props {
    profile: UserProfile | null
    view: 'list' | undefined
    residentA4hRoomId?: string
    showSubmitButton?: true
    handleNotificationsContentSubmit?: (scheduledNotification: string[]) => void
    Menu: boolean
    Activities: boolean
    CommunityMessages: boolean
    Rotm: boolean
    Sotm: boolean
    Birthdays: boolean
    QuestionOfTheDay?: boolean
    handleChange?: (item: string, isChecked: boolean) => Promise<void>
    isLoading?: boolean
}

class Content extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    state: State = {
        isFetching: false,
        error: null,
        isSaving: false,
        contentBtnDisabled: false,
        Menu: this.props.Menu,
        Birthdays: this.props.Birthdays,
        Activities: this.props.Activities,
        CommunityMessages: this.props.CommunityMessages,
        Rotm: this.props.Rotm,
        Sotm: this.props.Sotm,
        QuestionOfTheDay: this.props.QuestionOfTheDay || false,
        facilityQuestions: [],
        createQotdModal: false,
        question: "",
        questionSearchTerm: "",
        selectedFacilityQuestion: null,
        errorMessage: null,
    }

    async componentDidMount() {
        await this.refreshQotd()
    }

    refreshQotd = async () => {
        try {
            this.setState({
                isFetching: true
            })
            if (this.props.QuestionOfTheDay) {
                const questions = await listFacilityQuestionsOfTheDay(this.state.questionSearchTerm)
                if (!questions) throw new Error("Cannot fetch Facility questions")
                this.setState({ facilityQuestions: questions })
            } else {
                this.setState({
                    facilityQuestions: []
                })
            }
            this.setState({
                isFetching: false
            })
        } catch (error) {
            this.setState({
                isFetching: false
            })
            console.error(error);
        }
    }

    async handleToggleChange(item, isChecked) {
        if (this.props.handleChange) await this.props.handleChange(item, isChecked)
    }


    async handleSubmit() {
        try {
            this.setState({ errorMessage: null, contentBtnDisabled: true })

            const alertsData = { questionOfTheDay: this.state.QuestionOfTheDay, birthdays: this.state.Birthdays, activities: this.state.Activities, affirmations: this.state.CommunityMessages, rotm: this.state.Rotm, sotm: this.state.Sotm, menu: this.state.Menu }

            const alertsArr = Object.keys(alertsData).filter(key => alertsData[key])
            if (this.props.handleNotificationsContentSubmit) {
                await this.props.handleNotificationsContentSubmit(alertsArr)
            }
            await this.refreshQotd()
            this.setState({ contentBtnDisabled: false })
        } catch (e) {
            console.error(e)
        }
    }

    handleQotdSubmit = async () => {
        this.setState({
            isFetching: true,
            createQotdModal: false,
        })
        try {
            if (this.state.selectedFacilityQuestion) {
                const updateResponse = await updateFacilityQuestionOfTheDay(this.state.selectedFacilityQuestion, this.state.question)
                if (!updateResponse) throw new Error("Failed to update question of the day");
                toast.success("Successfully updated question of the day", {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                })
            } else {
                const createResponse = await createFacilityQuestionOfTheDay({ question: this.state.question });
                if (!createResponse) throw new Error("Failed to create question of the day");
                toast.success("Successfully created question of the day", {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                })
            }
        } catch (error) {
            if (typeof error === 'string') this.setState({ errorMessage: error })
            else if (error instanceof Error) this.setState({ errorMessage: error.message })
        }
        this.setState({
            selectedFacilityQuestion: null,
            question: ""
        })
        await this.refreshQotd() // isFetching is set false by refreshQotd
    }

    handleQotdDelete = async (id: string) => {
        this.setState({
            errorMessage: null,
            isFetching: true
        })
        try {
            const deleteResponse = await deleteFacilityQuestionOfTheDay(id)
            if (!deleteResponse) throw new Error("Failed to delete question of the day")
            toast.success("Successfully deleted question of the day", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
        } catch (error) {
            if (typeof error === 'string') this.setState({ errorMessage: error })
            else if (error instanceof Error) this.setState({ errorMessage: error.message })
        }
        await this.refreshQotd() // isFetching is set false by refreshQotd
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps !== this.props) {
            // The value has changed, so re-render the component
            this.setState({
                Birthdays: prevProps.Birthdays,
                Menu: prevProps.Menu,
                CommunityMessages: prevProps.CommunityMessages,
                Rotm: prevProps.Rotm,
                Sotm: prevProps.Sotm,
                Activities: prevProps.Activities,
            })
            // QuestionOfTheDay - need not be updated here
        }
    }
    render() {
        return (
            <>
                <Dimmer active={this.state.isFetching} inverted>
                    <Loader active={this.state.isFetching} />
                </Dimmer>
                <Modal
                    as={Form}
                    onClose={() => { this.setState({ createQotdModal: false }); this.setState({ question: "" }); }}
                    open={this.state.createQotdModal}
                    onSubmit={(e) => { e.preventDefault() }}
                >
                    <Modal.Header>{this.state.selectedFacilityQuestion ? "Edit the" : "Create a"} question of the day</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Form.Field>
                                <label>Question</label>
                                <Form.Input
                                    required={true}
                                    value={this.state.question || ''}
                                    placeholder="Question of the day"
                                    onChange={(e) => this.setState({ question: e.currentTarget.value })}
                                />
                            </Form.Field>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            disabled={!this.state.question} primary type="submit"
                            onClick={() => this.handleQotdSubmit()}
                        >
                            Submit
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Segment>
                    <Header as={"h3"}>Content</Header>
                    <Dimmer active={this.props.isLoading} inverted>
                        <Loader active={this.props.isLoading} />
                    </Dimmer>
                    <List>
                        {this.props.QuestionOfTheDay !== undefined && <List.Item>
                            <Checkbox toggle label="Question of the day" checked={this.state.QuestionOfTheDay} onChange={(e, data) => {
                                this.setState({
                                    QuestionOfTheDay: data.checked || false
                                })
                            }} />
                        </List.Item>}
                        <List.Item>
                            <Checkbox toggle label="Community News" checked={this.state.CommunityMessages} onChange={(e, data) => {
                                this.handleToggleChange("communityMessages", data.checked)
                                this.setState({
                                    CommunityMessages: data.checked || false
                                })
                            }} />
                        </List.Item>
                        <List.Item>
                            <Checkbox toggle label="Menu" checked={this.state.Menu} onChange={(e, data) => {
                                this.handleToggleChange("menu", data.checked)
                                this.setState({
                                    Menu: data.checked || false
                                })
                            }} />
                        </List.Item>
                        <List.Item>
                            <Checkbox toggle label="Calendar" checked={this.state.Activities} onChange={(e, data) => {
                                this.handleToggleChange("activities", data.checked)
                                this.setState({
                                    Activities: data.checked || false
                                })
                            }} />
                        </List.Item>
                        <List.Item>
                            <Checkbox toggle label="Birthdays" checked={this.state.Birthdays} onChange={(e, data) => {
                                this.handleToggleChange("birthdays", data.checked)
                                this.setState({
                                    Birthdays: data.checked || false
                                })
                            }} />
                        </List.Item>
                        <List.Item>
                            <Checkbox toggle label="Resident Spotlight" checked={this.state.Rotm} onChange={(e, data) => {
                                this.handleToggleChange("rotm", data.checked)
                                this.setState({
                                    Rotm: data.checked || false
                                })
                            }} />
                        </List.Item>
                        <List.Item>
                            <Checkbox toggle label="Staff Spotlight" checked={this.state.Sotm} onChange={(e, data) => {
                                this.handleToggleChange("sotm", data.checked)
                                this.setState({
                                    Sotm: data.checked || false
                                })
                            }} />
                        </List.Item>
                    </List>

                    {this.props.showSubmitButton && <Button primary disabled={this.state.contentBtnDisabled} onClick={this.handleSubmit.bind(this)}>Submit</Button>}
                </Segment>
                {(this.props.QuestionOfTheDay) &&
                    <Segment>
                        {this.state.errorMessage && <Message error>{this.state.errorMessage}</Message>}
                        <Grid stackable columns={2}>
                            <Grid.Column>
                                <Button primary onClick={() => this.setState({ selectedFacilityQuestion: null, errorMessage: null, createQotdModal: true })}>
                                    <Icon name="add" className="Button-icon" />Create Question of the day
                                </Button>
                            </Grid.Column>
                            <Grid.Column floated='right' tablet={5} mobile={8} computer={3}>
                                <Input
                                    placeholder="Search questions"
                                    icon='search'
                                    type="text"
                                    value={this.state.questionSearchTerm}
                                    onChange={(e) => this.setState({ questionSearchTerm: e.target.value })}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            this.refreshQotd()
                                        }
                                    }}
                                ></Input>
                            </Grid.Column>
                        </Grid>
                        {!!(this.state.facilityQuestions.length) && <Table attached='top'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell colSpan='3'>Question</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {this.state.facilityQuestions && this.state.facilityQuestions.map(
                                    (entry) =>
                                        entry && (
                                            <Table.Row key={entry._id}>
                                                <Table.Cell>{entry.question}</Table.Cell>
                                                <Table.Cell collapsing textAlign='right'><Button onClick={() => this.setState({ errorMessage: null, selectedFacilityQuestion: entry, question: entry.question || "" }, () => { this.setState({ createQotdModal: true }) })} primary> Edit </Button></Table.Cell>
                                                <Table.Cell collapsing textAlign='right'><Button onClick={async () => { if (entry._id) await this.handleQotdDelete(entry._id) }} color='red'> Delete </Button></Table.Cell>
                                            </Table.Row>
                                        ),
                                )}
                            </Table.Body>
                        </Table>}
                        {!(this.state.facilityQuestions.length) && <Message> There are no questions for this facility </Message>}
                    </Segment>
                }
            </>
        )
    }
}

function mapStateToProps({ authReducer }: AppState) {
    return {
        profile: authReducer.profile,
    }
}

export default connect(
    mapStateToProps
)(Content)
