import React from 'react'

import './style.less'
import { RouteComponentProps, withRouter } from 'react-router'
import {
    Dimmer,
    Loader,
    Tab,
    TabProps,
    Pagination,
    PaginationProps,
    Button,
    Modal,
    TransitionablePortal,
    Divider,
    Grid,
    Checkbox,
    Form,
    Dropdown,
    Segment,
    Header
} from 'semantic-ui-react'
import { DailyActivity, User, UserProfile } from '../../../types'
import 'flatpickr/dist/themes/airbnb.css'
import 'rc-time-picker/assets/index.css'
import { newActivity, Activities, deleteActivity, updateActivity, listActivityAttendees } from '../../../services/DailyActivities';
import { fetchAllActiveFacilityRegistrants } from '../../../services/Registrants'
import ActivityForm from '../../../components/ActivityForm'
import ListItemsPane from '../../../components/ListItemsPane'
import { getUploadUrl, uploadToSignedUrl, checkDestPlayableWithTimeDelay } from '../../../services/PplOfTheMonth'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'

// import KanbanColumn from './kanbanColumn'
import KanbanBoards from './kanban-boards'
import kanbanInitData from './kanban-init-data'
import { kanbanDataType } from '../../../types/kanbanDataTypes'
import ImageUploader from '../../../components/ImageUploader'


import { jsonTocsvDownloader, jsonTocsvDownloaderV2 } from '../../../util/jsonTocsvDownloader';
import CalendarEventContent from '../../../components/CalendarEventContent';
import { uploadSignageImageToS3, copySignageImageFromS3 } from '../../../services/ImageUpload'
import { toast } from 'react-toastify'
import moment from 'moment-timezone';
import { fetchOneFacility, fetchResidentRooms } from '../../../services/Facilities'
import { listRoomTypes } from '../../../services/RoomTypes';
import { fetchOneUser, updateUser } from '../../../services/Users';
import NotificationsFormContainer from '../../../components/NotificationFormContainer';
import ServiceInstances from '../../../components/ServiceInstances';
import { ServicesType } from '../../../types/ServicesTypes';
import ServiceInstanceFormCreate from '../../../components/ServiceInstances/ServiceInstanceFormCreate';
import { deleteServiceInstance, fetchAllActivityAndServiceInstance } from '../../../services/ServiceInstances';
import { sortByKey } from '../../../util/sortData';

interface State {
    isFetching: boolean;
    error: string | null;
    audioError: string | null;
    isSaving: boolean;
    activities: DailyActivity[];
    openCreateForm: boolean;
    activityFilter: string;
    activeTab: string;
    activePageNumber: any;
    copyActivity: DailyActivity | null;
    activeTabIndex: number;
    audioBlob;
    isCalendarView: boolean;
    selectedDateInCalendar: string | undefined;
    calendarTabIndex: number;
    selectedActivityId?: string | undefined;
    selectedActivity?: DailyActivity | undefined;
    showActivityDetail: boolean;
    selectedActivityAttendees?: any;
    kanbanBoard?: kanbanDataType;
    selectedAnnouncees?: string[];
    copiedActivity: boolean;
    showDetailModal: boolean;
    showRecurringDeleteConfirmModal: boolean;
    showRecurringEditConfirmModal: boolean;
    editRecurringActivities: boolean;
    isLoading: boolean;
    printBtnSpinner: boolean
    exportBtnSpinner: boolean
    copyImageUrl: string | undefined
    timezone?: string
    disableRecurrence?: boolean
    locationOptions: {
        key: string;
        text: string;
        value: string;
    }[]
    filteredActivities: DailyActivity[];
    selectedCalendarType: string[];
    fetchedActivities: DailyActivity[];
    defaultLocationId: string;
    loadDropdown: boolean;
    componentMounting: boolean;
    showSvcInstanceModal: boolean;
    svcInstanceData: Partial<ServicesType>;
    reloadDailySvcActivity: boolean;
    selectedStartDate: string;
    selectedEndDate: string;
    reloadServiceAccordianFlag: boolean;
    calendarDateOfDailytab: Date;
    initalCalendarDate: Date;
}

interface Props extends RouteComponentProps {
    profile: UserProfile | null;
}

class DailyActivities extends React.Component<Props, State> {
    private unlisten:  (() => void) | null = null; // Initialize unlisten as null
    private isServiceEnabled: boolean = this.props.profile && this.props.profile.FacilityConfigurations && this.props.profile.FacilityConfigurations.services ? true : false;
    constructor(props: Props) {
        super(props)
        this.state = {
            isFetching: false,
            error: null,
            audioError: null,
            isSaving: false,
            activities: [],
            openCreateForm: false,
            activityFilter: '',
            activeTab: 'active',
            activePageNumber: 1,
            copyActivity: null,
            activeTabIndex: 0,
            audioBlob: undefined,
            isCalendarView: false,
            selectedDateInCalendar: undefined,
            calendarTabIndex: 0,
            selectedActivityId: this.props.match.params['id'] || undefined,
            selectedActivity: undefined,
            showActivityDetail: false,
            kanbanBoard: kanbanInitData,
            copiedActivity: false,
            exportBtnSpinner: false,
            printBtnSpinner: false,
            copyImageUrl: undefined,
            showDetailModal: false,
            showRecurringDeleteConfirmModal: false,
            showRecurringEditConfirmModal: false,
            editRecurringActivities: false,
            isLoading: false,
            timezone: undefined,
            disableRecurrence: false,
            locationOptions: [],
            filteredActivities: [],
            selectedCalendarType: (this.props.profile && this.props.profile.selectedCalendarType && this.props.profile.selectedCalendarType.length && this.props.profile.selectedCalendarType) || [],
            fetchedActivities: [],
            defaultLocationId: '',
            loadDropdown: false,
            componentMounting: false,
            showSvcInstanceModal: false,
            svcInstanceData: {
                name: '',
                longDescription: '',
                shortDescription: '',
                Asset: '',
                calendarType: [],
              },
            reloadDailySvcActivity: false,
            selectedStartDate: '',
            selectedEndDate: '',
            reloadServiceAccordianFlag: false,
            calendarDateOfDailytab: new Date(),
            initalCalendarDate: new Date(),
        };
    }
    handlecalendarDateOfDailytab = (date: Date) => {
        this.setState({
            calendarDateOfDailytab: date
        })
    }

    handleChangeActiveTab = (listTab: string, tabNumber:number) => {
        this.setState({
            activeTab: listTab,
            activeTabIndex: tabNumber
        })
    }
    
    getLocationId(location: string, locationOptions: any[]) {
        const foundObject = locationOptions.find((option) => option.text === location);
        return foundObject ? foundObject.value : '';
    }

    openCloseCreateActivityForm(): void {
        if (this.state.copyActivity) {
            this.setState({
                openCreateForm: !this.state.openCreateForm,
                copyActivity: null,
            })
        } else {
            this.setState({
                openCreateForm: !this.state.openCreateForm,
            })
        }
    }

    handleLocationUpdate = (value: string[] = []) => {
        if (this.state.selectedActivity) {
            this.handleEventActivityEdit(value);
        }
    }

    handleSvcInsatanceModal = (startDate?: string, endDate?: string) => {
        if (startDate && endDate) {
            this.setState(prevState => ({
                svcInstanceData: {
                    ...prevState.svcInstanceData,
                    startDate: startDate,
                    endDate: endDate,
                    calendarType: this.state.selectedCalendarType,
                },
                showSvcInstanceModal: !this.state.showSvcInstanceModal
            }));
        } else {
            this.setState({
                showSvcInstanceModal: !this.state.showSvcInstanceModal,
                svcInstanceData: {
                    calendarType: this.state.selectedCalendarType,
                }
            });
        }
    }

    async deleteActivity(data): Promise<void> {
        this.setState({
            ...this.state,
            isSaving: true,
            isFetching: true,
        })
        try {
            let activities:any = this.state.activities;
            if (!activities || activities.length === 0) {
                if (this.state.selectedActivity) {
                    activities = [this.state.selectedActivity];
                }
            }
            let activity = activities.find((activity) => activity._id === data.id);
            if (activity) {
                if (activity.svcActivityInstance) {
                    const _id = activity._id;
                    if (_id) {
                        const response = await deleteServiceInstance({ _id, deleteGroup: false });
                        if (!response) {
                            throw new Error('Instance could not be deleted');
                        }
                    } else {
                        throw new Error('Instance could not be deleted');
                    }
                } else {
                    await deleteActivity(data);
                }
                //remove the item from the array
                activities = activities.filter((activity) => activity._id !== data.id);
                this.setState({
                    ...this.state,
                    activities
                })
            } else {
                throw new Error('Calendar entry could not be found');
            }
        } catch (error) {
            console.error(error.message || "Could not delete activity")
            this.setState({
                ...this.state,
                isSaving: false,
                isFetching: false,
                error: error.message || "Could not delete activity",
            });
        } finally {
            this.setState({
                ...this.state,
                isSaving: false,
                isFetching: false,
            })
        }
    }

    async deleteRecurringActivity(data){
        try {
            this.setState({
                ...this.state,
                isSaving: true,
            })
            await deleteActivity(data);
            await this.populateStateWithActivities('delete')
        } catch (error) {
            this.setState({
                ...this.state,
                isSaving: false,
                isFetching: false,
                error: error.message || 'Could not delete recurring activities',
            })
        }
    }

    editActivity(id: string): void {
        const activities = this.state.activities.map((activity: DailyActivity) => {
            if (activity._id === id) activity.edit = true
            return activity
        })
        this.setState({
            ...this.state,
            activities,
        })
    }

    cancelEditActivity(id?: string): void {
        this.setState({
            disableRecurrence: false
        })
        if (this.state.showActivityDetail) {
            this.setState({
                isFetching: false,
                isSaving: false
            })
        } else if (id) {
            const activities = this.state.activities.map((activity: DailyActivity) => {
                if (activity._id === id) delete activity.edit
                return activity
            })
            this.setState({
                ...this.state,
                activities,
                copyActivity: null,
                // selectedActivity: undefined,
                // selectedActivityId: '',
                openCreateForm: false,
                audioBlob: null,
            })
        } else {
            this.setState({
                openCreateForm: false,
                copyActivity: null,
                audioBlob: null,
            })
        }
    }

    copyActivity(id: string) {
        let activity = this.state.activities.find((activity: DailyActivity) => activity._id === id)
        document.getElementsByClassName('page-header')[0].scrollIntoView({ block: 'start', behavior: 'smooth' })
        if (activity) {
            if(activity.svcActivityInstance){
                this.setSvcInstanceData(activity);
                this.setState({
                    showSvcInstanceModal: true,
                })
            } else {
                this.setState({
                    openCreateForm: true,
                    copyActivity: activity
                })
            }
            }
    }

    passAudioBlob(audioBlob) {
        this.setState({
            audioBlob,
        })
    }

    async uploadAudiofile() {
        const { audioId = undefined, signedUrl = undefined } = await getUploadUrl('activities')

        const file = new File([this.state.audioBlob], audioId, {
            type: 'audio/wav',
        })

        await uploadToSignedUrl(file, signedUrl)

        return audioId
    }

    async uploadImageFile(activity: string, imageFile: File): Promise<void> {
        if (imageFile) {
            await uploadSignageImageToS3("activities", activity, imageFile.name, imageFile)
        } else {
            throw new Error("Image file is not provided")
        }
    }

    async copyUploadImageFile(activity: string, copyUrl: string): Promise<void> {
        if (copyUrl) {
            await copySignageImageFromS3('activities', copyUrl, activity)
        } else {
            throw new Error("url is not provided")
        }
    }

    async handleAudio(activity) {
        try {
            const audioId = await this.uploadAudiofile()

            if (audioId) {
                const destAudioId = audioId.replace(/\.wav$/, '.mp3')

                let destPlayableExists
                for (let i = 0; i < 6; i++) {
                    destPlayableExists = await checkDestPlayableWithTimeDelay(
                        destAudioId,
                        'activities',
                        Math.pow(2, i) * 1000,
                    )

                    if (destPlayableExists) {
                        break
                    }
                }

                if (destPlayableExists) {
                    console.log('audio submitting', activity, ' audioId:', destAudioId ? destAudioId : null)

                    try {
                        const insertedActivityId = await newActivity({
                            text: activity.text,
                            dateTimeString: activity.dateTimeString,
                            AddedBy: this.props.profile ? this.props.profile._id : '',
                            audioId: destAudioId ? destAudioId : null,
                            category: activity.category,
                            showAttendance: !!activity.showAttendance,
                            recurrence: activity.recurrence,
                            calendarType: activity.calendarType
                        });

                        if (insertedActivityId) {
                            const signageActivityId = Array.isArray(insertedActivityId) ? insertedActivityId.sort((a,b) => a.localeCompare(b))[0] : insertedActivityId;
                            if (activity.imageFile) {
                                await this.uploadImageFile(signageActivityId, activity.imageFile)
                            } else if (activity.copyImageUrl) {
                                await this.copyUploadImageFile(signageActivityId, activity.copyImageUrl)
                            } else if (this.state.copyImageUrl) {
                                await this.copyUploadImageFile(signageActivityId, this.state.copyImageUrl)
                            }
                        }

                        if (this.state.calendarTabIndex) {
                            await this.populateStateWithActivities('save')
                        } else {
                            const activityDate = new Date(activity.dateTimeString)
                            var firstDay =
                                new Date(activityDate.getFullYear(), activityDate.getMonth(), 1).getTime() -
                                86400000 * 6
                            var lastDay =
                                new Date(activityDate.getFullYear(), activityDate.getMonth() + 1, 0).getTime() +
                                86400000 * 14
                            this.handleMonthChange({ start: new Date(firstDay), end: new Date(lastDay) })
                        }
                    } catch (e) {
                        this.setState({
                            openCreateForm: false,
                            isSaving: false,
                            isFetching: false,
                            audioBlob: undefined,
                            error: e.message,
                        })
                    }
                } else {
                    this.setState({
                        isSaving: false,
                        isFetching: false,
                        audioBlob: undefined,
                        audioError: 'Failed to process audio. Please start over',
                    })
                }
            } else {
                this.setState({
                    isSaving: false,
                    isFetching: false,
                    audioBlob: undefined,
                    audioError: 'Error uploading audio. Please start over',
                })
            }
        } catch (e) {
            this.setState({
                isSaving: false,
                isFetching: false,
                audioBlob: undefined,
                error: e.message,
            })
        }
        return false
    }

    async handleSubmit(activity: DailyActivity): Promise<void> {
        this.setState({
            isSaving: true,
            isFetching: true,
            error: null,
            disableRecurrence: false,
        })

        //handle audio here
        if (this.state.audioBlob) {
            try {
                await this.handleAudio.bind(this)(activity)
            } catch (e) {
                this.setState({
                    openCreateForm: false,
                    isSaving: false,
                    isFetching: false,
                    audioBlob: undefined,
                    error: "Could not save activity",
                })
            }
        } else {
            try {
                const insertedActivityId = await newActivity({
                    text: activity.text,
                    dateTimeString: activity.dateTimeString,
                    AddedBy: this.props.profile ? this.props.profile._id : '',
                    category: activity.category,
                    audioId: activity.audioId ? activity.audioId : undefined,
                    playableUrl: activity.playableUrl ? activity.playableUrl : undefined,
                    showAttendance: !!activity.showAttendance,
                    recurrence: activity.recurrence,
                    calendarType: activity.calendarType
                });
                
                if (insertedActivityId) {
                    const signageActivityId = Array.isArray(insertedActivityId) ? insertedActivityId.sort((a,b) => a.localeCompare(b))[0] : insertedActivityId;
                    if (activity.imageFile) {
                        await this.uploadImageFile(signageActivityId, activity.imageFile)
                    } else if (activity.copyImageUrl) {
                        await this.copyUploadImageFile(signageActivityId, activity.copyImageUrl)
                    } else if (this.state.copyImageUrl) {
                        await this.copyUploadImageFile(signageActivityId, this.state.copyImageUrl)
                    }
                }

                if (this.state.calendarTabIndex) {
                    await this.populateStateWithActivities('save')
                } else {
                    const activityDate = activity.dateTimeString && new Date(activity.dateTimeString) || new Date()
                    var firstDay =
                        new Date(activityDate.getFullYear(), activityDate.getMonth(), 1).getTime() - 86400000 * 6
                    var lastDay =
                        new Date(activityDate.getFullYear(), activityDate.getMonth() + 1, 0).getTime() + 86400000 * 14
                    this.handleMonthChange({ start: new Date(firstDay), end: new Date(lastDay) })
                }
            } catch (error) {
                toast.warn(error instanceof Error ? error.message : "Error during menu creation.", {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                })
                this.setState({
                    isSaving: false,
                    isFetching: false,
                    openCreateForm: false,
                    error: 'Could not save Activity.',
                })
            }
        }
        this.reloadActivities()
        if (this.state.showActivityDetail) {
            this.setState({
                isSaving: false,
                isFetching: false,
            })
        }
    }

    async handleUpdate(activity): Promise<void> {
        this.setState({
            isSaving: true,
            isFetching: true,
            error: null,
            disableRecurrence: false,
        });
        try {
            // If audio blob - first do handle Audio so that the audio gets uploaded.
            // Then get the url/id and send it in update activity
            // Make sure after it is done the audio blob gets cleared from the state. The same has to be done in case of the submit/create
            // Otherwise the audio might get replaced by some old audio every time edit is attempted

            if (this.state.audioBlob) {
                try {
                    const audioId = await this.uploadAudiofile()

                    if (audioId) {
                        const destAudioId = audioId.replace(/\.wav$/, '.mp3')

                        let destPlayableExists
                        for (let i = 0; i < 6; i++) {
                            destPlayableExists = await checkDestPlayableWithTimeDelay(
                                destAudioId,
                                'activities',
                                Math.pow(2, i) * 1000,
                            )

                            if (destPlayableExists) {
                                break
                            }
                        }

                        if (destPlayableExists) {
                            try {
                                const updatedActivityResponse = await updateActivity({
                                    _id: activity._id,
                                    text: activity.text,
                                    dateTimeString: activity.dateTimeString,
                                    UpdatedBy: this.props.profile ? this.props.profile._id : '',
                                    audioId: destAudioId ? destAudioId : null,
                                    category: activity.category,
                                    showAttendance: this.state.selectedActivity
                                        ? this.state.selectedActivity.showAttendance
                                        : !!activity.showAttendance,
                                    recurrence: activity.recurrence,
                                    ...(this.state.editRecurringActivities ? { modifyGroup: true} : { modifyGroup: false }),
                                    ...((activity.imageFile || activity.copyImageUrl) ? {updateSignage: true} : {}),
                                    calendarType: activity.calendarType
                                });

                                if (updatedActivityResponse) {
                                    const signageActivityId =  this.state.editRecurringActivities ? activity.srcId : activity._id;
                                    if (activity.imageFile || activity.copyImageUrl) {
                                        if (activity.copyImageUrl) {
                                            await this.copyUploadImageFile(signageActivityId, activity.copyImageUrl)
                                        } else {
                                            await this.uploadImageFile(signageActivityId, activity.imageFile)
                                        }
                                    }
                                }
                                this.setState({
                                    editRecurringActivities: false
                                })
                            } catch (e) {
                                this.setState({
                                    openCreateForm: false,
                                    isSaving: false,
                                    isFetching: false,
                                    audioBlob: undefined,
                                    error: e.message,
                                    editRecurringActivities: false
                                })
                            }
                        } else {
                            this.setState({
                                isSaving: false,
                                isFetching: false,
                                audioBlob: undefined,
                                openCreateForm: false,
                                audioError: 'Failed to process audio. Please start over',
                                editRecurringActivities: false
                            })
                        }
                    }
                } catch (e) {
                    console.log(e);
                    toast.error(e.message || 'Could not update activity', {
                        position: 'bottom-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }); // discuss better way to show error
                    this.setState({
                        isSaving: false,
                        isFetching: false,
                        audioBlob: undefined,
                        openCreateForm: false,
                        editRecurringActivities: false
                    })
                }
            } else {
                try {
                    const updatedActivityResponse = await updateActivity({
                        _id: activity._id,
                        text: activity.text,
                        dateTimeString: activity.dateTimeString,
                        UpdatedBy: this.props.profile ? this.props.profile._id : '',
                        category: activity.category,
                        showAttendance: this.state.selectedActivity
                            ? this.state.selectedActivity.showAttendance
                            : !!activity.showAttendance,
                        recurrence: activity.recurrence,
                        ...(this.state.editRecurringActivities ? { modifyGroup: true} : { modifyGroup: false }),
                        ...((activity.imageFile || activity.copyImageUrl) ? {updateSignage: true} : {}),
                        calendarType: activity.calendarType
                    });
                if (updatedActivityResponse) {
                    const signageActivityId =  this.state.editRecurringActivities ? activity.srcId : activity._id;
                    if (activity.imageFile || activity.copyImageUrl) {
                        if (activity.copyImageUrl) {
                            await this.copyUploadImageFile(signageActivityId, activity.copyImageUrl)
                        } else {
                            await this.uploadImageFile(signageActivityId, activity.imageFile)
                        }
                    }
                }
                this.setState({
                    editRecurringActivities: false
                })
                } catch(e){
                    toast.error(e.message || 'Could not update activity', {
                        position: 'bottom-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }); // discuss better way to show error
                    this.setState({
                        isSaving: false,
                        isFetching: false,
                        audioBlob: undefined,
                        openCreateForm: false,
                        editRecurringActivities: false
                    })
                }
            }
            await this.populateStateWithActivities('update', activity)
        } catch (error) {
            toast.error(error.message || 'Could not update activity', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            }); // discuss better way to show error
        } finally {
            this.handleRedirect();
        }
    }


    async fetchActivity(activityId) {
        try {
            const activity = await Activities({ _id: activityId });
            if(activity && activity.Result && activity.Result.length > 0) {
                return activity.Result[0];
            } else {
                return null;
            }
        } catch (error) {
            console.log("error in fetchActivity", error);
            toast.error(`Cannot fetch activity`, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
            });
        }
        
    }

    async getActivities(filters: any = {}): Promise<DailyActivity[]> {
        this.setState({ isFetching: true, error: null });
        try {
            if (!Object.keys(filters).length && !filters.startDateTimeString && !filters.endDateTimeString) {
                if (this.state.selectedStartDate && this.state.selectedEndDate) {
                    filters.startDateTimeString = this.state.selectedStartDate;
                    filters.endDateTimeString = this.state.selectedEndDate;
                } else {
                    const firstDayTimeString = moment.tz(new Date(), this.state.timezone).startOf('month').format('YYYY-MM-DDTHH:mm:ss');
                    const lastDayDayTimeString = moment.tz(new Date(), this.state.timezone).endOf('month').format('YYYY-MM-DDTHH:mm:ss');
                    filters.startDateTimeString = firstDayTimeString;
                    filters.endDateTimeString = lastDayDayTimeString;
                }
            }
            const calendarType = this.state.selectedCalendarType.length == 0 ? undefined : this.state.selectedCalendarType;
            const combinedData = await fetchAllActivityAndServiceInstance({ startDateTimeString: filters.startDateTimeString, endDateTimeString: filters.endDateTimeString, calendarType });
            this.setState({ isFetching: false, error: null });
            return combinedData;
        } catch (error) {
            this.setState({ isFetching: false, error: null });
            console.log("error in fetchActivity", error);
            toast.error(`Cannot fetch activity`, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
            });
            return [];
        }
    }

    async fetchActivityAttendees(activity): Promise<any> {
        this.setState({ isFetching: true, error: null });
        const attendees = await listActivityAttendees(activity._id)
        const facilityRegistrants = await fetchAllActiveFacilityRegistrants(activity.Facility, true /* excludeAlisImage */, true, /* includeRoomInfo */ );
        const residentRooms = await fetchResidentRooms();
        await this.populateStateWithActivities(undefined)
        return [attendees, facilityRegistrants, residentRooms]
    }

    async setActivityDateFilter(time): Promise<void> {
        const date = moment(time).format('YYYY-MM-DD');
        const startDateTimeString = moment.tz(date, this.state.timezone).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
        const endDateTimeString = moment.tz(date, this.state.timezone).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
        const activities = await this.getActivities({startDateTimeString, endDateTimeString});
        this.setState({
            activities,
            activePageNumber: 1,
            activityFilter: date,
        })
    }

    clearDateFilter(): void {
        if (this.state.activeTab === 'active') {
            this.setListView(0)
        }
        if (this.state.activeTab === 'history') {
            this.setListView(1)
        }
    }

    async handleTabChange(_: React.MouseEvent<HTMLDivElement, MouseEvent>, data: TabProps): Promise<void> {
        // if (this.state.activeTabIndex === 2) {
            this.cancelEditActivity();
            await this.setListView(data.activeIndex);
        // }

    }

    async setListView(activeIndex) {
        let selectedDate, activeTabIndex, activeTab;
        if (activeIndex === 0) {
            selectedDate = moment.tz(this.state.timezone).format('YYYY-MM-DD');
            activeTabIndex = 0;
            activeTab = 'active';
        } else if (activeIndex === 1) {
            selectedDate = moment().subtract(1, 'days').tz(this.state.timezone).format('YYYY-MM-DD');
            activeTabIndex = 1;
            activeTab = 'history';
        }
        const startDateTimeString = moment.tz(selectedDate, this.state.timezone).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
        const endDateTimeString = moment.tz(selectedDate, this.state.timezone).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
        const activities = await this.getActivities({ startDateTimeString, endDateTimeString });

        this.setState({
            activeTabIndex,
            activeTab,
            activities,
            activePageNumber: 1,
            activityFilter: selectedDate,
        });
    }

    async handleMainTabChange(_: React.MouseEvent<HTMLDivElement, MouseEvent>, data: TabProps) {
        try {
            this.setState({ isFetching: true });
            this.cancelEditActivity();
            if(this.state.calendarTabIndex === data.activeIndex) return;
            if (data.activeIndex === 0) {
                this.setState({
                    calendarTabIndex: 0,
                });
                this.props.history.push('/admin/dailyactivities?tab=daily');
            }
            if (data.activeIndex === 1) {
                this.setState({
                    activities: this.state.activities,
                    activePageNumber: 1,
                    calendarTabIndex: 1,
                });
                this.props.history.push('/admin/dailyactivities?tab=calendar');
            }
            if (data.activeIndex === 2) {
                await this.setListView(0);
                this.setState({
                    ...this.state,
                    calendarTabIndex: 2
                });
                this.props.history.push('/admin/dailyactivities?tab=list');
            }
        } catch (error) {
            this.setState({ isFetching: false });
            console.log("error in changing tab", error);
            toast.error("Something went wrong", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            if (data.activeIndex === 0) {
                this.setState({ isFetching: false });
            }
        }
    }

    changePage(e, data: PaginationProps): void {
        this.setState({
            activePageNumber: data.activePage,
        })
    }

    getPaginatedActivity() {
        if (this.state.activities.length > this.paginationPerPageNumber) {
            const startNumber = (this.state.activePageNumber - 1) * this.paginationPerPageNumber
            const endNumber = this.state.activePageNumber * this.paginationPerPageNumber - 1

            let returnActivities = this.state.activities

            if (this.state.activeTab === 'history') {
                returnActivities = this.state.activities.slice()
                returnActivities.sort((a, b) => b.timestamp - a.timestamp)
            }

            return returnActivities.filter((activity: DailyActivity, index: number) => {
                return index >= startNumber && index <= endNumber
            })
        }
        return this.state.activities
    }

    async populateStateWithActivities(readActivityIntent, editedActivity = undefined) {
        this.setState({isFetching: true});
        if (!this.state.showActivityDetail) {
            try {
                const activities = await this.getActivities();

                activities.forEach((activity: DailyActivity) => {
                    const time = activity.startDate ? activity.startDate : activity.timestamp || activity.dateTimeString;
                    activity.date = new Date(time).toLocaleDateString('en-US');
                });

                if (
                    this.state.calendarTabIndex === 1 &&
                    (readActivityIntent === 'mount' || readActivityIntent === 'update' || readActivityIntent === 'delete')
                ) {
                    this.setState({
                        ...this.state,
                        isSaving: false,
                        openCreateForm: false,
                        activeTab: 'active',
                        activities: activities,
                        fetchedActivities: activities,
                        activePageNumber: 1,
                        activeTabIndex: 0,
                        audioBlob: null,
                    });
                } else {
                    if (
                        (readActivityIntent === 'update' || readActivityIntent === 'delete') &&
                        this.state.activeTab === 'history'
                    ) {
                        this.setState({
                            ...this.state,
                            isSaving: false,
                            openCreateForm: false,
                            activeTab: 'history',
                            activities: activities,
                            activePageNumber: 1,
                            activeTabIndex: 1,
                            audioBlob: null,
                        });
                    } else {
                        this.setState({
                            ...this.state,
                            isSaving: false,
                            openCreateForm: false,
                            activeTab: 'active',
                            activities: activities,
                            activePageNumber: 1,
                            activeTabIndex: 0,
                            audioBlob: null,
                        });
                    }
                }
            } catch (error) {
                this.setState({
                    ...this.state,
                    isFetching: false,
                    error,
                });
            } finally {
                this.setState({
                    isFetching: false,
                });
            }
        } else {
            this.setState({
                selectedActivity: editedActivity ? editedActivity : this.state.selectedActivity,
                isFetching: false,
                isSaving: false,
                showActivityDetail: editedActivity ? false : true,
            });
        }
        if(readActivityIntent == 'delete'){ //above code sets selected activity quick fix
        this.setState({
            selectedActivity: undefined,
            selectedActivityId: undefined
        })
        }
    }

    handleEventActivityEdit = async (value: null | string[]) => {
        this.setState(
            {
                kanbanBoard: kanbanInitData,
                isFetching: true,
            },
            async () => {
                const selectedActivity = this.state.selectedActivity;
                if(!selectedActivity) return;
                const [selectedActivityAttendees, allFacilityRegistrants, residentRooms] = await this.fetchActivityAttendees(
                    selectedActivity,
                );

                const facilityRegistrants = allFacilityRegistrants.filter((registrant) => {
                    if (value) {
                        if (value && Array.isArray(value) && value.length > 0) {
                            return registrant.Unit ? (value.includes(registrant.Unit.RoomCategory) || value.includes(this.getLocationId('Other', this.state.locationOptions))) : true;
                        }
                    } else {
                        if (selectedActivity && selectedActivity.calendarType && selectedActivity.calendarType.length > 0) {
                            return registrant.Unit ? (selectedActivity.calendarType.includes(registrant.Unit.RoomCategory)): true;
                        } else {
                            return true;
                        }
                    }
                });

                // let kanbanDataTemp:kanbanDataType = Object.assign({}, kanbanInitData)
                let kanbanDataTemp: kanbanDataType = JSON.parse(JSON.stringify(kanbanInitData));
                let activityAnnounceeNames: string[] = [];

                const intendedRegistrantIds: String[] = [];

                selectedActivityAttendees.Result.forEach((attendeeObj) => {
                    const room = residentRooms.find(roomObj => roomObj.ResidentId === attendeeObj.registrantId);
                    attendeeObj.key = attendeeObj._id;
                    attendeeObj.id = attendeeObj._id;
                    attendeeObj.title = attendeeObj.registrant.FirstName + ' ' + attendeeObj.registrant.LastName;
                    attendeeObj.roomName = room ? room.RoomName : undefined;

                    if (attendeeObj.status === 'intend_attend') {
                        delete attendeeObj.participation;
                        delete attendeeObj.declineReason;
                        kanbanDataTemp.columns[1].cards.push(attendeeObj);
                        activityAnnounceeNames.push(attendeeObj.title);
                    }

                    if (attendeeObj.status === 'attended') {
                        attendeeObj.participation = attendeeObj.participation || 'active';
                        kanbanDataTemp.columns[2].cards.push(attendeeObj);
                    }

                    if (attendeeObj.status === 'removed') {
                        attendeeObj.declineReason = attendeeObj.declineReason || 'Not Interested';
                        kanbanDataTemp.columns[3].cards.push(attendeeObj);
                    }
                    intendedRegistrantIds.push(attendeeObj.registrantId);
                });

                facilityRegistrants.forEach((registrant) => {
                    let attendeeObj = {
                        key: 'temp' + registrant._id,
                        id: 'temp' + registrant._id,
                        registrantId: registrant._id,
                        title: registrant.FirstName + ' ' + registrant.LastName,
                        registrant: {
                            FirstName: registrant.FirstName,
                            LastName: registrant.LastName,
                        },
                        roomName: registrant.Unit ? registrant.Unit.Name : undefined,
                    };
                    if (!intendedRegistrantIds.includes(registrant._id)) {
                        kanbanDataTemp.columns[0].cards.push(attendeeObj);
                    }
                });

                this.setState({
                    ...this.state,
                    showActivityDetail: true,
                    selectedActivityAttendees,
                    error: '',
                    kanbanBoard: kanbanDataTemp,
                    selectedAnnouncees: activityAnnounceeNames,
                    isFetching: false,
                });
            },
        );
    }

    setShowRecurringEditConfirmModal(selectedActivityId){
        this.setState({ showRecurringEditConfirmModal: true, selectedActivityId });
    }

    setSelectedActivity(selectedActivity) {
        this.setState({ selectedActivity });
    }

    handleEventClick(payload) {
        this.handlecalendarDateOfDailytab(payload.event._instance.range.start || new Date());
        const id = payload.event._def.publicId;
        const selectedActivity = this.state.activities.find(
            (event) => event._id === id,
        );
        if(selectedActivity && selectedActivity["svcActivityInstance"]) {
            this.setState({showActivityDetail: true, selectedActivityId: id, selectedActivity: selectedActivity})
            const selectedTab = this.props.history.location.search;
            const selectedDate = this.state.selectedStartDate && moment(this.state.selectedStartDate).format('YYYY-MM-DD')
            this.props.history.push(`/admin/dailyactivities/serviceinstance/${id}`, { selectedTab, selectedDate });
        } else if (selectedActivity && selectedActivity.recurrence && !selectedActivity["svcActivityInstance"]) {
            const date = selectedActivity.dateTimeString;
            const activityFilter = moment(date).startOf('day').format("YYYY-MM-DDTHH:mm:ss");
            this.setState({
                ...this.state,
                selectedDateInCalendar: date,
                activityFilter: activityFilter,
                showRecurringEditConfirmModal: true,
                selectedActivityId: payload.event._def.publicId,
                selectedActivity: selectedActivity,
                error: '',
            });
        } else {
            this.handleRedirect(id);
        }
    }

    handleRedirect(id?: string, calendarDate?: Date) {
        const selectedDate = calendarDate && moment(calendarDate).format('YYYY-MM-DD') || this.state.calendarDateOfDailytab && moment(this.state.calendarDateOfDailytab).format('YYYY-MM-DD');
        if (id) {
            const selectedTab = this.props.history.location.search;
            this.props.history.push(`/admin/dailyactivities/${id}/${this.state.editRecurringActivities ? true : false}`, { selectedTab, selectedDate });
        } else {
            const previousUrl = this.props.history.location.state ? this.props.history.location.state : '/';
            const previousDate = previousUrl.selectedDate || moment(new Date()).format('YYYY-MM-DD');
            const queryParams = new URLSearchParams(previousUrl.selectedTab);
            const previousTab = queryParams.get('tab') || "daily";
            this.props.history.push(`/admin/dailyactivities?tab=${previousTab}&date=${previousDate}`);
        }
    }

    handleRedirectedActivity(selectedActivity) {
        const date = selectedActivity.dateTimeString;
        const activityFilter = moment(date).startOf('day').format("YYYY-MM-DDTHH:mm:ss");
        this.setState({
            ...this.state,
            selectedDateInCalendar: date,
            activityFilter: activityFilter,
            selectedActivityId: String(selectedActivity._id),
            selectedActivity: selectedActivity,
            error: '',
        }, () => this.handleEventActivityEdit(null));

    }


    componentDidUpdate(prevProps, prevState): void {
        if (this.state.selectedCalendarType !== prevState.selectedCalendarType && this.state.calendarTabIndex === 1) {
            const firstDay = this.state.selectedStartDate ? this.state.selectedStartDate : moment(new Date()).startOf('month').format('YYYY-MM-DDTHH:mm:ss');
            const lastDay = this.state.selectedEndDate ? this.state.selectedEndDate : moment(new Date()).endOf('month').format('YYYY-MM-DDTHH:mm:ss');
            this.handleMonthChange({ start: new Date(firstDay), end: new Date(lastDay) }); 
        }
        const queryParams = new URLSearchParams(window.location.search); // chooseing the tab
        const currentTab = queryParams.get('tab');
        let activityTabIndex = this.isServiceEnabled ? 0 : 1; // default to calendar view if services in not eanable for the facility
        if (currentTab === 'calendar') {
            activityTabIndex = 1;
        } else if (currentTab === 'list') {
            activityTabIndex = 2;
        }
        if (activityTabIndex !== prevState.calendarTabIndex) {
            this.setState({
                ...this.state,
                calendarTabIndex: activityTabIndex,
            });
        }
    }

    async componentDidMount() {
        this.setState({
            componentMounting: true,
        });
        try {
            const queryParams = new URLSearchParams(this.props.history.location.search); //to get the tab from the url
            const currentTab = queryParams.get('tab') || (this.isServiceEnabled ? 'daily' : 'calendar');
            const queryString = this.props.history.location.search || ""; // Get the query string, e.g., "?tab=daily&date=2024-06-04"
            const urlParams = new URLSearchParams(queryString); // Create a URLSearchParams object
            const dateString = urlParams.get('date'); // Get the value of the 'date' parameter
            const initialDate = dateString ? moment(dateString).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD");
            this.setState({
                initalCalendarDate: initialDate,
                selectedDateInCalendar:initialDate,
                selectedStartDate: moment(initialDate).startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
                selectedEndDate: moment(initialDate).endOf('month').format('YYYY-MM-DDTHH:mm:ss'),
            })
            let activityTabIndex = this.isServiceEnabled ? 0 : 1;
            if (currentTab === 'calendar') {
                activityTabIndex = 1;
            } else if (currentTab === 'list') {
                activityTabIndex = 2;
            }
           

            const facilityId = this.props.profile && this.props.profile.Facility;
            const [facilityData, userData, roomTypes]: any[] = await Promise.all([
                facilityId ? fetchOneFacility(facilityId) : {},
                this.props.profile ? fetchOneUser(this.props.profile._id) : {},
                listRoomTypes()
              ]);
            const FacilityTimeZone = facilityData.FacilityTimeZone;
            if (!FacilityTimeZone) {
                // if facility timezone is missing it'll be stuck on loading screen
                toast.warn("Facility timezone is not set, Please contact support", {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                return;
            }
            this.setState({
                timezone: FacilityTimeZone,
            });
            if (this.props.match.params['id']) {
                const activityId = this.props.match.params['id']
                const recurringActivities : boolean = JSON.parse(this.props.match.params['recurringActivities'] || "false");
                const selectedActivity = await this.fetchActivity(activityId);
                if(!selectedActivity) {
                    toast.warn("Activity not found", {
                        position: 'bottom-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    this.setState({
                        componentMounting: false,
                    });
                    this.handleRedirect();
                    return;
                }
                const date = selectedActivity.dateTimeString ? selectedActivity.dateTimeString : moment.tz(selectedActivity.timestamp, FacilityTimeZone).format("YYYY-MM-DDTHH:mm:ss");
                const activityFilter = moment(date).startOf('day').format("YYYY-MM-DDTHH:mm:ss")
                this.setState({
                    selectedActivity,
                    selectedActivityId: activityId,
                    showActivityDetail: true,
                    editRecurringActivities: recurringActivities,
                    selectedDateInCalendar: date,
                    activityFilter: activityFilter,
                }, () => this.handleRedirectedActivity(selectedActivity))
                this.setState({
                    componentMounting: false,
                });
                return
            }
            if (roomTypes && roomTypes.Result && roomTypes.Result.length > 0) {
                const locationOptions = roomTypes.Result.map(el => ({ key: el._id, value: el._id, text: el.Name }));
                const defaultLocationId = this.getLocationId('Other', locationOptions);
                const defaultCalendarType = facilityData.defaultCalendarType ? [facilityData.defaultCalendarType] : [defaultLocationId];
                this.setState({
                    locationOptions,
                    selectedCalendarType: (userData && userData.selectedCalendarType && userData.selectedCalendarType.length && userData.selectedCalendarType) || defaultCalendarType,
                    defaultLocationId,
                    calendarTabIndex: activityTabIndex
                });
            }
            if(this.state.calendarTabIndex === 1) {
                await this.populateStateWithActivities('mount');
            }
        } catch (error) {
            console.error(error);
            toast.error("Something went wrong",{position: 'bottom-center', autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,});
        } finally{
            this.setState({
                componentMounting: false,
            });
            this.unlisten = this.props.history.listen(this.handlePathnameChange);
        }
    }

    componentWillUnmount() {
        // Call the stored unlisten function to remove the listener
        if (this.unlisten) {
            this.unlisten();
        }
    }

    handlePathnameChange = () => {
        const { pathname } = this.props.history.location;
        if (pathname === '/admin/dailyactivities') {
            this.setState({
                showActivityDetail: false,
                kanbanBoard: kanbanInitData,
                selectedAnnouncees: undefined,
                editRecurringActivities: false
            });
        }
    }

    async handleMonthChange(payload) {
        try {
            if (this.props.match.params['id']) return; // avoid fetch calendar data when in detailed view
            let fixedEndDate = new Date(payload.end.getTime() - 1); // subtract 1 ms from end so that the end date is not in the next month
            const firstDay = moment(payload.start).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
            const lastDay = moment(fixedEndDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
            this.setState({
                selectedStartDate: firstDay,
                selectedEndDate: lastDay,
            });
            const activities = await this.getActivities({ startDateTimeString: firstDay, endDateTimeString: lastDay });
    
            this.setState({
                ...this.state,
                activities: activities,
                fetchedActivities: activities,
                isSaving: false,
                isFetching: false,
                openCreateForm: this.state.copiedActivity ? true : false,
                selectedActivityId: '',
                selectedActivity: undefined,
                error: '',
                copiedActivity: false,
            });
        } catch (error) {
            console.log("error in handleMonthChange", error);
            toast.error("Something went wrong",{position: 'bottom-center', autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,});
        }
    }

    async handleDateClick(payload) {
        this.setState({
            ...this.state,
            error: '',
            openCreateForm: true,
            selectedDateInCalendar: `${payload.dateStr}T09:00:00`,
            activityFilter: `${payload.dateStr}T00:00:00`,
        });
    }

    async handleCancel() {
        this.setState({
            ...this.state,
            openCreateForm: false,
        });
    }

    setSvcInstanceData = (data) => {
        this.setState({
            svcInstanceData: data
        });
    }

    closeSvcInstanceModal = () => {
        this.setState({
            showSvcInstanceModal: false
        });
        this.setState({
            svcInstanceData: {
                name: '',
                longDescription: '',
                shortDescription: '',
                Asset: '',
                calendarType: [],
            }
        });
    }

    reloadActivities() {
        if(this.state.calendarTabIndex === 0) {
           this.setState({reloadDailySvcActivity: !this.state.reloadDailySvcActivity});
        } 
        if(this.state.calendarTabIndex === 1) {
            this.populateStateWithActivities('mount');
        }
    } 
    
    reloadChildComponents(){
        this.setState({reloadServiceAccordianFlag: !this.state.reloadServiceAccordianFlag});
       };

    paginationPerPageNumber = 10;

    panes0 = [
        {
            ...(this.isServiceEnabled ? {
                menuItem: 'Daily',
                render: () => (
                    <div>
                        <ServiceInstances selectedCalendarType={this.state.selectedCalendarType} reloadSvcInstanceData={this.state.reloadDailySvcActivity} source={"DailyActivities"} handleRedirect={this.handleRedirect.bind(this)} showSvcInstanceModal={this.handleSvcInsatanceModal} showCreateForm={this.openCloseCreateActivityForm.bind(this)}
                            setShowRecurringEditConfirmModal={this.setShowRecurringEditConfirmModal.bind(this)} setSelectedActivity={this.setSelectedActivity.bind(this)}
                            reloadServiceAccordianFlag={this.state.reloadServiceAccordianFlag} handlecalendarDateOfDailytab={this.handlecalendarDateOfDailytab.bind(this)} 
                        />
                    </div>
                ),
            } : {})
        }, 
        {
            menuItem: 'Monthly',
            render: () => {
                const getColor = (category) => {
                    switch (category) {
                        case 'wellness':
                            return '#2987CD';
                        case 'social':
                            return '#69B546';
                        case 'brain':
                            return '#EAC31F';
                        case 'fitness':
                            return '#E98530';
                        case 'snack':
                            return '#183466';
                        default:
                            return '#2987CD';
                    }
                };

                let exportCalendarData = async () => {
                    if (this.state.exportBtnSpinner) {
                        toast.warn("Task in progress, please wait until it's completed.", {
                            position: 'bottom-center',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        })
                        return
                    }
                    this.setState({ exportBtnSpinner: true })
                    const exportData = this.state.activities.map((instance) => {
                        return {
                            Name: instance.name && instance.name.replace(/\n/g, '') || '-',
                            Description: instance.shortDescription || '-',
                            Category: instance.category || '-',
                            Tags: instance.defaults && instance.defaults.tags || '-',
                            StartDate: instance.startDate ? moment(instance.startDate).format('MM-DD-YYYY') : '-',
                            StartTime: instance.startDate ? moment(instance.startDate).format('hh:mm A') : '-',
                            EndDate: instance.endDate ? moment(instance.endDate).format('MM-DD-YYYY') : '-',
                            EndTime: instance.endDate ? moment(instance.endDate).format('hh:mm A') : '-',
                        };
                    });
                    await jsonTocsvDownloaderV2(exportData, "Calendar")
                    this.setState({ exportBtnSpinner: false })

                }

                return (
                    <div className="menu-calendar-view">
                        <FullCalendar
                            customButtons={{
                                export: {
                                    text: this.state.exportBtnSpinner ? 'Loading...' : 'Export data',
                                    click: function () {
                                        exportCalendarData();
                                    },
                            }, 
                            print: {
                                text: this.state.printBtnSpinner ? 'Loading...' : 'Print Calendar',
                                click: async () => {
                                    window.open("https://www.prntyr.com/calendarapp", '_blank');
                                    // Commented out the print functionality as it is now redirecting to https://www.prntyr.com?speak2-user=true
                                    // if (this.state.printBtnSpinner) {
                                    //     toast.warn("Task in progress, please wait until it's completed.", {
                                    //         position: 'bottom-center',
                                    //         autoClose: 5000,
                                    //         hideProgressBar: false,
                                    //         closeOnClick: true,
                                    //         pauseOnHover: true,
                                    //     })
                                    //     return
                                    // }
                                    // this.setState({ printBtnSpinner: true });
                                    // await jsonTocsvDownloader(this.state.activities, "Calendar", true);
                                    // this.setState({ printBtnSpinner: false })
                                }
                                },
                                addSvcActivity:{
                                    text: `+ Service Activity`,
                                    click: () => {
                                        this.handleSvcInsatanceModal()
                                    }, 
                                    hint: 'Create a service instance'
                                },
                                addActivity : { 
                                    text: `+ Simple Activity`,
                                    click: () => {
                                        this.openCloseCreateActivityForm()
                                    }, 
                                    hint: `Create a simple activity`,
                                }
                            }}
                            headerToolbar={{
                                right: this.isServiceEnabled ? 'addSvcActivity addActivity print export today prev,next' : 'addActivity print export today prev,next'

                            }}
                            plugins={[dayGridPlugin, interactionPlugin]}
                            initialView="dayGridMonth"
                            editable={false}
                            timeZone={'local'}
                            defaultTimedEventDuration={0} // without this, events with time after 11pm get extended to the next day as default value is 1hr
                            events={[
                                ...this.state.activities.map((event) => {
                                    const start = event.startDate ? event.startDate : event.timestamp;
                                    return event.audioId
                                        ? {
                                              title: event.name ? event.name.replace(/(<([^>]+)>)/gi, '') : '',
                                              start: start,
                                              id: event._id,
                                              classNames: [`category-act-${event.category || 'default'}`, 'audio'],
                                              backgroundColor: getColor(event.category),
                                          }
                                        : {
                                              title: event.name ? event.name.replace(/(<([^>]+)>)/gi, '') : '',
                                              start: start,
                                              id: event._id,
                                              classNames: [`category-act-${event.category || 'default'}`],
                                              backgroundColor: getColor(event.category),
                                          };
                                }),
                            ]}
                            initialDate={moment(this.state.initalCalendarDate).format('YYYY-MM-DD')}
                            datesSet={this.handleMonthChange.bind(this)}
                            dateClick={this.handleDateClick.bind(this)}
                            eventClick={this.handleEventClick.bind(this)}
                            eventContent={(info) => <CalendarEventContent info={info} />}
                            showNonCurrentDates={false}
                        />
                    </div>
                );
            },
        },
        {
            menuItem: 'List',
            render: () => (
                <Tab
                    activeIndex={this.state.activeTabIndex}
                    className="views-tab"
                    menu={{ secondary: true, pointing: true }}
                    panes={this.panes}
                    onTabChange={this.handleTabChange.bind(this)}
                />
            ),
        },
    ];

    panes = [
        {
            menuItem: 'Active',
            render: () => (
                <ListItemsPane
                    loading={this.state.isFetching}
                    listItemFilter={this.state.activityFilter}
                    setListItemDateFilter={this.setActivityDateFilter.bind(this)}
                    isSaving={this.state.isSaving}
                    isFetching={this.state.isFetching}
                    clearDateFilter={this.clearDateFilter.bind(this)}
                    listItems={this.state.activities}
                    getPaginatedListItem={this.getPaginatedActivity.bind(this)}
                    deleteListItem={this.deleteActivity.bind(this)}
                    editListItem={this.editActivity.bind(this)}
                    copyListItem={this.copyActivity.bind(this)}
                    cancelEditListItem={this.cancelEditActivity.bind(this)}
                    handleSubmit={this.handleSubmit.bind(this)}
                    handleUpdate={this.handleUpdate.bind(this)}
                    sourcePage="DailyActivities"
                    passAudioBlob={this.passAudioBlob.bind(this)}
                    facilityTimezone={this.state.timezone}
                    activeHistoryTab={this.state.activeTab}
                    initalCalendarDate={this.state.initalCalendarDate}
                    handleChangeActiveTab={this.handleChangeActiveTab.bind(this)}
                />
            ),
        },
        {
            menuItem: 'History',
            render: () => (
                <ListItemsPane
                    loading={this.state.isFetching}
                    listItemFilter={this.state.activityFilter}
                    setListItemDateFilter={this.setActivityDateFilter.bind(this)}
                    isSaving={this.state.isSaving}
                    isFetching={this.state.isFetching}
                    clearDateFilter={this.clearDateFilter.bind(this)}
                    listItems={this.state.activities}
                    getPaginatedListItem={this.getPaginatedActivity.bind(this)}
                    deleteListItem={this.deleteActivity.bind(this)}
                    editListItem={this.editActivity.bind(this)}
                    copyListItem={this.copyActivity.bind(this)}
                    cancelEditListItem={this.cancelEditActivity.bind(this)}
                    handleSubmit={this.handleSubmit.bind(this)}
                    handleUpdate={this.handleUpdate.bind(this)}
                    sourcePage="DailyActivities"
                    passAudioBlob={this.passAudioBlob.bind(this)}
                    facilityTimezone={this.state.timezone}
                    activeHistoryTab={this.state.activeTab}
                    initalCalendarDate={this.state.initalCalendarDate}
                    handleChangeActiveTab={this.handleChangeActiveTab.bind(this)}
                />
            ),
        },
    ]

    ActivityFormWrapperComponent = () => {
        const copyClickHandler = (selectedActivity, copyImageUrl: string | undefined) => {
            this.setState({
                showActivityDetail: false,
                copiedActivity: true,
                disableRecurrence: true,
                copyImageUrl
            });
            if (this.state.selectedActivity && this.state.selectedActivity._id && this.state.selectedActivityId) {
                document.getElementsByClassName('page-header')[0].scrollIntoView({ block: 'start', behavior: 'smooth' })
                if (selectedActivity) {
                    this.setState({
                        openCreateForm: true,
                        copyActivity: selectedActivity,
                        selectedActivityId:undefined 
                    })
                }
            }
        }
        if (this.state.selectedActivityId) {
            return (
                <ActivityForm
                    isFetching={this.state.isFetching}
                    isSaving={this.state.isSaving}
                    handleSubmit={this.handleSubmit.bind(this)}
                    handleUpdate={this.handleUpdate.bind(this)}
                    cancelEditActivity={this.cancelEditActivity.bind(this)}
                    copyActivity={this.state.copyActivity}
                    passAudioBlob={this.passAudioBlob.bind(this)}
                    audioError={this.state.audioError}
                    dateTimeString={this.state.selectedDateInCalendar}
                    edit={true}
                    editableActivity={this.state.selectedActivity}
                    _id={this.state.selectedActivityId}
                    activityDetailView={this.state.showActivityDetail}
                    selectedActivity={this.state.selectedActivity}
                    copyClickHandler={copyClickHandler}
                    timezone={this.state.timezone}
                    disableRecurrence={this.state.disableRecurrence}
                    modifyGroup={this.state.editRecurringActivities}
                    selectedCalendarType={this.state.selectedCalendarType}
                    handleLocationUpdate={this.handleLocationUpdate}
                    handleRedirect={this.handleRedirect.bind(this)}
                    handleDelete={this.handleDelete.bind(this)}
                />
            )
        } else {
            return (
                <ActivityForm
                    attendanceToggle={true}
                    isFetching={this.state.isFetching}
                    isSaving={this.state.isSaving}
                    handleSubmit={this.handleSubmit.bind(this)}
                    handleUpdate={this.handleUpdate.bind(this)}
                    cancelEditActivity={this.cancelEditActivity.bind(this)}
                    copyActivity={this.state.copyActivity}
                    passAudioBlob={this.passAudioBlob.bind(this)}
                    audioError={this.state.audioError}
                    dateTimeString={this.state.selectedDateInCalendar}
                    timezone={this.state.timezone}
                    disableRecurrence={this.state.disableRecurrence}
                    selectedCalendarType={this.state.selectedCalendarType}
                    handleLocationUpdate={this.handleLocationUpdate}
                    handleRedirect={this.handleRedirect.bind(this)}
                />
            )
        }
    }

    addNewAnnouncee(name) {
        let announcees: string[] = [];
        if (this.state.selectedAnnouncees) {
            announcees = [...this.state.selectedAnnouncees];
        } else {
            announcees = [];
        }
        if (!announcees.includes(name)) {
            announcees.push(name);
        }
        this.setState({ selectedAnnouncees: announcees });
    }

    removeAnnouncee(name) {
        if (this.state.selectedAnnouncees) {
            let announcees = [...this.state.selectedAnnouncees];
            const indexToRemove = announcees.indexOf(name);
            if (indexToRemove >= 0) {
                announcees.splice(indexToRemove, 1);
                this.setState({ selectedAnnouncees: announcees });
            }
        }
    }

    async handleDeleteRecurringActivity() {
        this.setState({
            isFetching: true,
            showRecurringDeleteConfirmModal: false,
        }, async () => {
            if (this.state.selectedActivity && this.state.selectedActivity.recurrence) {
                if (this.state.selectedActivity.srcId) {
                    const srcId = this.state.selectedActivity.srcId;
                    await this.deleteRecurringActivity({
                        id: this.state.selectedActivity._id,
                        srcId: srcId,
                        deleteGroup: true
                    });
                    this.setState({
                        showRecurringDeleteConfirmModal: false,
                        showDetailModal: false,
                        isLoading: false,
                        selectedActivity: undefined,
                        selectedActivityId: undefined,
                        isFetching: false,
                    });
                } else {
                    if(this.state.selectedActivity && this.state.selectedActivity._id){
                        await this.deleteActivity({
                            id: this.state.selectedActivity._id
                        });
                        this.setState({
                            showRecurringDeleteConfirmModal: false,
                            showDetailModal: false,
                            selectedActivity: undefined,
                            selectedActivityId: undefined,
                            isLoading: false,
                            isFetching: false 
                        });
                    }
                }
            } else {
                //// can you check why this is repeated -- the below condition is the same as the above else case
                if(this.state.selectedActivity && this.state.selectedActivity._id){
                    await this.deleteActivity({
                        id: this.state.selectedActivity._id
                    });
                    this.setState({
                            showRecurringDeleteConfirmModal: false,
                            showDetailModal: false,
                            selectedActivity: undefined,
                            selectedActivityId: undefined,
                            isLoading: false,
                            isFetching: false 
                    });
                }
            }
        });
    }

    async handleDelete(e){
        e.preventDefault();
        if (this.state.selectedActivity && this.state.selectedActivity.recurrence) {
            this.setState({
                showRecurringDeleteConfirmModal: true,
                showDetailModal: false,
            });
        } else {
            this.setState(
                {
                    isLoading: true,
                },
                async () => {
                    await this.deleteActivity({
                        id: this.state.selectedActivityId,
                    });
                },
            );
            this.setState({
                showDetailModal: false,
                isLoading: false,
            },()=> this.handleRedirect());
        }
    }
    
    async handleChange(value: string[]) {
        try {
            this.setState({
                loadDropdown: true,
                selectedCalendarType: value,
            });
            const staffUser = this.props.profile as unknown as User;
            const updatedStaff = {
                ...staffUser,
                selectedCalendarType: value || [],
                updateSelectedCalendarType: true
            };
            await updateUser(updatedStaff, staffUser);
            this.setState({
                activities: this.state.fetchedActivities
            });
        } catch (err) {
            console.log('error in handleChange', err);
            toast.error(err.message);
        } finally {
            this.setState({ loadDropdown: false });
        }
    }

    ActivityDetailComponent = () => {
        return (
            <>
                <div>
                    <Button
                        basic
                        style={{ marginLeft: '2em', marginTop: '1em', marginBottom: '1em' }}
                        icon="left chevron"
                        content="Back to all activities"
                        onClick={() => this.handleRedirect()}
                    />
                </div>
                <div>
                    <h1 style={{ display: 'inline', margin: '1em' }}>
                        {this.state.selectedActivity && this.state.selectedActivity.text}
                    </h1>
                    {/* <Button icon='edit' onClick={() => {
                            this.setState({openActivityDetailEditForm: true}) 
                        }}>
                    </Button> */}
                </div>
                {/*/// undo comment with both audio required */}
                {/* <div style={{padding: '1em'}}>
                    {this.state.selectedActivity && this.state.selectedActivity.playableUrl
                    ?   <audio controls src={this.state.selectedActivity &&     this.state.selectedActivity.playableUrl}>
                            Your browser does not support the
                            <code>audio</code> element.
                        </audio>
                    : ''
                    }
                </div> */}
                {/* <TransitionablePortal
                    open={this.state.openActivityDetailEditForm}
                    transition={{ animation: 'fade down', duration: 500 }}
                    closeOnDocumentClick={false}
                >
                    <Modal open={this.state.openActivityDetailEditForm} size="tiny" className="edit-item-form">
                        {this.ActivityFormWrapperComponent()}
                    </Modal>
                </TransitionablePortal> */}
                <Grid>
                    <Grid.Column width={8}>
                        <div className="edit-item-form">{this.ActivityFormWrapperComponent()}</div>
                    </Grid.Column>

                    <Grid.Column width={8}>
                        <div className="activity-notifications">
                            <div style={{ width: "35rem" }}>
                                <Segment raised padded style={{ background: "#F9F9F9" }} >
                                    <NotificationsFormContainer selectedAnnouncees={this.state.selectedAnnouncees} />
                                </Segment>
                            </div>
                        </div>
                        <Divider />
                        <>
                            <h3>Photos</h3>
                            {this.state.selectedActivity && <ImageUploader
                                activity={this.state.selectedActivity}
                                updateActivityImageFilenames={(newImageFilenames: string[]) => {
                                    const localSelectedActivity = JSON.parse(
                                        JSON.stringify(this.state.selectedActivity),
                                    );
                                    localSelectedActivity.imageFilenames = newImageFilenames;
                                    this.setState({ selectedActivity: localSelectedActivity });
                                }}
                            />}
                        </>
                    </Grid.Column>
                </Grid>
                <Divider />

                <Checkbox
                    toggle
                    onChange={async (e, data) => {
                        if (this.state.selectedActivity) {
                            try {
                                this.setState({
                                    isFetching: true
                                })
                                const { recurrence, dateTimeString, ...payload } = this.state.selectedActivity;
                                const startDate =
                                    recurrence && this.state.editRecurringActivities
                                        ? moment(
                                              recurrence
                                                  .split('\n')[0]
                                                  .split('DTSTART=')[1]
                                                  .replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'),
                                          )
                                              .set({
                                                  hour: moment(dateTimeString).format('HH'),
                                                  minute: moment(dateTimeString).format('mm'),
                                                  second: moment(dateTimeString).format('ss'),
                                              })
                                              .format('YYYY-MM-DDTHH:mm:ss')
                                        : dateTimeString;
                                await updateActivity({
                                    _id: payload._id,
                                    showAttendance: data.checked,
                                });
                                this.setState({
                                    selectedActivity: {
                                        ...this.state.selectedActivity,
                                        showAttendance: data.checked,
                                    }
                                });
                            } catch (error) {
                                toast.error(error.message || 'Could not update activity', {
                                    position: 'bottom-center',
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                }); // discuss better way to show error
                                this.setState({
                                    openCreateForm: false,
                                    showActivityDetail: false,
                                    audioBlob: undefined,
                                    error: error.message,
                                });
                            } finally {
                                if (data.checked) {
                                    await this.handleEventActivityEdit(null);
                                } else {
                                    this.setState({
                                        isFetching: false,
                                        isSaving: false
                                    });
                                }
                            }
                        }
                    }}
                    checked={this.state.selectedActivity && this.state.selectedActivity.showAttendance}
                    label={`Attendance turned ${
                        this.state.selectedActivity && this.state.selectedActivity.showAttendance ? 'on' : 'off'
                    }`}
                />

                <Divider />
                <Dimmer.Dimmable as={'div'} dimmed={moment(this.state.selectedActivity && this.state.selectedActivity.dateTimeString).startOf("day").isBefore()}>
                    {this.state.selectedActivity && this.state.kanbanBoard ? (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div style={{ width: '100%', overflow: 'auto' }}>
                                <h3>Manage Attendance</h3>
                                <KanbanBoards
                                    board={this.state.kanbanBoard}
                                    facilityId={this.state.selectedActivity && this.state.selectedActivity.Facility}
                                    activityId={this.state.selectedActivity && this.state.selectedActivity._id}
                                    addNewAnnouncee={(name) => {
                                        this.addNewAnnouncee(name);
                                    }}
                                    removeAnnouncee={(name) => {
                                        this.removeAnnouncee(name);
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </Dimmer.Dimmable>
            </>
        );
    };

    ActivityMainComponent = () => {
        return (
            <>
                {this.state.error ? <h3 style={{color: 'red'}}>{this.state.error}</h3> : null}
                <div style={{ display: "flex", gap: "10px", alignItems: "flex-end" }}>
                    <Form.Field>
                        <Dropdown
                            loading={this.state.loadDropdown}
                            selection={true}
                            value={this.state.selectedCalendarType}
                            options={sortByKey(this.state.locationOptions)}
                            placeholder="Select Calendar"
                            style={{ width: "100%" }}
                            onChange={(e, { value }) => {
                                this.handleChange(value as []);
                            }}
                            multiple
                        />
                    </Form.Field>
                </div>

                <TransitionablePortal
                    open={this.state.openCreateForm}
                    transition={{ animation: 'fade down', duration: 500 }}
                    closeOnDocumentClick={false}
                >
                    <Modal open={this.state.openCreateForm} size="tiny" onClose={() => this.setState({ openCreateForm: false })} className="edit-item-form">
                        {this.ActivityFormWrapperComponent()}
                    </Modal>
                </TransitionablePortal>
                <Tab
                    activeIndex={this.state.calendarTabIndex}
                    className="views-tab"
                    menu={{ secondary: true, pointing: true }}
                    panes={this.panes0}
                    onTabChange={this.handleMainTabChange.bind(this)}
                />

                {(this.state.calendarTabIndex !== 0 && this.state.calendarTabIndex !== 1) && this.state.calendarTabIndex && this.state.activities.length > this.paginationPerPageNumber ? (
                    <div className="pagination-holder">
                        <Pagination
                            activePage={this.state.activePageNumber}
                            totalPages={Math.ceil(this.state.activities.length / this.paginationPerPageNumber)}
                            onPageChange={this.changePage.bind(this)}
                            siblingRange={1}
                        />
                    </div>
                ) : null}
            </>
        );
    };

    render() {
        return (
            <div className="DailyActivities">
                <Dimmer active={this.state.isFetching || this.state.componentMounting} inverted>
                    <Loader active={this.state.isFetching || this.state.componentMounting} />
                </Dimmer>
                <Modal open={this.state.showRecurringDeleteConfirmModal}>
                    <Modal.Header>Delete recurring event?</Modal.Header>
                    <Modal.Actions>
                        <Button
                            loading={this.state.isLoading}
                            basic
                            color="blue"
                            onClick={async (e) => {
                                e.preventDefault();
                                this.setState(
                                    {
                                        showRecurringDeleteConfirmModal: false,
                                        isFetching: true,
                                    },
                                    async () => {
                                        await this.deleteActivity({
                                            id: this.state.selectedActivityId,
                                            deleteGroup: false,
                                        });
                                        this.handleRedirect()
                                        this.setState({
                                            isFetching: false,
                                        });
                                    },
                                );
                            }}
                        >
                            This event
                        </Button>
                        <Button
                            loading={this.state.isLoading}
                            basic
                            color="red"
                            onClick={async (e) => {
                                e.preventDefault();
                                await this.handleDeleteRecurringActivity();
                                this.handleRedirect()
                            }}
                        >
                            All events
                        </Button>
                        <Button
                            basic
                            color="grey"
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                    showRecurringDeleteConfirmModal: false,
                                    showDetailModal: true,
                                });
                            }}
                        >
                            Cancel
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Modal open={this.state.showRecurringEditConfirmModal}>
                    <Modal.Header>Edit recurring event?</Modal.Header>
                    <Modal.Actions>
                        <Button
                            loading={this.state.isLoading}
                            basic
                            color="blue"
                            onClick={async (e) => {
                                e.preventDefault();
                                await this.handleEventActivityEdit(null);
                                this.setState({
                                    showRecurringEditConfirmModal: false,
                                },()=>{
                                    this.handleRedirect(this.state.selectedActivityId) 
                                });
                            }}
                        >
                            This event
                        </Button>
                        <Button
                            loading={this.state.isLoading}
                            basic
                            color="red"
                            onClick={async (e) => {
                                e.preventDefault();
                                await this.handleEventActivityEdit(null);
                                this.setState({
                                    editRecurringActivities: true,
                                    showRecurringEditConfirmModal: false,
                                },()=>{
                                    this.handleRedirect(this.state.selectedActivityId) 
                                });
                            }}
                        >
                            All events
                        </Button>
                        <Button
                            basic
                            color="grey"
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                    showRecurringEditConfirmModal: false,
                                    selectedActivity: undefined,
                                    selectedActivityId: undefined,
                                });
                            }}
                        >
                            Cancel
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Modal
                    style={{ width: "450px" }}
                    dimmer={true}
                    open={this.state.showSvcInstanceModal}
                    onClose={() => this.closeSvcInstanceModal()}
                >
                    <Modal.Content>
                        <ServiceInstanceFormCreate instanceData={this.state.svcInstanceData} setInstanceData={this.setSvcInstanceData.bind(this)}
                            closeModal={this.closeSvcInstanceModal.bind(this)} reloadServices={this.reloadChildComponents.bind(this)} handleCalendarReload={this.reloadActivities.bind(this)} source={"DailyActivities"} />
                    </Modal.Content>
                </Modal>
                {this.state.showActivityDetail ? this.ActivityDetailComponent() : this.ActivityMainComponent()}
            </div>
        );
    }
}

export default withRouter(DailyActivities);
