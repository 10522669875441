import React, { FC, useEffect, useRef, useState } from 'react'
import { Button, Dimmer, Loader } from 'semantic-ui-react'
import * as Promise from 'bluebird'
import { CSVLink } from 'react-csv'
import { Registrant,  UserProfile } from '../../../../types'
import { fetchAllPaginatedResidents, fetchPaginatedRegistrantA4HContacts } from '../../../../services/Registrants'
import { connect } from 'react-redux'
import { getSmartGadget } from '../../../../services/SmartGadgets'

interface Props {
    getAllSmartGadgets: () => Promise
    profile:UserProfile;
}

const ExportRegistrantButton: FC<Props> = (props) => {
    const [exportData, setExportData] = useState([])

    const [exportDataLoading, setExportDataLoading] = useState(false)

    const excelRef = useRef()

    useEffect(() => {
        if (exportData.length > 0) {
            // WIP see how you can avoid this issue instead of using ts-ignore
            // @ts-ignore
           excelRef && excelRef.current && excelRef.current.link && excelRef.current.link.click()
        }
    }, [exportData])
    
   const  getAllSmartGadgets = async (registrants:Registrant[]) => {
        const residentsWithGadgetInfo = await Promise.map(registrants, async (e) => {
            const gadgetNames = await getSmartGadget(e.Unit && e.Unit.a4hRoomId)
            e.gadgetNames = gadgetNames
            return e
        }, {
            concurrency: 5
        })
        return residentsWithGadgetInfo
    }

    const handleExport = async (registrants) => {
        setExportDataLoading(true)
        // const gadgetsData = await props.getAllSmartGadgets()
        let arrObj = await Promise.map(registrants, async (e) => {
            const data = await fetchPaginatedRegistrantA4HContacts(e._id)
            
            return {
                'Resident Name': `${e.FirstName} ${e.LastName}`,
                'Resident Room': (e.Unit && e.Unit.Name) || 'NA',
                'Alexa DSNs': e.gadgetNames
                    ? e.gadgetNames.map((e) => {
                          const number = e.serialNumber
                          return number.toString()
                      })
                    : 'NA',
                Contacts: data[0] ?  data
                    .map((e) => {
                        const str = `${e.RelativeName}: ${e.Contact}`
                        if (e.Contact) {
                            return str
                        } else {
                            return 'NA'
                        }
                    })
                    .join('\n') : "NA",
            }
        }, {
            concurrency: 5
        })
        setExportDataLoading(false)
        setExportData(arrObj)
    }
    const exportButtonClickHandler = async () => {
        setExportDataLoading(true)
        const res = await fetchAllPaginatedResidents()
        const gadgetsData = await getAllSmartGadgets(res)
        handleExport(res)
    }

    return (
        <>
            <Dimmer active={exportDataLoading} inverted>
                <Loader active={exportDataLoading} />
            </Dimmer>
            <Button content={exportDataLoading ? 'Loading CSV...' : 'Export'} basic onClick={exportButtonClickHandler} />
            <CSVLink
                data={exportData}
                asyncOnClick={true}
                filename="Residents.csv"
                ref={excelRef}
                style={{
                    display: 'none',
                }}
            />
        </>
    )
}

function mapStateToProps({ authReducer }) {
    return {
        currentUser: authReducer.currentUser,
        profile: authReducer.profile,
    }
}

export default connect(mapStateToProps)(ExportRegistrantButton)
