import React, { useEffect, useState } from 'react'
import { Button, Dimmer, Grid, Header, Icon, Item, Label, Loader, Modal, Popup } from 'semantic-ui-react';
import { distanceInWordsToNow } from 'date-fns'
import './style.less'
import moment from 'moment';
import IconList from '../../pages/AdminPanel/Requests/CategoryIconList/IconList';
import DatePicker from 'react-datepicker';
import { updateRequestInstance } from '../../services/RequestInstances';
import { toast } from 'react-toastify';
import { acceptRequest } from '../../services/Requests';
import { useSelector } from 'react-redux';
import { AuthState, OrderIdColorDict } from '../../types';
import { formatDateWithTZ, getDurationInMs, getFormattedEndDateString } from '../../util/timezone';
import momentTZLib from 'moment-timezone';
import { Service } from '../../types/Service';
import { fetchOneServiceType } from '../../services/service';

interface Props {
    id: string
    residentName: string
    requestTime: number |  string
    requestName: string
    room: string
    escalatedIndicator: boolean
    avatarUrl?: string
    category: string
    onClick: () => any
    request : any 
    refresh : ()=>void
    powerAndFacilityUsers?: boolean;
    orderId?: string
    orderIdColorDict : OrderIdColorDict
}

const RequestItem: React.FC<Props> = props => {
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [datePickerModal, setDatePickerModal] = useState<boolean>(false)
    const [modalLoader , setmodalLoader] = useState<boolean>(false)
    const profile = useSelector(({ authReducer }: { authReducer: AuthState }) => {
        return authReducer.profile;
    });
    const [duration, setDuration] = useState<number | undefined>(undefined);
    const facilityTZ = profile && profile.FacilityTimeZone || "";

    useEffect(() => {
        if (props.request.ScheduledTime) {
            setStartDate(props.request.ScheduledTime.startDate || moment().format('DD-MM-YYYY'));
            setEndDate(props.request.ScheduledTime.endDate || moment().format('DD-MM-YYYY'));
        }
    }, [datePickerModal]);

    useEffect(() => {
        if (startDate && duration !== undefined) {
            const updatedEndDate = getFormattedEndDateString(startDate, duration, 'YYYY-MM-DDTHH:mm:ss');
            setEndDate(updatedEndDate);
        }
    }, [startDate]);

    useEffect(() => {
        if (endDate) { // here there is no check for start date, as it will be always there when end date is there
            const updateDuration = getDurationInMs(startDate, endDate);
            setDuration(updateDuration);
        }
    }, [endDate]);

    const handleCalendarClick = (e) => {
        e.stopPropagation();
        setDatePickerModal(true);
    };
    const handleCloseModal = () => {
        setStartDate('');
        setEndDate('');
        setDatePickerModal(false);
    };
    const handleSubmit = async (accept: boolean = false) => {
        try {
            setmodalLoader(true);
            if(startDate >= endDate){
                throw new Error("Start time should not be greator than end time")
            }
            if (startDate === "Invalid date" || endDate === "Invalid date") {
                toast.warn('Please select a valid start/end date', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                return;
            }

            const ScheduledTime = {
                startDate,
                endDate
            };
            const requestInstance = {
                _id: props.id,
                ScheduledTime,
            };
            const res = await updateRequestInstance(requestInstance);
            if (!res.updated) { // backend will return updated as false with a message as to why updated is false
                return toast.warn(res.message ? res.message : 'Something went wrong while updating request', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
            if (accept) {
                await acceptRequest(props.id);
            }
            toast.success("Successfully scheduled the request", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            handleCloseModal();
            props.refresh();
        } catch (err) {
            console.log(err);
            toast.error(err instanceof Error ? err.message : "Failed to update Schedule", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setmodalLoader(false);
        }
    };

    const renderRequestStatus = (req) => {
        if (req.Status === 'Open') {
            const currentFacilityTime = momentTZLib.tz(new Date(), facilityTZ);
            if (momentTZLib.tz(req.RequestedTime, facilityTZ).isBefore(currentFacilityTime)) {
                return 'Open - Past Due';
            } else {
                return req.Status;
            }
        } else if (req.ClosedByResident) { // if closed by resident we show it as canceled
            return 'Canceled';
        } else {
            return req.Status;
        }
    };

    const renderRequestLabelColor = (req) => {
        if (req.Status === 'Open') {
            const currentFacilityTime = momentTZLib.tz(new Date(), facilityTZ);
            if (momentTZLib.tz(req.RequestedTime, facilityTZ).isBefore(currentFacilityTime)) {
                return 'red';
            } else {
                return 'orange';
            }
        } else if (req.Status === 'Accepted') {
            return 'green';
        } else if (req.ClosedByResident) { // if closed by resident we show its status in purple color
            return 'purple';
        } else {
            return 'grey';
        }
    }

    return (
        <div className='RequestItem-main'>
        <div className='RequestItem-container'>
            <Grid columns={1} className="RequestItem very specific class" onClick={props.onClick}>
                <Grid.Row>
                    {props.request.requestCount && (
                        <Label
                            circular
                            color="blue"
                            style={{
                                zIndex: 1,
                                marginTop: '-20px',
                                marginLeft: 'auto'
                            }}
                        >
                            {props.request.requestCount}
                        </Label>
                    )}
                    <Grid.Column>
                        <Label as='a' color={renderRequestLabelColor(props.request)} tag style={{ float: 'right' }}>
                            {renderRequestStatus(props.request)}
                        </Label>
                        <p style={{fontWeight: "bold", fontSize: "12px"}}>
                            {props.id}
                        </p>
                            {
                                props.orderId &&
                                <p style={{ backgroundColor: props.orderIdColorDict[props.orderId] ? props.orderIdColorDict[props.orderId] : "none" }} className='order-id-cont' >
                                    Order ID: {props.orderId.slice(props.orderId.length - 4)}
                                </p>
                            }
                        <Item className='header' as={Header}>{props.residentName} - {props.request.Category ? props.request.Category : props.request.Department_Name}</Item>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                        <Grid.Column>
                            <div style={{ marginTop: '5px' }}>
                                <Grid >
                                    <Grid.Column width={3} style={{ paddingRight: "0px" }}>
                                        {formatDateWithTZ(props.requestTime, 'MM-DD-YYYY', facilityTZ)}
                                    </Grid.Column>
                                    <Grid.Column width={3}>
                                        {formatDateWithTZ(props.requestTime, 'hh:mm A', facilityTZ)}
                                    </Grid.Column>
                                    <Grid.Column width={6}>
                                        <span
                                            style={{
                                                color: ((props.request.OriginalServiceId || props.request.OriginalServiceInstanceId) && !props.request.Service)
                                                    ? "red"
                                                    : "black"
                                            }}
                                        >
                                            {
                                                (props.request.OriginalServiceId || props.request.OriginalServiceInstanceId) && !props.request.Service
                                                    ? 'Service Deleted'
                                                    : props.request.Service && props.request.Service.name
                                            }
                                        </span>
                                        <span style={{ color: 'red' }}>{props.escalatedIndicator ? '*' : ''}</span>
                                    </Grid.Column>
                                    <Grid.Column width={2}></Grid.Column>
                                    <Grid.Column width={2}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <IconList name={props.category || "Other"} size='small' />
                                            <Popup
                                                content="Schedule"
                                                trigger={
                                                    <div className={props.request.OrderName ? `calender-schedule` : ``} onClick={(e) => props.request.OrderName ? handleCalendarClick(e) : null} style={{ display: "flex", justifyContent: "center" }}>
                                                        <Icon style={{fontSize: "24px", marginLeft: '5px' }} name="calendar alternate outline" size="small" disabled={props.request.OrderName ? false : true} />
                                                    </div>
                                                }
                                                // style={{ marginLeft: '10px' }}
                                                size="tiny"
                                                on="hover"
                                                disabled={props.request.OrderName ? false : true}
                                                position="top right"
                                            />
                                        </div>
                                    </Grid.Column>
                                </Grid>  
                            </div>
                        </Grid.Column>
                </Grid.Row>
            </Grid>
            <Modal
                onClose={() => setDatePickerModal(false)}
                open={datePickerModal}
            >
                <Dimmer
                    active={modalLoader}
                    inverted
                >
                    <Loader
                        active={modalLoader}
                    />
                </Dimmer>
                <Modal.Header>Schedule the request</Modal.Header>
                <Modal.Content >
                    <Modal.Description>
                        <Grid columns={2} centered>
                            <Grid.Column>
                                <label>Start Date</label>
                                <DatePicker
                                    customInput={
                                        <div
                                            style={{
                                                position: 'relative',
                                                boxShadow: '0px 1px 2px 0 rgba(34, 36, 38, 0.15)',

                                            }}
                                        >
                                            <Icon
                                                name="calendar alternate outline"
                                                style={{
                                                    position: 'absolute',
                                                    top: '45%',
                                                    left: '10px',
                                                    transform: 'translateY(-50%)',
                                                }}
                                            />
                                            <input
                                                style={{ padding: "10px", paddingLeft: '30px' }}
                                                placeholder="Schedule Start *"
                                                required={true}
                                                value={startDate ? moment(startDate).format('M.D.Y h:mm A') : ''}
                                            />
                                        </div>
                                    }
                                    dateFormat="M.d.Y h:mm aa"
                                    minDate={new Date()} // Set the minimum to today
                                    showTimeSelect
                                    timeIntervals={15}
                                    selected={startDate ? Date.parse(startDate || new Date().toISOString()) : null}
                                    onChange={(date: Date) => {
                                        if(!date) return;
                                        const dateTime = moment(date).format('YYYY-MM-DDTHH:mm:ss');
                                        setStartDate(dateTime);
                                        if (!endDate) {
                                            setEndDate(moment(dateTime).add(15, 'minutes').format('YYYY-MM-DDTHH:mm:ss'));
                                        }
                                    }}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <div>
                                    <label>End Date</label>
                                    <DatePicker
                                        customInput={
                                            <div
                                                style={{
                                                    position: 'relative',
                                                    boxShadow: '0px 1px 2px 0 rgba(34, 36, 38, 0.15)',
                                                }}
                                            >
                                                <Icon
                                                    name="calendar alternate outline"
                                                    style={{
                                                        position: 'absolute',
                                                        top: '45%',
                                                        left: '10px',
                                                        transform: 'translateY(-50%)',
                                                    }}
                                                />
                                                <input
                                                    style={{ padding: "10px", paddingLeft: '30px' }}
                                                    placeholder="Schedule End*"
                                                    required={true}
                                                    disabled={!startDate}
                                                    value={endDate ? moment(endDate).format('M.D.Y h:mm A') : ''}
                                                />
                                            </div>
                                        }
                                        minDate={startDate ? new Date(startDate) : new Date()} // Set the minimum to today or start date
                                        minTime={
                                            startDate ? new Date(moment(startDate).add(15, 'minutes').valueOf()) : null
                                        } // Set the minTime her
                                        maxTime={ // this is required when minTime is passed
                                            startDate
                                                ? new Date(startDate).setHours(23, 59, 59) // Set the maxTime to end of the day
                                                : null
                                        }
                                        dateFormat="M.d.Y h:mm aa"
                                        showTimeSelect
                                        disabled={!startDate}
                                        timeIntervals={15}
                                        selected={endDate ? Date.parse(endDate || new Date().toISOString()) : null}
                                        onChange={(date: Date) => {
                                            if (!date) return;
                                            const dateTime = moment(date).format('YYYY-MM-DDTHH:mm:ss');
                                            setEndDate(dateTime);
                                        }}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => handleCloseModal()}>
                        Cancel
                    </Button>
                    {props.powerAndFacilityUsers && <Button color={'blue'} onClick={() => handleSubmit(false)}>
                        {props.request.ScheduledTime ? "Reschedule" : "Schedule"}
                    </Button>}
                    {!props.request.StaffId && !props.powerAndFacilityUsers && <Button color={'blue'} onClick={() => handleSubmit(true)}>
                        {props.request.ScheduledTime ? "Reschedule & Accept" : "Schedule & Accept"}
                    </Button>}
                </Modal.Actions>
            </Modal>
        </div>
        </div>
    )
}

export default RequestItem
