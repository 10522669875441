import React, { useState, useEffect } from 'react'
import { Form, Dropdown, Message, List, Button, Loader, Dimmer } from 'semantic-ui-react'
import { assertWifi, getWifiPairingStatus, getEndpoints } from '../../../../services/A4hEndpoints'
import { Promise } from 'bluebird'
import { fetchResidentRooms } from '../../../../services/Facilities'
import { sortByKey } from '../../../../util/sortData';
import { sendToast } from '../../../../util'

const WifiStatusItem = props => {
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState('')

    const refreshWifiStatus = async (endpointId, operationId) => {
        setLoading(true)
        const res = await getWifiPairingStatus(endpointId, operationId)
        if (res && res.status)
            setStatus(res.status)
        setLoading(false)
    }

    return (
        <List.Item>
            {props.resident} - {status.replace('_', ' ') || props.status.replace('_', ' ')}
            <Button
                basic
                circular
                size='mini'
                loading={loading}
                icon='refresh'
                style={{ marginLeft: '5px' }}
                onClick={() => refreshWifiStatus(props.endpointId, props.operationId)}
            />
        </List.Item>
    )
}

const WifiAssertion = () => {
    const [rooms, setRooms] = useState([])
    const [options, setOptions] = useState([])
    const [selectedOptions, setSelectedOptions] = useState<any>([])
    const [submitting, setSubmitting] = useState<boolean>(false)
    const [wifiName, setWifiName] = useState('')
    const [psk, setPsk] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const [listItems, setListItems] = useState([])
    const [fetching, setFetching] = useState(true)
    const [progress, setProgress] = useState(0)

    const fetchAndSetDropdownOptions = async () => {
        setFetching(true)
        const facilityRooms = await fetchResidentRooms()
        setProgress(0);
        const rooms = await Promise.map(
            facilityRooms,
            async (facilityRoom, index) => {
                console.log('index', index)
                if (index > 10) {
                    setProgress(Math.round(100 - (index / facilityRooms.length) * 100))
                }
                try {
                    const endpoints = await getEndpoints(facilityRoom.RoomId)
                    if (!endpoints || !endpoints.length) return null
                    return {
                        ...facilityRoom,
                        EndpointIds: endpoints
                    }
                } catch (error) {
                    return null
                }
            },
            {
                concurrency: 10
            }
        )
        const filteredRooms = rooms.filter(r => r)
        setRooms(filteredRooms)
        const formattedRooms = filteredRooms.map(room => {
            return {
                text: room.Resident,
                key: room.RoomId,
                value: JSON.stringify(room.EndpointIds)
            }
        })
        setOptions(formattedRooms)
        setFetching(false)
    }

    const clearForm = () => {
        setWifiName('')
        setPsk('')
        setSelectedOptions([])
    }

    const handleSubmit = async (ssid, psk = '', stringifiedResidentEndpoints) => {
        if (psk && psk.length < 8) {
            setErrMsg('Password must be at least 8 characters long')
            return
        }
        const parsedResidentEndpoints = stringifiedResidentEndpoints.map(e => JSON.parse(e))
        const endpointIds = parsedResidentEndpoints.flat()
        if (!ssid || !endpointIds.length) {
            setErrMsg('Form incomplete')
            return
        }
        setErrMsg('')
        setSubmitting(true)
        const res = await Promise.map(
            endpointIds,
            async endpointId => {
                try {
                    return await assertWifi(endpointId, ssid, psk)
                } catch (e) {
                    console.error(e.Error)
                    sendToast("error", "Failed to assert wifi. Please check if device is online and reachable.")
                }
            },
            {
                concurrency: 5
            }
        ).filter(e => e)
        setListItems(res)
        clearForm()
        setSubmitting(false)
    }

    useEffect(() => {
        fetchAndSetDropdownOptions()
    }, [])

    const getResident = (endpointId, allRooms) => {
        const { Resident } = allRooms.find(r => r.EndpointIds.includes(endpointId))
        return Resident
    }

    const listWifiResponse = (items) => {
        return (
            <List divided relaxed>
                {items.map(item => (
                    <WifiStatusItem
                        key={item.operationId}
                        endpointId={item.endpointId}
                        operationId={item.operationId}
                        status={item.status}
                        resident={getResident(item.endpointId, rooms)}
                    />
                ))}
            </List>
        )
    }

    return <>
        <Dimmer active={fetching} inverted>
            <Loader active={fetching} > {progress}% </Loader>
        </Dimmer>
        <Form widths='equal'>
            <Form.Input label='WiFi name' required value={wifiName} onChange={(e, { value }) => setWifiName(value)} />
            <Form.Input label='WiFi password' value={psk} onChange={(e, { value }) => setPsk(value)} />
            <Form.Field required>
                <label>Resident rooms</label>
                <Dropdown
                    placeholder='Select rooms'
                    closeOnEscape 
                    value={selectedOptions}
                    multiple
                    clearable
                    search
                    scrolling
                    selection
                    options={sortByKey(options)}
                    onChange={(e, { value }) => setSelectedOptions(value)}
                />
            </Form.Field>
            <Form.Button basic color='blue' loading={submitting} onClick={() => handleSubmit(wifiName, psk, selectedOptions)}>
                Assert Wifi
            </Form.Button>
        </Form>
        {errMsg && <Message negative>{errMsg}</Message>}

        {listItems.length ? <>
            <h2>Status</h2>
            {listWifiResponse(listItems)}
        </>
            :
            <></>
        }
    </>
}

export default WifiAssertion
