import API from './API'

export async function fetchRoomGroups(): Promise<any> {
    return await API.lambdaGet(`/room-groups/list`);
}

export async function fetchResidentGroups(): Promise<any> {
    return await API.lambdaPost(`/room-groups/list`, { includeRealTimeResidentIds: true });
}

export async function createRoomGroup(Name :string, RoomIds :string[]): Promise<any> {
    return await API.lambdaPost('/room-groups/create', {
        Name,
        RoomIds
    });
}

export async function deleteRoomGroup(_id :string): Promise<any> {
    return await API.lambdaDeleteById('/room-groups/delete', {_id});
}
