import React, { FC, useEffect, useRef, useState } from 'react'
import { Dimmer, Divider, Header, Icon, Image, List, Loader, Message, Modal } from 'semantic-ui-react'
import { useAdminPanelFeedItems as useFeedItems } from '../../hooks/feedItems'
import { AuthState, FeedItem} from '../../types'
import LoadMore from '../LoadMore'
import FilterPrintExportIcons from "../FilterPrintExportIcons/index"
import moment from "moment-timezone"
import ResidentDetailView from './ResidentDetailView'
import DatePicker from 'react-datepicker'
import "./../../../node_modules/react-datepicker/dist/react-datepicker.css";
import { formatAndExportDataForPrint } from '../../util/jsonTocsvDownloader'
import { fetchOneFacility } from '../../services/Facilities'
import { useSelector } from 'react-redux'
import StaffFeedDetailView from './StaffFeedDetailView'
import RelativeFeedDetailView from './RelativeFeedDetailView'

interface RequestsWithFilterProps {
    residentId?: string
    residentName?: string
}

interface RequestsFilterProps {
    feedItems: FeedItem[]
    filterChangeHandler: (filterItems: string[]) => void
    setFeedFilters: Function
    residentName? : string
    facilityTimezone: string
    facilityName: string
    fetchRelavantFeedContent: (feed: FeedItem, render: boolean, facilityTimezone: string, requestResidentClickHandler: (feed: FeedItem) => void) => string | JSX.Element | null
}

interface RequestListProps {
    feedItems: FeedItem[]
    loading: boolean
    setPageNo: React.Dispatch<React.SetStateAction<number>>
    feedHasMorePages: boolean
    feedIsPaginating: boolean
    facilityTimezone: string
}

interface DateRangeFilter {
    fromDate?: Date;
    toDate?: Date;
}

const BlueClickableText = ({ text, onClick = () => {} }) => {
    return (
        <span 
            style={{ color: "#1991EB", fontWeight: "bold", cursor: "pointer" }} 
            onClick={onClick}>
            {text}
        </span>
    );
};

const fetchRelavantFeedContent = (feed: FeedItem, render: boolean = false, facilityTimezone: string = "America/New_York", highlightedPersonResidentClickHandler = (feed: FeedItem, personOfFocus = "resident") => {}) => {
    const formatDateTime = (utcTimestamp, outputDateOrTime?: string) => {
        if (outputDateOrTime === "time") return moment.tz(utcTimestamp, facilityTimezone).format('hh:mm A');
        else if (outputDateOrTime === "date") return moment.tz(utcTimestamp, facilityTimezone).format('MM/DD/YYYY');
        else
            return moment.tz(utcTimestamp, facilityTimezone).format('MM/DD/YYYY hh:mm A');
    };
    if (feed && feed.Category){
        switch (feed.Category) {
            case "DeviceStatusMonitor":
                if (render) {
                    return (
                        <>
                            <Image avatar src={feed.RegistrantImage ? feed.RegistrantImage : `${process.env.PUBLIC_URL}/avatar_placeholder.svg`} />
                            <List.Content verticalAlign='middle'>
                                <List.Header>
                                    Room <BlueClickableText text={`${feed.Unit_Name}`} /> {feed.DeviceName} {`(${feed.DeviceDSN})`} device went <span style={{ fontWeight: "bolder" }}>{feed.DeviceStatus && feed.DeviceStatus.toLowerCase()}</span> at {formatDateTime(feed.DateAdded, "time")} on {formatDateTime(feed.DateAdded, "date")}
                                </List.Header>
                            </List.Content>
                        </>
                    );
                } else {
                    return `Room ${feed.Unit_Name} ${feed.DeviceName} (${feed.DeviceDSN}) device went ${feed.DeviceStatus && feed.DeviceStatus.toLowerCase()} at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`;
                }
            case "WebRTC":
                const receiver = feed.ReceiverId && feed.ReceiverId.includes("amzn1.alexa.unit.did") ? feed.Registrant_FullName : feed.Staff_FullName || "N/A"
                const sender = feed.SenderId && feed.SenderId.includes("amzn1.alexa.unit.did") ? feed.Registrant_FullName : feed.Staff_FullName || "N/A"
                const senderIsStaff = feed.SenderId && feed.SenderId.includes("amzn1.alexa.unit.did") ? false : true
                const receiverIsStaff = feed.ReceiverId && feed.ReceiverId.includes("amzn1.alexa.unit.did") ? false : true;
                if (render) {
                    return (
                        <>
                            <Image avatar src={feed.RegistrantImage ? feed.RegistrantImage : `${process.env.PUBLIC_URL}/avatar_placeholder.svg`} />
                            <List.Content verticalAlign='middle'>
                                <List.Header>
                                    {senderIsStaff ? `Staff `: ``}<BlueClickableText text={sender} onClick={
                                        () => {
                                            if (senderIsStaff) highlightedPersonResidentClickHandler(feed, "staff")
                                            else highlightedPersonResidentClickHandler(feed)
                                        }
                                    }/> has placed a Speak2 call to <BlueClickableText text={receiver} onClick={
                                        () => {
                                            if (receiverIsStaff) highlightedPersonResidentClickHandler(feed, "staff")
                                            else highlightedPersonResidentClickHandler(feed)
                                        }
                                    }/> at {formatDateTime(feed.DateAdded, "time")} on {formatDateTime(feed.DateAdded, "date")}
                                </List.Header>
                            </List.Content>
                        </>
                    );
                } else {
                    return `${sender} has placed a Speak2 call to ${receiver} at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`;
                }
            case "Request":
                if (render) {
                    switch (feed.RequestType) {
                        case "Technology Technology":
                        case "Security Security":
                        case "Concierge Concierge":
                        case "Wellness Wellness":
                        case "Spa Spa":
                        case "Fitness Fitness":
                            return (
                                <>
                                    <Image avatar src={feed.RegistrantImage ? feed.RegistrantImage : `${process.env.PUBLIC_URL}/avatar_placeholder.svg`} />
                                    <List.Content verticalAlign='middle'>
                                        <List.Header>
                                            <BlueClickableText text={`${feed.Registrant_FullName}`} onClick={() => highlightedPersonResidentClickHandler(feed)} /> in apartment {feed.Unit_Name} is requesting {feed.RequestType.split(' ')[0]} help at {formatDateTime(feed.DateAdded, "time")}{feed.Details && `. Details: ${feed.Details.split('description:')[1].split(',')[0]}`} on {formatDateTime(feed.DateAdded, "date")}
                                        </List.Header>
                                    </List.Content>
                                </>
                            );
                        case 'Creative Arts':
                            return (
                                <>
                                    <Image avatar src={feed.RegistrantImage ? feed.RegistrantImage : `${process.env.PUBLIC_URL}/avatar_placeholder.svg`} />
                                    <List.Content verticalAlign='middle'>
                                        <List.Header>
                                            <BlueClickableText text={`${feed.Registrant_FullName}`} onClick={() => highlightedPersonResidentClickHandler(feed)} /> in apartment {feed.Unit_Name} is requesting {feed.RequestType} help at {formatDateTime(feed.DateAdded, "time")}{feed.Details && `. Details: ${feed.Details.split('description:')[1].split(',')[0]}`} on {formatDateTime(feed.DateAdded, "date")}
                                        </List.Header>
                                    </List.Content>
                                </>
                            );
                        // case 'Kitchen Dietary': // Ijaz has to add a nre slot for this utterance so that it is differentiated with other meal requests and this will requireskill certification hence once the skill is certified and updated we will uncomment it
                        //     return (
                        //         <>
                        //             <Image avatar src={feed.RegistrantImage ? feed.RegistrantImage : `${process.env.PUBLIC_URL}/avatar_placeholder.svg`} />
                        //             <List.Content verticalAlign='middle'>
                        //                 <List.Header>
                        //                     <BlueClickableText text={`${feed.Registrant_FullName}`} onClick={() => highlightedPersonResidentClickHandler(feed)} /> in apartment {feed.Unit_Name} would like his meal delivered at {formatDateTime(feed.DateAdded, "time")}{feed.Details && `. Details: ${feed.Details.split('description:')[1].split(',')[0]}`} on {formatDateTime(feed.DateAdded, "date")}
                        //                 </List.Header>
                        //             </List.Content>
                        //         </>
                        //     );
                        default:
                            return (
                                <>
                                    <Image avatar src={feed.RegistrantImage ? feed.RegistrantImage : `${process.env.PUBLIC_URL}/avatar_placeholder.svg`} />
                                    <List.Content verticalAlign='middle'>
                                        <List.Header>
                                            <BlueClickableText onClick={() => feed.Registrant_FullName ? highlightedPersonResidentClickHandler(feed) : {}} text={`${feed.Registrant_FullName ? feed.Registrant_FullName : feed.Unit_Name}`} /> requested <span style={{ fontWeight: "bolder" }}>{feed.RequestType}</span> at {formatDateTime(feed.DateAdded, "time")} on {formatDateTime(feed.DateAdded, "date")}
                                        </List.Header>
                                    </List.Content>
                                </>
                            );
                    }
                } else {
                    return `Room ${feed.Unit_Name} ${feed.Registrant_FullName ? feed.Registrant_FullName : ''} requested ${feed.RequestType} at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`;
                }
            case "MailIsIn":
                const mailIsInStatus = (feed) => {
                    if (feed.IsMailIsIn) return "Yes, the mail is in"
                    else return "No, the mail is not here"
                }
                if (render) {
                    return (
                        <>
                            <Image avatar src={feed.StaffImage ? feed.StaffImage : `${process.env.PUBLIC_URL}/avatar_placeholder.svg`} />
                            <List.Content verticalAlign='middle'>
                                <List.Header>
                                    Staff <BlueClickableText text={`${feed.Staff_FullName}`} onClick={() => feed.Staff_FullName && highlightedPersonResidentClickHandler(feed, "staff")}/> sent a {mailIsInStatus(feed)} Announcement at {formatDateTime(feed.DateAdded, "time")} on {formatDateTime(feed.DateAdded, "date")}
                                </List.Header>
                            </List.Content>
                        </>
                    );
                } else {
                    return `${feed.Staff_FullName} sent a ${mailIsInStatus(feed)} Announcement at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`;
                }
            case "CommunityMessage":
                if (render) {
                    return (
                        <>
                            <Image avatar src={feed.StaffImage ? feed.StaffImage : `${process.env.PUBLIC_URL}/avatar_placeholder.svg`} />
                            <List.Content verticalAlign='middle'>
                                <List.Header>
                                    Staff <BlueClickableText text={`${feed.Staff_FullName}`} onClick={() => feed.Staff_FullName && highlightedPersonResidentClickHandler(feed, "staff")} /> created a Community News at {formatDateTime(feed.DateAdded, "time")} on {formatDateTime(feed.DateAdded, "date")}
                                </List.Header>
                            </List.Content>
                        </>
                    );
                } else {
                    return `${feed.Staff_FullName} created a Community News at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`;
                }
            case "CheckIn":
                if (render) {
                    return (
                        <>
                            <Image avatar src={feed.StaffImage ? feed.StaffImage : `${process.env.PUBLIC_URL}/avatar_placeholder.svg`} />
                            <List.Content verticalAlign='middle'>
                                <List.Header>
                                    Staff <BlueClickableText text={`${feed.Staff_FullName}`} onClick={() => feed.Staff_FullName && highlightedPersonResidentClickHandler(feed, "staff")}/>
                                    {
                                        feed.CheckinMessage == "checkin" ?
                                            ` checked in ${feed.Registrant_FullName} at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}` : ` noted that ${feed.Registrant_FullName} has not checked in at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`
                                    }
                                </List.Header>
                            </List.Content>
                        </>
                    );
                } else {
                    return `${feed.CheckinMessage === 'checkin' ? `checked in ${feed.Staff_FullName} at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")} ` : `noted that ${feed.Registrant_FullName} has not checked in at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`} `;
                }
            case "Announcement":
                if (render) {
                    return (
                        <>
                           <Image avatar src={feed.StaffImage ? feed.StaffImage : `${process.env.PUBLIC_URL}/avatar_placeholder.svg`} />
                            <List.Content verticalAlign='middle'>
                                <List.Header>
                                    Staff <BlueClickableText text={`${feed.Staff_FullName}`} onClick={() => feed.Staff_FullName && highlightedPersonResidentClickHandler(feed, "staff")}/> {`sent an announcement at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`}
                                </List.Header>
                            </List.Content>
                        </>
                    );
                } else {
                    return `${feed.Staff_FullName} sent an announcement at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`;
                }
            case "NotePad":
                let actionText = '';
                if (feed.Action === "Add") {
                    actionText = 'created';
                } else if (feed.Action === "Remove") {
                    actionText = 'removed';
                }
                if (actionText) {
                    if (render) {
                        return (
                            <>
                                <Image avatar src={feed.StaffImage ? feed.StaffImage : `${process.env.PUBLIC_URL}/avatar_placeholder.svg`} />
                                <List.Content verticalAlign='middle'>
                                    <List.Header>
                                        Staff <BlueClickableText text={`${feed.Staff_FullName}`} onClick={() => feed.Staff_FullName && highlightedPersonResidentClickHandler(feed, "staff")}/> {`${actionText} a ${feed.CalendarEvent} event at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`}
                                    </List.Header>
                                </List.Content>
                            </>
                        );
                    } else {
                        return `Staff ${actionText} a ${feed.CalendarEvent} event at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`;
                    }
                }
            case "AddressBook":
                if (render) {
                    return (
                        <>
                            <Image avatar src={feed.StaffImage ? feed.StaffImage : `${process.env.PUBLIC_URL}/avatar_placeholder.svg`} />
                            <List.Content verticalAlign='middle'>
                                <List.Header>
                                    Staff <BlueClickableText text={`${feed.Staff_FullName}`} onClick={() => feed.Staff_FullName && highlightedPersonResidentClickHandler(feed, "staff")} /> {feed.Action === "Add" ? `created a new contact for ${feed.Registrant_FullName} at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}` : 
                                    `removed a contact from ${feed.Registrant_FullName}'s address book at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`}
                                </List.Header>
                            </List.Content>
                        </>
                    );
                } else {
                    return `Staff ${feed.Staff_FullName} ${feed.Action === "Add" ? `created a new contact for ${feed.Registrant_FullName} at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}` : `removed a contact from ${feed.Registrant_FullName}'s address book at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`} `;
                }
            case "BasicRequest":
                const staffActions = ['Visiting_Apartment', 'Visiting Apartment', 'Leaving Apartment', 'Leaving_Apartment']
                let displayText = `asked for ${feed.Action} at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`
                switch (feed.Action) {
                    case "Message":
                        displayText = `read messages at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`
                        break
                    case "Sent Message":
                        displayText = `sent a message at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`
                        break
                    case "Check in":
                        displayText = `checked in at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`
                        break
                    case "Read Activities":
                        displayText = `asked for activities at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`
                        break
                    case "StaffOfTheMonth":
                        displayText = `asked for staff of the month at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`;
                        break;
                    case "ResidentOfTheMonth":
                        displayText = `asked for resident of the month at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`;
                        break; 
                    case "Messages":
                        displayText = `asked for Community News at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`;
                        break;
                    /* 
                    Visting Apartment:
                        (Staff) FName LName is visiting apartment NNNN at hh-mm AM/PM on mm/dd/yyyy
                    Leaving Apartment:
                        (Staff) FName LName is leaving apartment NNNN at hh-mm AM/PM on mm/dd/yyyy
                    */
                    case "Visiting Apartment":
                    case "Visiting_Apartment":
                        displayText = `is visiting apartment ${feed.Unit_Name} at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`;
                        break;
                    case "Leaving Apartment":
                    case "Leaving_Apartment":
                        displayText = `is leaving apartment ${feed.Unit_Name} at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`;
                        break;
                }
                if (render) {
                    if (feed.Action && staffActions.includes(feed.Action)) {
                        // Visiting and leaving apartment
                        return (
                            <>
                                <Image avatar src={feed.StaffImage ? feed.StaffImage : `${process.env.PUBLIC_URL}/avatar_placeholder.svg`} />
                                <List.Content verticalAlign='middle'>
                                    <List.Header>
                                        Staff <BlueClickableText text={`${feed.Staff_FullName}`} onClick={() => feed.Staff_FullName && highlightedPersonResidentClickHandler(feed, "staff")} /> {displayText}
                                    </List.Header>
                                </List.Content>
                            </>
                        );
                    }
                    else if (feed.Action === 'Visitor checked in') {
                        if (feed.Staff_FullName || feed.Registrant_FullName) { // Staff or Resident
                            return <>
                                <Image avatar src={feed.StaffImage || feed.RegistrantImage || `${process.env.PUBLIC_URL}/avatar_placeholder.svg`} />
                                <List.Content verticalAlign='middle'>
                                    <List.Header>
                                        {feed.VisitorName} has checked into the community to visit {feed.Staff_FullName ? 'staff' : ''} {<BlueClickableText text={`${feed.Staff_FullName || feed.Registrant_FullName}`} onClick={() => highlightedPersonResidentClickHandler(feed, feed.Staff_FullName ? "staff" : "resident")} />} at {formatDateTime(feed.DateAdded, "time")} on {formatDateTime(feed.DateAdded, "date")}
                                    </List.Header>
                                </List.Content>
                            </>;
                        } else // Other case
                            return <>
                                <Image avatar src={`${process.env.PUBLIC_URL}/avatar_placeholder.svg`} />
                                <List.Content verticalAlign='middle'>
                                    <List.Header>
                                        Visitor {feed.VisitorName} has checked into the community at {formatDateTime(feed.DateAdded, "time")} on {formatDateTime(feed.DateAdded, "date")}
                                    </List.Header>
                                </List.Content>
                            </>;
                    } else if (feed.Action === 'Sign out' || feed.Action === 'Sign in' || feed.Action === 'Sign_In' || feed.Action === 'Sign_Out') {
                        return (
                            <>
                                <Image avatar src={feed.StaffImage || feed.RegistrantImage || `${process.env.PUBLIC_URL}/avatar_placeholder.svg`} />
                                <List.Content verticalAlign='middle'>
                                    <List.Header>
                                        {feed.Staff_FullName && 'Staff'} {(feed.Staff_FullName || feed.Registrant_FullName) ? <BlueClickableText text={`${feed.Staff_FullName || feed.Registrant_FullName}`} onClick={() => highlightedPersonResidentClickHandler(feed, feed.Staff_FullName ? "staff" : "resident")} /> : 'Visitor ' + feed.VisitorName} {`has checked ${(feed.Action === 'Sign in' || feed.Action === 'Sign_In') ? 'into' : 'out of'} the community at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`}
                                    </List.Header>
                                </List.Content>
                            </>
                        );
                    }
                    else
                        return (
                            <>
                                <Image avatar src={feed.RegistrantImage ? feed.RegistrantImage : `${process.env.PUBLIC_URL}/avatar_placeholder.svg`} />
                                <List.Content verticalAlign='middle'>
                                    <List.Header>
                                        <BlueClickableText
                                            text={`${feed.Registrant_FullName || feed.Staff_FullName || feed.Relative_FullName}`}
                                            onClick={() => {
                                                if (feed.Registrant_FullName) highlightedPersonResidentClickHandler(feed);
                                                else if (feed.Staff_FullName) highlightedPersonResidentClickHandler(feed, "staff");
                                                else if (feed.Relative_FullName) highlightedPersonResidentClickHandler(feed, "relative");
                                            }}
                                        /> {displayText}
                                    </List.Header>
                                </List.Content>
                            </>
                        );
                } else {
                    return `${feed.Registrant_FullName || feed.Staff_FullName || feed.Relative_FullName} ${displayText}`;
                }
            case "Notification":
                if (render) {
                    return (
                        <>
                           <Image avatar src={feed.StaffImage ? feed.StaffImage : `${process.env.PUBLIC_URL}/avatar_placeholder.svg`} />
                            <List.Content verticalAlign='middle'>
                                <List.Header>
                                    Staff <BlueClickableText text={`${feed.Staff_FullName}`} onClick={() => highlightedPersonResidentClickHandler(feed, "staff")} /> {`sent a notification at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`}
                                </List.Header>
                            </List.Content>
                        </>
                    );
                } else {
                    return `${feed.Staff_FullName} sent a notification at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`;
                }
            case "Support":
                if (render) {
                    return (
                        <>
                            <Image avatar src={feed.SupportAction === "Staff" ? feed.StaffImage : feed.RegistrantImage ? feed.RegistrantImage : `${process.env.PUBLIC_URL}/avatar_placeholder.svg`} />
                            <List.Content verticalAlign='middle'>
                                <List.Header>
                                    {(feed.SupportAction === 'Staff' && 'Staff') || ''}<BlueClickableText text={`${feed.SupportAction === "Staff" ? feed.Staff_FullName : feed.Registrant_FullName}`} onClick={() => feed.Staff_FullName && highlightedPersonResidentClickHandler(feed, "staff")} /> {`asked for Speak2 support at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`}
                                </List.Header>
                            </List.Content>
                        </>
                    );
                } else {
                    const fullName = feed.SupportAction === "Staff" ? `${feed.Staff_FullName}` : feed.Registrant_FullName;
                    return `${ feed.SupportAction === "Staff" && 'Staff'} ${fullName} asked for Speak2 support at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`;
                }

            case "Pals":
                const fullName = feed.SupportAction === "Staff" ? feed.Staff_FullName : feed.Registrant_FullName;
                const imageSrc = feed.SupportAction === "Staff" ? feed.StaffImage : (feed.RegistrantImage || `${process.env.PUBLIC_URL}/avatar_placeholder.svg`);
                const autoApproveText = feed.AutoApproved ? "auto approved " : "";
                const supportActionText = feed.SupportAction === "Staff" ? "Staff " : "";

                if (render) {
                    return (
                        <>
                            {
                                feed.palsMessageRequest ? (
                                    <>
                                        <Image avatar src={feed.StaffImage ? feed.StaffImage : `${process.env.PUBLIC_URL}/avatar_placeholder.svg`} />
                                        <List.Content verticalAlign='middle'>
                                            <List.Header>
                                                Staff <BlueClickableText text={`${feed.Staff_FullName}`} onClick={() => feed.Staff_FullName && highlightedPersonResidentClickHandler(feed, "staff")} />  {`${feed.palsMessageRequest} a Pals message from ${feed.Relative_FullName} at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`}
                                            </List.Header>
                                        </List.Content>
                                    </>
                                ) : (
                                    <>
                                    <Image avatar src={imageSrc} />
                                    <List.Content verticalAlign='middle'>
                                        <List.Header>
                                            Pal {<BlueClickableText text={feed.Relative_FullName} onClick={() => feed.Relative_FullName && highlightedPersonResidentClickHandler(feed, "relative")}/> } sent a {autoApproveText}message at {formatDateTime(feed.DateAdded, "time")} on {formatDateTime(feed.DateAdded, "date")}
                                        </List.Header>
                                    </List.Content>
                                    </>
                                )
                            }
                           
                        </>
                    );
                } else {
                    if (feed.AutoApproved) {
                        return `Pal ${fullName} sent a ${autoApproveText}message at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`;
                    }
                    if (feed.palsMessageRequest) {
                        return `Staff ${feed.Staff_FullName} ${feed.palsMessageRequest} a Pals message from ${feed.Relative_FullName} at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`;
                    }
                    return `${supportActionText}${fullName} asked for Speak2 support at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`;
                }

            case "Invites":
                if (render) {
                    return (
                        <>
                            <Image avatar src={feed.RegistrantImage ? feed.RegistrantImage : `${process.env.PUBLIC_URL}/avatar_placeholder.svg`} />
                            <List.Content verticalAlign='middle'>
                                <List.Header>
                                    <BlueClickableText onClick={() => feed.Registrant_FullName ? highlightedPersonResidentClickHandler(feed) : {}} text={`${feed.Registrant_FullName}`} /> added {feed.inviteTo} to their addressbook at {formatDateTime(feed.DateAdded, "time")} on {formatDateTime(feed.DateAdded, "date")}
                                </List.Header>
                            </List.Content>
                        </>
                    );
                } else {
                    return `${feed.Registrant_FullName} added ${feed.inviteTo} to their addressbook at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`;
                }
            
            case "RelativeMessage":
                if (render) {
                    return (
                        <>
                            <Image avatar src={feed.RegistrantImage ? feed.RegistrantImage : `${process.env.PUBLIC_URL}/avatar_placeholder.svg`} />
                            <List.Content verticalAlign='middle'>
                                <List.Header>
                                    <BlueClickableText onClick={() => feed.Registrant_FullName ? highlightedPersonResidentClickHandler(feed) : {}} text={`${feed.Registrant_FullName}`} /> received a message at {formatDateTime(feed.DateAdded, "time")} on {formatDateTime(feed.DateAdded, "date")}
                                </List.Header>
                            </List.Content>
                        </>
                    );
                } else {
                    return `${feed.Registrant_FullName} received a message at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`;
                }
                
            case "DeviceMove":
                if (render) {
                    return (
                        <>
                            <Image avatar src={feed.StaffImage ? feed.StaffImage : `${process.env.PUBLIC_URL}/avatar_placeholder.svg`} />
                            <List.Content verticalAlign='middle'>
                                <List.Header>
                                    Staff <BlueClickableText text={`${feed.Staff_FullName}`} onClick={() => feed.Staff_FullName && highlightedPersonResidentClickHandler(feed, "staff")} /> {`has moved device ${feed.DeviceDSN} to apartment ${feed.Unit_Name} at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`}
                                </List.Header>
                            </List.Content>
                        </>
                    );
                } else {
                    return `${feed.Staff_FullName} has moved device ${feed.DeviceDSN} to apartment ${feed.Unit_Name} at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`;
                }
            default:
                if (render) {
                    return (
                        <>
                            <Image avatar src={feed.RegistrantImage ? feed.RegistrantImage : `${process.env.PUBLIC_URL}/avatar_placeholder.svg`} />
                            <List.Content verticalAlign='middle'>
                                <List.Header>
                                    <BlueClickableText
                                        text={`${feed.Registrant_FullName || feed.Staff_FullName || feed.Relative_FullName}`}
                                        onClick={() => {
                                            if (feed.Registrant_FullName) highlightedPersonResidentClickHandler(feed)
                                            else if (feed.Staff_FullName) highlightedPersonResidentClickHandler(feed, "staff")
                                            else if (feed.Relative_FullName) highlightedPersonResidentClickHandler(feed, "relative")
                                        }}
                                    />  {!feed.Registrant_FullName && feed.Staff_FullName && 'created' || 'asked for'} {feed.Category} at {formatDateTime(feed.DateAdded, "time")} on {formatDateTime(feed.DateAdded, "date")}
                                </List.Header>
                            </List.Content>
                        </>
                    );
                } else {
                    return `${feed.Registrant_FullName || feed.Staff_FullName || feed.Relative_FullName} ${!feed.Registrant_FullName && feed.Staff_FullName && 'created' || 'asked for'} ${feed.Category} at ${formatDateTime(feed.DateAdded, "time")} on ${formatDateTime(feed.DateAdded, "date")}`;
                }
        }
    } else return null;
}

function addResidentPrefix(name) {
    return name.includes('resident') ? '' : 'Resident';
}

const RequestsTextList: FC<RequestListProps> = (props) => {
    const [showRequestDetails, setShowRequestDetails] = useState<{ openModal: boolean, feedItem: FeedItem | null, personOfFocus: string }>({
        openModal: false,
        feedItem: null,
        personOfFocus: ""
    })

    const nextPage = () => {
        props.setPageNo((prev) => prev + 1)
    }

    if (props.loading) {
        return <Dimmer active={true} inverted>
            <Loader active={true} />
        </Dimmer>
    }
    const pullCordRequests = props.feedItems.filter(el => el && el.RequestChannel && el.RequestChannel === 'ExternalDevice')

    const highlightedPersonClickHandler = (feedItem: FeedItem, personOfFocus = "resident") => {
        setShowRequestDetails({
            openModal: true,
            feedItem: feedItem,
            personOfFocus: personOfFocus
        })
    }

    const modalCloseHandler = () => {
        setShowRequestDetails({
            openModal: false,
            feedItem: null,
            personOfFocus: ""
        })
    }

    const renderListItem = (feed: FeedItem) => {
        return (
            <List.Item key={feed._id} style={{ display: "flex" }}>
                {fetchRelavantFeedContent(feed, true, props.facilityTimezone, highlightedPersonClickHandler)}
            </List.Item>
        );
    };

    const renderModalContent = () => {
        switch (showRequestDetails.personOfFocus) {
            case 'resident':
                return showRequestDetails.feedItem && showRequestDetails.feedItem.RegistrantId ? (
                    <>
                        <Modal.Header>Resident Details</Modal.Header>
                        <Modal.Content>
                            <ResidentDetailView
                                resident={{
                                    _id: showRequestDetails.feedItem.RegistrantId,
                                }}
                            />
                        </Modal.Content>
                    </>
                ) : (
                    <></>
                );
            
            case 'staff':
                return showRequestDetails.feedItem && showRequestDetails.feedItem.StaffId ? (
                    <>
                        <Modal.Header>Staff Details</Modal.Header>
                        <Modal.Content>
                            <StaffFeedDetailView
                                staff={{
                                    _id: showRequestDetails.feedItem.StaffId,
                                }}
                            />
                        </Modal.Content>
                    </>
                ) : (
                    <></>
                );
            
            case 'relative':
                return showRequestDetails.feedItem && showRequestDetails.feedItem.RelativeId ? (
                    <>
                        <Modal.Header>Relative Details</Modal.Header>
                        <Modal.Content>
                            <RelativeFeedDetailView
                                feedItem = {showRequestDetails.feedItem}
                            />
                        </Modal.Content>
                    </>
                ) : (
                    <></>
                );

            default:
                return <></>;
        }
    }
    
    return (
        <>
            <List divided relaxed style={{ height: "100vh", overflowY: "scroll" }}>
                <Header as={"h5"}> External Device Feed</Header>
                {pullCordRequests.map(item => renderListItem(item))}
                <Divider />
                <Header as={"h5"}>Community Feed</Header>
                {props.feedItems
                    .filter(item =>
                        item && item.StaffId !== "undefined" && item.Action !== "CustomYesIntent")
                    .map(item => {
                        console.log({ item });
                        return renderListItem(item);
                    })}
                {
                    props.feedHasMorePages ?
                        <LoadMore isLoading={props.feedIsPaginating} hasMore={props.feedHasMorePages} next={nextPage} />
                        :
                        <></>
                }
            </List>
            <Modal open={showRequestDetails.openModal} onClose={modalCloseHandler} closeIcon={true} closeOnDocumentClick >
                {
                    renderModalContent()
                }
            </Modal>
        </>

    )
}

const RequestIcons: FC<RequestsFilterProps> = (props) => {
    const [loading, setLoading] = useState(false)
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [dateRange, setDateRange] = useState({
        start: new Date(),
        end: new Date()
    })
    const [showPopUpText, setShowPopUpText] = useState(false);
    const calendarRef = useRef<HTMLHeadingElement>(null);
    // const formattedDate = new Intl.DateTimeFormat('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }).format(new Date());  // Example output: Tuesday, January 25, 2022
    const getHourFromTimeStamp = (timestamp: Date) => {
        const formatted = new Intl.DateTimeFormat("en-US", { hour: "numeric", minute: "numeric" }).format(new Date(timestamp))
        return formatted
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (calendarRef.current && !calendarRef.current.contains(event.target)) {
                setShowDatePicker(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [calendarRef]);
   
    const formatRequestDataForExport = () => {
        const data = props.feedItems.map((feedItem) => {
            const relavantFeedMessage = props.fetchRelavantFeedContent(feedItem, false, props.facilityTimezone, (feedItem) => {}) || ""
            return {
                Name: relavantFeedMessage,
                Category: feedItem.Category,
                Date: moment(feedItem.DateAdded).format('MM-DD-YYYY'), 
                Time: moment(feedItem.DateAdded).format('hh:MM a')
            }
        })
        return data
    }

    const formatRequestDataForPrint = () => {
        const data = props.feedItems.map((feedItem) =>
        {
            const relavantFeedMessage = props.fetchRelavantFeedContent(feedItem, false, props.facilityTimezone, (feedItem) => {}) || ""
            return {
                Subject: relavantFeedMessage as string,
                StartDate: new Date(feedItem.DateAdded),
                StartTime: new Date(feedItem.DateAdded),
            }
        }
        )

        return data
    }

    const formatDateRange = (dateRange: { start: Date, end: Date; }) => {
        const startDate = dateRange.start ? moment(dateRange.start).format('dddd, MMMM DD, YYYY') : "none";
        const endDate = dateRange.end ? moment(dateRange.end).format('dddd, MMMM DD, YYYY') : "none";
        if (!dateRange.end || (startDate === endDate)) return startDate;
        return `${startDate} - ${endDate}`;
    };

    const selectDateFilter = async (dates) => {
        const [start, end] = dates;
        setDateRange({
            start,
            end
        });
        if(start && !end) {
            setShowPopUpText(true);
        }
        if(start && end){
            setShowPopUpText(false);
            const updatedFilters: DateRangeFilter = {};
            updatedFilters.fromDate = start;
            updatedFilters.toDate = end;
            props.setFeedFilters((prev) => ({ ...prev, ...updatedFilters }));
            setShowDatePicker((prev) => !prev);
        }
    }
    return <div style={{ display: "flex", gap: "4px", alignItems: "flex-start", justifyContent: "space-between" }}>
        <div ref={calendarRef} style={{ display: "flex", gap: "2px", justifyContent: "left", alignItems: "flex-start", cursor: "pointer", position: "relative" }}>
            <Icon name='calendar' size='small' style={{ marginTop: '4px' }} onClick={() => {
                setShowDatePicker(prev => !prev);
            }} />
            <div>
                <span onClick={() => setShowDatePicker(prev => !prev)}>{formatDateRange(dateRange)}</span>
                {showDatePicker &&
                    <div style={{ position: "absolute", zIndex: 2 }}>
                        <DatePicker
                            selected={dateRange.start}
                            startDate={dateRange.start}
                            endDate={dateRange.end}
                            maxDate={new Date()}
                            onChange={selectDateFilter}
                            selectsRange
                            inline
                            onBlur={() => setShowDatePicker(false)}
                        />
                        {showPopUpText && (
                            <div
                                style={{
                                    position: "absolute",
                                    top: "0px",
                                    right: "-105px",
                                    backgroundColor: "#F0F0F0",
                                    color: "#216BA5",
                                    padding: "5px",
                                    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
                                    zIndex: 3,
                                    display: "flex",
                                    alignItems: "center"
                                }}
                            >
                                <span>Select end date</span>
                            </div>
                        )}
                    </div>
                }
            </div>
        </div>
        <FilterPrintExportIcons
            exportData={formatRequestDataForExport()}
            onPrintClick={async () => {
                setLoading(true)
                const exportData = formatRequestDataForPrint()
                await formatAndExportDataForPrint(exportData, `${props.residentName ? props.residentName : props.facilityName}-requests`, props.facilityTimezone, "activity"); // activity formats data in "YYYY-MM-DD hh:mm A"
                setLoading(false)
            }}
            onAnnouncementFilterClick={() => {
                console.log("print click")
            }}
            onBirthdaysFilterClick={(filteredRequests) => {
                props.filterChangeHandler(filteredRequests)
            }}
            onCalendarFilterClick={(filterItems) => {
                props.filterChangeHandler(filterItems)
            }}

            onCheckinFilterClick={(filterItems) => {
                props.filterChangeHandler(filterItems)
            }}

            onRequestsFilterClick={(filterItems) => {
                props.filterChangeHandler(filterItems)
            }}
            
            onMailFilterClick={(filterItems) => {
                props.filterChangeHandler(filterItems)
            }}

            onMenuFilterClick={(filterItems) => {
                props.filterChangeHandler(filterItems)
            }}

            onPalsFilterClick={(filterItems) => {
                props.filterChangeHandler(filterItems)
            }}

            onCarFilterClick={(filterItems) => {
                props.filterChangeHandler(filterItems)
            }}

            onKitchenFilterClick={(filterItems) => {
                props.filterChangeHandler(filterItems)
            }}

            onMailIsInFilterClick={(filterItems) => {
                props.filterChangeHandler(filterItems)
            }}

            onMaintenanceFilterClick={(filterItems) => {
                props.filterChangeHandler(filterItems)
            }}
            onROTMFilterClick={(filterItems) => {
                props.filterChangeHandler(filterItems)
            }}

            onSMSFilterClick={(filterItems) => {
                props.filterChangeHandler(filterItems)
            }}

            onSOTMFilterClick={(filterItems) => {
                props.filterChangeHandler(filterItems)
            }}
            onHouseKeeingFilterClick={(filterItems) => {
                props.filterChangeHandler(filterItems)
            }}
            onUnschduledEventFilterClick={(filterItems) => {
                props.filterChangeHandler(filterItems)
            }}
            onDeviceStatusFilterClick={(filterItems) => {
                props.filterChangeHandler(filterItems)
            }}
            printButtonLoading={loading}
            disable={props.feedItems && props.feedItems.length ? false : true}
            // todo check if resident name is also required for file name 
            exportFileName={`${props.residentName ? props.residentName : props.facilityName}-requests`}
        />
    </div>
}

const RequestTextListWithFilter: FC<RequestsWithFilterProps> = (props) => {
    const [feedFilters, setFeedFilters] = useState<any>({
        fromDate: new Date(),
        toDate: new Date(),
        RegistrantId: props.residentId ? props.residentId : 'all',
    })
    const [sortBy, setSortBy] = useState([{ DateAdded: 'asc' }])
    const [pageNo, setPageNo] = useState(1)
    const [filteredFeedItems, setFilteredRequests] = useState<FeedItem[]>([])
    const [isFilterSelected, setIsFilterSelected] = useState(false)
    const [facilityTimeZone, setFacilityTimeZone] = useState("")
    const [facilityName, setFacilityName] = useState("")
    const {
        feedItems: feedItems,
        error: feedError,
        isFetching: feedIsFetching,
        isPaginating: feedIsPaginating,
        hasMorePages: feedHasMorePages,
    } = useFeedItems(feedFilters, sortBy, pageNo, 20, facilityTimeZone) // todo figure out what to pass as history
    const profile = useSelector(({ authReducer }: { authReducer: AuthState }) => {
        return authReducer.profile
    })
    useEffect(() => {
        (async () => {
            const { FacilityTimeZone, Name } = await fetchOneFacility(profile && profile.Facility || "")
            setFacilityTimeZone(FacilityTimeZone || 'America/New_York')
            setFacilityName(Name)
        })()
    }, [])

    if (feedError) {
        return <Message error>Could not fetch Feed items</Message>
    }

    const filterChangeHandler = (filterItems: string[]) => {
        setIsFilterSelected(filterItems.length ? true : false)
        const formattedFilterNames: string[] = []
        filterItems.forEach(filter => {
            switch (filter) {
                case ("menu"):
                    formattedFilterNames.push("Menu")
                    break
                case ("calendar"):
                    formattedFilterNames.push("Calendar", "6360ca9c7b46ce0008e0d5e0")
                    break
                case ("mail"):
                    formattedFilterNames.push("Affirmations", "Messages")
                    break
                case ("checkin"):
                    formattedFilterNames.push("CheckIn", "Transportation", "Walk", "Stretch", "Yoga", "Exercise", "Game", "TV", "Movie", "Ate", "Awake", "Meds", "Water", "Sleep")
                    break
                case ("requests"):
                    formattedFilterNames.push("Request")
                    break
                case ("birthdays"):
                    formattedFilterNames.push("Birthdays")
                    break
                case ("rotm"):
                    formattedFilterNames.push("ResidentOfTheMonth")
                    break
                case ("sotm"):
                    formattedFilterNames.push("StaffOfTheMonth")
                    break
                case ("car"):
                    formattedFilterNames.push("Transportation", "Valet Car")
                    break
                case ("kitchen"):
                    formattedFilterNames.push("Kitchen", "Dinning", "Kitchen Dietary")
                    break
                case ("sms"):
                    formattedFilterNames.push("Message", "Sent Message")
                    break
                case ("maintenance"):
                    formattedFilterNames.push("Maintenance", "Maintenance Support")
                    break
                case ("mail_is_in"):
                    formattedFilterNames.push("Mail")
                    break
                case("housekeeping"):
                    formattedFilterNames.push("Housekeeping")
                    break
                case("event"): 
                    formattedFilterNames.push("Unscheduled Event", "Resident Calendar")
                    break
                case("device_status"):
                    formattedFilterNames.push("DeviceStatusMonitor")
                default:
                    return filter
            }
        })
        const filteredRequests = feedItems.filter(feedItem => {
            const containsAction = feedItem.Action ? formattedFilterNames.includes(feedItem.Action) : false;
            const containsCategory = feedItem.Category ? formattedFilterNames.includes(feedItem.Category) : false;
            const containsRequestType = feedItem.RequestType ? formattedFilterNames.includes(feedItem.RequestType) : false;
            return containsAction || containsCategory || containsRequestType;
        });
        setFilteredRequests(filteredRequests)
    }

    return (
        <>
            <RequestIcons
                feedItems={isFilterSelected ? (filteredFeedItems.length ? filteredFeedItems : []) : feedItems}
                filterChangeHandler={filterChangeHandler}
                setFeedFilters={setFeedFilters}
                residentName={props.residentName}
                facilityName={facilityName}
                facilityTimezone={facilityTimeZone}
                fetchRelavantFeedContent={fetchRelavantFeedContent}
            />
            <RequestsTextList
                feedItems={isFilterSelected ? (filteredFeedItems.length ? filteredFeedItems : []) : feedItems}
                feedHasMorePages={feedHasMorePages}
                feedIsPaginating={feedIsPaginating}
                loading={feedIsFetching}
                setPageNo={setPageNo}
                facilityTimezone={facilityTimeZone}
            />
        </>
    );
}

export default RequestTextListWithFilter