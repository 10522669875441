import { AnnouncementType } from "../pages/AdminPanel/Notifications/UpdateForm";
import { Message, ResponseResultIntent, ResponseResultInvite, ResponseResultMessage } from "../types/Analytics";
import { dbIntensivePaginationWrapper, fetchAllPaginatedData } from "../util";
import API from "./API";

export const fetchMessageAnalytics = async (filters = {}): Promise<ResponseResultMessage> => {
    try {
        const response = await API.lambdaPost('/analytics/messages', filters);
        return response.Result;
    } catch (error) {
        console.error('Failed to fetch message analytics:', error);
        throw error;
    }
}

export const fetchAllMessageAnalytics = async (filters: any = {}): Promise<Message[]> => {
    if (!filters.fromDate || !filters.toDate) throw new Error('Missing date filter for fetching content');
    const messageAPIPaginationAdaptor = async ({pagingSorting, ...params}) => {
        const updatedParams = {
            ...params,
        }
        if (pagingSorting.page_no) {
            updatedParams.pageNo = pagingSorting.page_no;
        }
        if (pagingSorting.page_size) {
            updatedParams.pageLimit = pagingSorting.page_size;
        }
        if (pagingSorting.sort) {
            updatedParams.sortBy = pagingSorting.sort;
        }
        const { Result: response } = await API.lambdaPost('/analytics/messages', updatedParams);
        /*
            {
                success: boolean;
                allFacilities: FacilityRes[];
                pageNumber: number;
                pageLimit: number;
                totalItemCount: number;
                messageReadCount: number;
                messages: Message[];
            }
        */
        const modifiedResponse: { Result: Message[]; TotRecords: number } = {
            Result: [],
            TotRecords: 0,
        };
        if (response && response.success && response.messages) {
            modifiedResponse.Result = response.messages;
        }
        if (response && response.totalItemCount) {
            modifiedResponse.TotRecords = response.totalItemCount;
        }
        return modifiedResponse;
    };
    return await fetchAllPaginatedData(
        messageAPIPaginationAdaptor,
        filters
    );
}

export const fetchInviteAnalytics = async (filters = {}): Promise<ResponseResultInvite> => {
    try {
        const response = await API.lambdaPost('/analytics/invites', filters);
        return response.Result;
    } catch (error) {
        console.error('Failed to fetch invite analytics:', error);
        throw error;
    }
}

export const fetchIntentAnalytics = async (filters): Promise<ResponseResultIntent> => {
    try {
        const response = await API.lambdaPost('/analytics/intents', filters);
        return response.Result;
    } catch (error) {
        console.error('Failed to fetch invite analytics:', error);
        throw error;
    }
}

export const fetchAllAnnouncementAnalytics = async (filters: any = {}, sort: any): Promise<AnnouncementType[]> => {
    try {
        if (!filters.fromDate || !filters.toDate) throw new Error('Missing date filter for fetching content');
        const announcementAPIPaginationAdaptor = async ({ pagingSorting, ...params }) => {
            const updatedParams = {
                ...params,
            };
            if (pagingSorting.page_no) {
                updatedParams.pageNo = pagingSorting.page_no;
            }
            if (pagingSorting.page_size) {
                updatedParams.pageLimit = pagingSorting.page_size;
            }
            if (pagingSorting.sort) {
                updatedParams.sortBy = pagingSorting.sort;
            }
            const { Result: response } = await API.lambdaPost('/analytics/intents', updatedParams);
            /*
               {
                    "success": true,
                    "pageNumber": 1,
                    "pageLimit": 10,
                    "announcementCount": 0,
                    "notificationCount": 8,
                    "mailIsInCount": 0,
                    "intentsData": [],
                    "paginatingCount": 0,
                    "allFacilities": []
                }
            */
            const modifiedResponse: { Result: AnnouncementType[]; TotRecords: number } = {
                Result: [],
                TotRecords: 0,
            };
            if (response && response.success && response.intentsData) {
                modifiedResponse.Result = response.intentsData;
            }
            if (response && response.paginatingCount) {
                modifiedResponse.TotRecords = response.paginatingCount;
            }
            return modifiedResponse;
        };
        return await dbIntensivePaginationWrapper({
            apiCall: announcementAPIPaginationAdaptor,
            params: filters,
            sorting: sort,
            page_size: 100,
        });
    } catch (error) {
        throw error;
    }
};

export const deletePendingRelative = async (contact) => {
    try {
        const response = await API.lambdaPost('/analytics/pending-relatives/delete', {
            contact
        });
        return response.Result;
    } catch (error) {
        console.error('Failed to fetch invite analytics:', error);
        throw error;
    }
}

export const deleteRelative = async (relativeId: string) => {
    try {
        const response = await API.lambdaPost('/analytics/relatives/delete', {
            relativeId
        });
        return response.Result;
    } catch (error) {
        console.error('Failed to fetch invite analytics:', error);
        throw error;
    }
}

