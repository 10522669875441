import React, { useEffect, useState } from "react";
import { DocWidgetData } from "../../../types/Service";
import { Registrant, User, UserProfile } from "../../../types";
import { Group } from "../../../types/Group";
import { fetchFacilityRegistrants } from "../../../services/Registrants";
import { listGroups } from "../../../services/Groups";
import {  fetchAllActiveUsers } from "../../../services/Users";
import { toast } from "react-toastify";
import { ListPaginatedDocWidgetData, deleteDocWidgetData, getDocWidgetData } from "../../../services/DocWidgets";
import { Button, Dimmer, Input, Loader, Modal, Pagination, PaginationProps, Table } from "semantic-ui-react";
import ModalDocWidget from "../../../components/Service/Modals";
import './style.less';
interface Props {
    profile: UserProfile | null;
}
const Docs = ({ profile }: Props) => {
    const [allDocs, setAllDocs] = useState<DocWidgetData[]>([]);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [modalContent, setModalContent] = useState<DocWidgetData | null>(null);
    const [selectedFile, setSelectedFile] = useState<string | null>(null);
    const [staffList, setStaffLists] = useState<User[]>([]);
    const [residentsList, setResidentLists] = useState<Registrant[]>([]);
    const [groupsList, setGroupsList] = useState<Group[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [isDocLoading, setIsDocLoading] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [data, setData] = useState<DocWidgetData>({
        Name: '',
        Access: [],
        Description: '',
        files: [],
    });
    const [createDocOpen, setCreateDocOpen] = useState<boolean>(false);
    const staffDictionary: Record<string, User> = {};
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageLimit, setPageLimit] = useState<number>(20);
    const [totalDocs, setTotalDocs] = useState<number>(0);
    const [docsFetch, setDocsFetch] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<string>('');

    useEffect(() => {
        fetchAllData();
    }, []);

    useEffect(() => {
        fetchDocsData();
    }, [pageNo, refresh]);

    useEffect(() => {
        if (deleteId) {
            setDeleteModal(true);
        }
    }, [deleteId]);

    const fetchDocsData = async () => {
        try {
            setDocsFetch(true);
            let allDocs = {
                Result: [],
                TotRecords: 0
            };
            if (searchQuery) {
                allDocs = await ListPaginatedDocWidgetData(pageNo, pageLimit, { Name: searchQuery });
            } else {
                allDocs = await ListPaginatedDocWidgetData(pageNo, pageLimit);
            }
            setAllDocs(allDocs.Result);
            setTotalDocs(allDocs.TotRecords);
        } catch (error) {
            console.error("Error fetching details in docs");
            toast.error("Failed to fetch data", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setDocsFetch(false);
        }
    };

    const fetchAllData = async () => {
        try {
            setIsFetching(true);
            const residents = await fetchFacilityRegistrants();
            const groups = await listGroups();
            const users = await fetchAllActiveUsers();
            setResidentLists(residents);
            setGroupsList(groups);
            setStaffLists(users);
        } catch (error) {
            console.error("Error fetching details in docs");
            toast.error("Failed to fetch data", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setIsFetching(false);
        }
    };

    staffList.forEach((staff: User) => {
        staffDictionary[staff._id] = staff;
    });

    const closeModal = () => {
        setOpenModal(false);
        setModalContent(null);
        setSelectedFile(null);
    };

    const getAccessNames = (accessIds: string[]) => {
        if (!Array.isArray(accessIds)) {
            accessIds = [accessIds];
        }
        if (!accessIds || (Array.isArray(accessIds) && accessIds.length) == 0) {
            return 'No room';
        }
        if (accessIds.includes('All')) {
            return 'All';
        }
        const names: string[] = accessIds.map(id => {
            const resident: Registrant | undefined = residentsList.find((resident) => resident._id === id);
            if (resident) {
                return `${resident.FirstName} ${resident.LastName}`;
            }

            const group: Group | undefined = groupsList.find(group => group._id === id);
            if (group) {
                return group.Name;
            }

            const staff: User | undefined = staffList.find(staff => staff._id === id);
            if (staff) {
                return `${staff.FirstName} ${staff.LastName}`;
            }
            return 'unknown room';
        });
        return names.join(', ');
    };

    const handleDocsView = async (id: string, name: string) => {
        try {
            setIsDocLoading(true);
            const data = await getDocWidgetData(id);
            setModalContent(data);
            setSelectedFile(name);
            setOpenModal(true);
            setIsDocLoading(false);
        } catch (error) {
            setIsDocLoading(false);
            toast.error('Something went wrong', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
            });
        }
    };

    const handleCreateDocModal = () => {
        setCreateDocOpen(!createDocOpen);
    };

    const handlePageChange = (e, { activePage }: PaginationProps) => {
        setPageNo(Number(activePage));
    };

    const handleRefresh = () => {
        setRefresh(!refresh);
    };

    const isImage = (url) => {
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp']; // Add more if needed
        const extension = url.split('.').pop().toLowerCase();
        return imageExtensions.includes(extension);
    };

    const handleEdit = (doc: DocWidgetData) => {
        try {
            if (!doc._id) throw new Error("No _id found in selected Doc");
            setData(doc);
            setCreateDocOpen(true);
            setIsEdit(true);
        } catch (error) {
            console.error(error);
            toast.error(`${error}`, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
            });
        }
    };

    const handleDelete = async (docId: string) => {
        try {
            setDeleteModal(false);
            setIsFetching(true);
            if (!docId) throw new Error("No _id found in selected Doc");
            await deleteDocWidgetData(docId);
            handleRefresh();
        } catch (error) {
            console.error(error);
            toast.error(`${error}`, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
            });
        } finally {
            setDeleteId("");
            setIsFetching(false);
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const handleSearchClick = () => {
        if (searchQuery === '') {
            setPageLimit(20);
        } else {
            setPageLimit(100);
        }
        setPageNo(1);
        fetchDocsData();
    };

    const sortedDocs = allDocs && allDocs.length > 0 && allDocs.sort((a, b) => a.Name.localeCompare(b.Name));

    return (
        <div>
            <Dimmer active={isFetching || docsFetch} inverted>
                <Loader size='small'>Loading</Loader>
            </Dimmer>
            <Button
                style={{ backgroundColor: 'rgb(24,52,102)', color: '#ffff' }}
                content="Create new Doc"
                icon="file outline"
                onClick={handleCreateDocModal}
            />
            <Input
                action={{ icon: 'search', onClick: handleSearchClick }}
                placeholder='Search Docs...'
                value={searchQuery}
                onChange={handleSearchChange}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleSearchClick();
                    }
                }}
            />
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Access Rooms</Table.HeaderCell>
                        <Table.HeaderCell>CreatedBy</Table.HeaderCell>
                        <Table.HeaderCell>UpdatedBy</Table.HeaderCell>
                        <Table.HeaderCell>Files</Table.HeaderCell>
                        <Table.HeaderCell>Options</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Dimmer active={isDocLoading} inverted>
                        <Loader size='tiny'>Loading</Loader>
                    </Dimmer>
                    {sortedDocs && sortedDocs.length > 0 && sortedDocs.map((docs: DocWidgetData) => (
                        <Table.Row key={docs._id} style={{ cursor: 'pointer' }}>
                            <Table.Cell>
                                {docs.Name}
                            </Table.Cell>
                            <Table.Cell>
                                {getAccessNames(docs.Access)}
                            </Table.Cell>
                            <Table.Cell>
                                {docs.AddedBy && staffDictionary[docs.AddedBy]
                                    ? `${staffDictionary[docs.AddedBy].FirstName} ${staffDictionary[docs.AddedBy].LastName}`
                                    : "-"}
                            </Table.Cell>
                            <Table.Cell>
                                {docs.UpdatedBy && staffDictionary[docs.UpdatedBy]
                                    ? `${staffDictionary[docs.UpdatedBy].FirstName} ${staffDictionary[docs.UpdatedBy].LastName}`
                                    : "-"}
                            </Table.Cell>
                            <Table.Cell style={{ maxHeight: '100px', overflowY: 'auto' }}>
                                {docs.files.map((file, index) => (
                                    <div style={{ cursor: "pointer" }} className="file-div hover-grey" onClick={() => docs._id && handleDocsView(docs._id, file)} key={index}>{file}</div>
                                ))}
                            </Table.Cell>
                            <Table.Cell>
                                <div className="center-style">
                                    <Button icon="edit" size="medium" className="edit-button" onClick={() => handleEdit(docs)} />
                                    <Button icon="delete" size="medium" className="delete-button" onClick={() => docs._id && setDeleteId(docs._id)} />
                                </div>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <div className="center-style">
                <Pagination
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    activePage={pageNo}
                    siblingRange={1}
                    totalPages={Math.ceil((totalDocs || 1) / pageLimit)}
                    onPageChange={handlePageChange}
                />
            </div>
            {/* Create Modal */}
            <ModalDocWidget
                open={createDocOpen}
                setOpen={setCreateDocOpen}
                data={data}
                setData={setData}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                getDocsData={() => { handleRefresh(); }}
                facilityId={profile ? profile.Facility : ''}
            />
            {/* Modal for displaying file content */}
            <Modal open={openModal} onClose={closeModal}>
                <Modal.Header>{modalContent ? modalContent.Name : ''}</Modal.Header>
                <Modal.Content>
                    {modalContent && modalContent.attachedFiles && modalContent.attachedFiles.map((attachedFile, index) => (
                        <div key={index}>
                            {attachedFile.name === selectedFile && (
                                isImage(attachedFile.name) ? (
                                    <img src={attachedFile.attachedLink} alt={attachedFile.name} style={{ maxWidth: '100%' }} />
                                ) : (
                                    <a href={attachedFile.attachedLink} target="_blank">
                                        {attachedFile.name}
                                    </a>
                                )
                            )}
                        </div>
                    ))}
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={closeModal}>Close</Button>
                </Modal.Actions>
            </Modal>
            <Modal //delete 
                onClose={() => {
                    setDeleteModal(false);
                }}
                size={'tiny'}
                open={deleteModal}
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <Modal.Content>
                    <Modal.Description>
                        <b>
                            Are you sure you want to delete this Doc? This will delete the uploaded documents linked to this Doc widget.
                        </b>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            setDeleteId("");
                            setDeleteModal(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="red"
                        onClick={async (e) => {
                            e.preventDefault();
                            handleDelete(deleteId);
                        }}
                    >
                        Yes
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
};

export default Docs;