import React, { useEffect, useRef, useState } from 'react';
import { Button, Dimmer, Dropdown, Form, Loader, Modal } from 'semantic-ui-react';
import './style.less';
import {
    addDocWidgetData,
    deleteAttachedFileData,
    deleteDocWidgetData,
    updateDocWidgetData,
} from '../../../services/DocWidgets';
import { DocWidgetData } from '../../../types/Service';
import { toast } from 'react-toastify';
import { uploadFile } from '../../../services/FileUpload';
import { fetchAllStaffUsers } from '../../../services/Users';
import { fetchAllActiveFacilityRegistrants } from '../../../services/Registrants';
import { listGroups } from '../../../services/Groups';
import { sortByKey } from '../../../util/sortData';

type Access = {
    key: string;
    text: string;
    value: string;
};
interface ModalComponentsDocWidgetProps {
    data: DocWidgetData;
    setData: React.Dispatch<React.SetStateAction<DocWidgetData>>;
    onCancel: () => void;
    isEdit: boolean;
    getDocsData: () => void;
    facilityId?: string;
    source?: string;
}
function ModalComponentsDocWidget({
    data,
    setData,
    onCancel,
    isEdit = false,
    getDocsData,
    facilityId = '',
    source = ''
}: ModalComponentsDocWidgetProps) {
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [accessOptions, setAccessOptions] = useState<Access[]>([]); // Access dropdown options

    useEffect(() => {
        const getAccessData = async () => {
            setLoading(true);
            const staff = await fetchAllStaffUsers();
            const residents = await fetchAllActiveFacilityRegistrants(facilityId, true);
            const groups = await listGroups();
            const groupOptions = sortData(groups, 'Group');
            const staffOptions = sortData(staff, 'Staff');
            const residentOptions = sortData(residents, 'Resident');
            const options = []
                .concat(groupOptions)
                .concat(staffOptions)
                .concat(residentOptions);
            setAccessOptions(options);
            setLoading(false);
        };
        getAccessData();
    }, []);

    const handleUploadRef = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const sortData = (data: any, type: string) => {
        const sortedData = data.sort((a: any, b: any) => {
            let A: string, B: string;
            if (type === 'Staff' || type === 'Resident') {
                A = a.LastName;
                B = b.LastName;
            } else {
                A = a.Name;
                B = b.Name;
            }
            if (A < B) {
                return -1;
            }
            if (A > B) {
                return 1;
            }
            return 0;
        });
        const formatted = sortedData.map((obj: any) => {
            if (type === 'Staff' || type === 'Resident') {
                return {
                    key: obj._id,
                    text: `${obj.FirstName} ${obj.LastName} ~ ${type}`,
                    value: obj._id,
                };
            } else {
                return {
                    key: obj._id,
                    text: `${obj.Name} ~ ${type}`,
                    value: obj._id,
                };
            }
        });
        return formatted;
    };

    const handleAccessDropdownChange = (value: any) => {
        if (value.includes('All')) {
            setData({ ...data, Access: ['All'] });
        } else {
            setData({ ...data, Access: value as string[] });
        }
    };

    const handleFileUpload = (event) => {
        const fileList = event.target.files;
        setUploadedFiles([...uploadedFiles, ...fileList]);
    };

    const handleAttachedFileDelete = async (fileName: string) => {
        try {
            if (data.attachedFiles) {
                setLoading(true);
                await deleteAttachedFileData({ fileName: fileName, DocId: data._id });
                const updatedAttachedFiles = data.attachedFiles.filter((file) => file.name !== fileName);
                setData({ ...data, attachedFiles: updatedAttachedFiles });
            }
        } catch (error) {
            toast.error(`Error in deleteing attached file ${error}`, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleFileDelete = (fileName: string) => {
        const updatedFiles = uploadedFiles.filter((file) => file.name !== fileName);
        setUploadedFiles(updatedFiles);
    };

    const handleChange = (key: string, value: string) => {
        setData({ ...data, [key]: value });
    };

    const handleDelete = async (id: string) => {
        try {
            setLoading(true);
            await deleteDocWidgetData(id);
            handleCancelButton();
            getDocsData()
        } catch (error) {
            console.error(error);
            toast.error(`${error}`, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        } finally {
            setLoading(false);
        }
    };

    const uploadImageFile = async (DocId: string) => {
        if (uploadedFiles) {
            const { fileNames } = await uploadFile(DocId, uploadedFiles, true);
            return fileNames;
        } else {
            throw new Error('Image file is not provided');
        }
    };

    const handleSaveData = async () => {
        try {
            setLoading(true);
            if (!isEdit) {
                const files = uploadedFiles.map((file) => { return file.name; });
                const id = await addDocWidgetData({ ...data, files });
                if (uploadedFiles) {
                    const fileName = await uploadImageFile(id);
                    const res = await updateDocWidgetData({ _id: id, ...data, files: fileName });
                    if (!res) {
                        throw new Error('Failed to insert doc');
                    }
                }
            } else {
                if (data._id) {
                    await updateDocWidgetData(data);
                    const id = data._id;
                    if (uploadedFiles) {
                        let fileName = await uploadImageFile(id);
                        if (data.attachedFiles) {
                            const attachedFiles = data.attachedFiles.map((file: any) => file.name);
                            fileName = [...attachedFiles, ...fileName];
                        }
                        const res = await updateDocWidgetData({ _id: id, ...data, files: fileName });
                        if (!res) {
                            throw new Error('Failed to update doc');
                        }
                    }
                } else {
                    throw new Error('Failed to update doc as Id is missing');
                }
            }
            toast.success(isEdit ? 'Doc has been updated!' : 'Doc has been created!', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            getDocsData();
            handleCancelButton();
        } catch (error) {
            toast.error(`${error}`, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleCancelButton = () => {
        setData({
            Name: '',
            Access: [],
            Description: '',
            files: [],
        });
        onCancel();
    };

    const checkIfRequired = () => {
        if (!isEdit) {
            return data && data.Name && data.Name.length > 0 && uploadedFiles && uploadedFiles.length > 0 && data.Access && data.Access.length > 0;
        } else {
            return data && data.Name && data.Name.length > 0 && data.files && data.files.length > 0 && data.Access && data.Access.length > 0;
        }
    };

    return (
        <div>
            <Dimmer active={loading} inverted>
                <Loader active={loading}></Loader>
            </Dimmer>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Button
                    style={{ backgroundColor: 'rgb(24,52,102)', color: '#ffff' }}
                    content="Docs"
                    icon="file outline"
                />
                {source && <h4 style={{ textAlign: 'center' }}>{source.toUpperCase()}</h4>}
            </div>
            <Form>
                <Form.Input
                    type="text"
                    value={data.Name || ''}
                    onChange={(e) => handleChange('Name', e.currentTarget.value)}
                    placeholder="Name"
                />
                <Form.Field>
                    <Dropdown
                        closeOnEscape
                        value={data.Access || []}
                        placeholder="Access"
                        multiple
                        clearable
                        search
                        scrolling
                        selection
                        options={[{ key: 'All', text: 'All', value: 'All' },...sortByKey(accessOptions)]}
                        onChange={(e, { value }) => handleAccessDropdownChange(value)}
                    />
                </Form.Field>
                <Form.TextArea
                    value={data ? data.Description : ''}
                    placeholder="Description"
                    onChange={(e) => handleChange('Description', e.currentTarget.value)}
                />
                <Form.Input>
                    <input
                        type="text"
                        placeholder={uploadedFiles.length ? `No of Selected Files : ${uploadedFiles.length}` : 'File'}
                        readOnly
                        style={{
                            cursor: 'default',
                            border: '1px solid #ccc',
                            borderRadius: '4px 0 0 4px',
                            width: '80%',
                        }}
                    />
                    <input
                        type="file"
                        multiple
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                        onChange={handleFileUpload}
                    />
                    <Button className="ui button" onClick={handleUploadRef}>
                        Upload
                    </Button>
                </Form.Input>
                {isEdit ? (
                    <div className="deleteButtonWidget">
                        <Button
                            onClick={() => {
                                setDeleteConfirmation(true);
                            }}
                        >
                            Delete
                        </Button>
                    </div>
                ) : (
                    <></>
                )}
                {data.attachedFiles && (
                    <div className="selectedImages-class">
                        <h4>Attached Files</h4>
                        {data.attachedFiles.map((file) => (
                            <div
                                key={file.name}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    marginTop: '5px',
                                }}
                            >
                                <a
                                    href={file.attachedLink}
                                    target="_blank"
                                    download
                                    rel="noopener noreferrer"
                                    style={{ marginRight: '1rem' }}
                                >
                                    {file.name}
                                </a>
                                <Button icon="trash" color="red" onClick={() => handleAttachedFileDelete(file.name)} />
                            </div>
                        ))}
                    </div>
                )}
                <div className="selectedImages-class">
                    {uploadedFiles.length ? <h4>Selected Files</h4> : <></>}
                    {uploadedFiles.map((file) => (
                        <div
                            key={file.name}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                                marginTop: '5px',
                            }}
                        >
                            <span style={{ marginRight: '1rem' }}>{file.name}</span>
                            <Button icon="trash" color="red" onClick={() => handleFileDelete(file.name)} />
                        </div>
                    ))}
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                    <Button className="" onClick={() => handleSaveData()} disabled={checkIfRequired() ? false : true}>
                        {isEdit ? 'Update' : 'Submit'}
                    </Button>
                    <Button className="" onClick={handleCancelButton}>
                        Cancel
                    </Button>
                </div>
            </Form>
            <Modal
                onClose={() => {
                    setDeleteConfirmation(false);
                }}
                size={'tiny'}
                open={deleteConfirmation}
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <Modal.Content>
                    <Modal.Description>
                        <b>Are you sure you want to delete this doc?</b>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            setDeleteConfirmation(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        primary
                        onClick={(e) => {
                            e.preventDefault();
                            setDeleteConfirmation(false);
                            data._id && handleDelete(data._id);
                        }}
                    >
                        Delete
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
}

export default ModalComponentsDocWidget;
