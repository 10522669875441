import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Button, Dropdown, DropdownProps, Embed, Grid, Icon, Loader, Modal, Popup, Segment, List, Image, Header, } from 'semantic-ui-react'
import { fetchAllPaginatedResidents, getA4hRoomIdFromResidentId } from '../../services/Registrants'
import { AuthState, Registrant } from '../../types'
import { sendToast } from '../../util'
import UserItem from '../UserItem'
import Webcam from 'react-webcam';
import useWebSocket from '../../hooks/websocket'
import { useSelector } from 'react-redux';
import './style.less'
import { AppState } from '../../reducers'
import ResidentModalCalendarComponent from './ResidentModalCalendarComponent'
import { toast } from 'react-toastify';
import { sortByKey } from '../../util/sortData';


interface Props {
    fullScreen: boolean
    onModalClose: () => void
}
const ResidentsLiveCall: FC<Props> = ({ fullScreen, onModalClose }) => {
    const profile = useSelector(({ authReducer }: { authReducer: AuthState; }) => {
        return authReducer.profile;
    });
    const profileId = profile ? profile._id : ""
    const avatarUrl = `${process.env.PUBLIC_URL}/avatar_placeholder.svg`;
    const [residents, setResidents] = useState<Registrant[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [selectedResident, setSelectedResident] = useState<Registrant | null>()
    const localStream = useRef<MediaStream | null>(null);
    const remoteVideo = useSelector(({ remoteVideoReducer }: AppState) => {
        return remoteVideoReducer.remoteVideo
    });
    const [isCallIncoming, isOutboundCallInitiated, isCallAccepted, isCallInProgress, isReconnecting, startCall, endCall, handleIncomingCallAccecpt, caller] = useWebSocket("wss://calling.speak2.live/ws", profileId, () => localStream.current, setRegistrantToNull);
    const [activeCamera, setActiveCamera] = useState('user');
    const [availableDevices, setAvailableDevices] = useState<any>([]);
    const [initialCamera, setInitialCamera] = useState<string>(''); // State variable for the initial camera device ID
    const webcamRef = useRef(null);

    useEffect(() => {
        async function getCameraDevices() {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const cameras = devices.filter(device => device.kind === 'videoinput');
        console.log({cameras})
        setInitialCamera(cameras.length > 0 ? cameras[0].deviceId : '');
        setAvailableDevices(cameras);
        }

        getCameraDevices();
    }, []);

    function videoRefCallback(videoElement: HTMLVideoElement) {
        if (videoElement && remoteVideo) {
            videoElement.srcObject = remoteVideo;
        }
    }

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true)
                const residents = await fetchAllPaginatedResidents()
                setResidents(residents)
            } catch (error) {
                console.error("ResidentsLiveCall", error)
                const errorMessage = error instanceof Error ? error.message : "Failed to fetch residents"
                sendToast("error", errorMessage)
            } finally {
                setIsLoading(false)
            }
        })()
    }, [])

    useEffect(()=>{
        if(caller && caller.callerId) {
            const selectedResident = residents.filter(resident => resident._id === caller.callerId)[0];
            setSelectedResident(selectedResident);
        }
    }, [caller]);


    const residentsDropdownOptions = residents.map(resident => {
        return {
            key: resident._id,
            value: resident._id,
            text: `${resident.FirstName} ${resident.LastName}`
        }
    })

    const deviceOptions = availableDevices.map(device => {
        const labelParts = device.label.split('(');
        const labelText = labelParts.length > 0 ? labelParts[0].trim() : `Camera ${device.deviceId}`;
        return {
            key: device.deviceId,
            value: device.deviceId,
            text: labelText,
        };
    });

    const handleCameraChange = (data) =>{
        const { value } = data;
        if (value) {
            setActiveCamera(value);
        }
    }

    const onDropdownChange = (_, data: DropdownProps) => {
        const { value } = data
        console.log({ value })
        if (value && !isOutboundCallInitiated && !isCallInProgress && !isCallIncoming) {
            const selectedResident = residents.filter(resident => resident._id === value)[0]
            setSelectedResident(selectedResident)
        } else {
            toast.error("Cannot switch resident during call", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }

    const onPremiseChangeHandler = (onPremise: boolean) => {
        setSelectedResident((prev) => {
            if (prev) {
                return {
                    ...prev,
                    onPremise
                }
            }
            // Handle the case where prev is null.
            return null;
        })
    }

    const onSocialOptOutChangeHandler = (socialOptOut: boolean) => {
        setSelectedResident((prev) => {
            if (prev) {
                return {
                    ...prev,
                    Private: socialOptOut
                };
            }
            // Handle the case where prev is null.
            return null;
        });
    }

    const disableCameraIcon = () => {
        return !selectedResident || (!selectedResident.Unit || selectedResident.onPremise === false) || isReconnecting;
    }

    const onCameraClick = async () => {
        if (selectedResident) {
            const { a4hRoomId } = selectedResident && await getA4hRoomIdFromResidentId(selectedResident._id);
            startCall(a4hRoomId);
        } else {
            toast.warn("Please select a resident to initiate the call.",{
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }


    const CameraIcon = () => {
        if (isOutboundCallInitiated) {
            return fullScreen ? <>
                <Loader active inline size={'small'} className='modalLoader' />
                <span style={{ color: 'black', marginLeft: '5px' }}>Call Ringing...</span>  {/* the loader component is displaying white text , the loader text is not visible in the modal this is the reason we using span */}
            </> : <></>;
        }
        if (!isCallInProgress && !isOutboundCallInitiated && !isCallIncoming) {
            const isDisabled = disableCameraIcon();
            const cameraIcon = (
                fullScreen ?
                    <Icon
                        name={"video camera"}
                        color={"black"}
                        size={"large"}
                        style={{ display: "block", cursor: "pointer" }}
                        disabled={isDisabled}
                        onClick={onCameraClick}
                    /> :
                    <Image src={`${process.env.PUBLIC_URL}/icons8-circled-play-30.png`} alt="Notification Icon"
                        style={{ display: "block", cursor: "pointer" }}
                        disabled={isDisabled}
                        onClick={onCameraClick}
                    />
            );

            return isDisabled ? (
                <Popup
                    content="Please ensure that the resident is selected, on premise and associated with a room to initiate the call"
                    trigger={cameraIcon}
                />
            ) : (
                cameraIcon
            );
        } else {
            return <></>;
        }
    };

    const ResidentDropDown = () => {
        const dropDown = (
            <Dropdown
                options={sortByKey(residentsDropdownOptions)}
                placeholder="Resident"
                selection
                loading={isLoading}
                onChange={onDropdownChange}
                search
                value={selectedResident ? selectedResident._id : ""}
            />
        );

        return (
            <div style={{
                display: 'flex',
            }}>
                {dropDown}
            </div>

        )
    };

    function setRegistrantToNull() {
        setSelectedResident(null)
    }

    const EndCallButton = () => {
        return (
            (isCallInProgress || isCallIncoming || isOutboundCallInitiated) ? <Button
                color='red'
                onClick={() => {
                    setSelectedResident(null)
                    endCall(true);
                }}
                content={`${isCallIncoming ? "Reject" : "End"} Call`}
                icon='call'
                labelPosition='left'
                size={fullScreen ? "small" : "medium"}
            /> : <></>
        );
    }

    const EndResidentCallIcon = ()=>{
        return (
            (isCallInProgress || isCallIncoming || isOutboundCallInitiated || selectedResident) ? <Icon
            color='red'
            onClick={() => {
                setSelectedResident(null)
                endCall(true);
            }}
            size='big'
            name='close'
        /> : <></>
        )
    }

    const AcceptCallButton = () => {
        return (
            (isCallIncoming && !isCallAccepted) ? (
                fullScreen ? (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Header as='h2' style={{ marginRight: '10px', marginBottom: '0' }}>
                                {caller.callerName ? caller.callerName : "Unknown"}
                            </Header>
                            <Button
                                color='green'
                                onClick={handleIncomingCallAccecpt}
                                content='Accept Call'
                                icon='call'
                                labelPosition='left'
                                size={fullScreen ? "small" : "medium"}
                            />
                        </div>
                    </div>
                ) : (
                    <>
                         <Header as='h2' style={{color:"white"}}>{caller.callerName ? caller.callerName : "Unknown"}</Header>
                        <Button
                            color='green'
                            onClick={handleIncomingCallAccecpt}
                            content='Accept Call'
                            icon='call'
                            labelPosition='left'
                            size={fullScreen ? "small" : "medium"}
                        />
                    </>
                )
            ) : null
        );
    }

    const handleCameraError = (error: DOMException | string) => {
        console.error("Webcam Error", error);
        let errorMessage = "";
        if (typeof error === "string") {
            errorMessage = error
        } else {
            if (error.message.includes("Permission denied")) {
                errorMessage = "Please allow access to both camera and microphone to use the live call feature.";
            } else if (error.message.includes("NotAllowedError")) {
                errorMessage = "Please allow access to both camera and microphone to use the live call feature.";
            } else if (error.message.includes("NotFoundError")) {
                errorMessage = "Camera and microphone not found. Please ensure they are properly connected and try again.";
            } else {
                errorMessage = "An error occurred while accessing the camera and microphone. Please try again.";
            }
        }
        sendToast("error", errorMessage);
    };

    const videoConstraints = {
        width: 1280,
        height: 720,
        // facingMode: "user",
        deviceId: activeCamera
    };

    const renderResidentCall = (dontRender = false) => {
        return <Grid columns={2} padded relaxed verticalAlign='top'>
             {
                !fullScreen &&
                <Grid.Column width={7} verticalAlign="top" style={{ padding: "0px 10px" }}>
                    <div>
                    {
                            isCallInProgress
                                ? <></>
                                : !selectedResident
                                    ? <p style={{ color: "white",display:'flex' }}>Please select a resident to initiate the call.</p>
                                    : !isOutboundCallInitiated
                                            ? <>
                                                {
                                                    !isCallIncoming &&
                                                    <p style={{ color: "white", display: 'flex' }}>Waiting to call the resident...</p>
                                                }
                                            </> 
                                        : <Loader active inline='centered' size='medium' style={{ color: 'white' }}>
                                            Calling resident... Please wait.
                                        </Loader>
                        }
                    </div>
                    <div style={{ fontSize: '12px', display: 'flex', alignItems: 'center',marginTop:'5px' }}>
                        <ResidentDropDown />
                        <CameraIcon />
                        <EndResidentCallIcon/>
                    </div>
                        <div style={{ marginTop: '5px' }}>
                            {selectedResident ? <UserItem isFullScreen={fullScreen} id={selectedResident._id} name={`${selectedResident.FirstName} ${selectedResident.LastName}`} details={selectedResident.SpecialHandling || selectedResident.Notes || ''} onClick={() => { }} source="home" onPremiseChangeHandler={onPremiseChangeHandler} residentPremise={selectedResident.onPremise} roomName={(selectedResident.Unit && selectedResident.Unit.Name) || ''} onSocialOptOutChangeHandler={onSocialOptOutChangeHandler} residentSocialOptOut={selectedResident.Private || false}
                                isExternalResident={selectedResident.ExternalIntegrationSrc ? true : false}
                                isAlisResident={selectedResident.alisResidentId ? true : false}
                                externalIntegrationSource={selectedResident.ExternalIntegrationSrc}
                            /> : <></>}
                        </div>
                    <AcceptCallButton />
                    <div>
                     {!isCallInProgress && !isCallIncoming && !isOutboundCallInitiated && selectedResident &&(
                        <>
                          <div style={{ marginBottom: '5px',color:"white" }}>Select Camera:</div>
                           <Dropdown
                            options={sortByKey(deviceOptions)} 
                            selection
                            placeholder='Available Devices'
                            onChange={(_, data) => handleCameraChange(data)}
                            value={activeCamera || initialCamera} // Set the default selected camera using the activeCamera or initialCamera state
                             />
                        </>
                      )}
                    </div>
                </Grid.Column>
            }
            <Grid.Column width={fullScreen ? 16 : 9} style={{ background: "none", position: "relative", padding: 0 }}>
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        paddingTop: "56.25%", // 16:9 aspect ratio
                        position: "relative",
                    }}
                >
                    {
                        remoteVideo ? 
                        <video
                            ref={videoRefCallback}
                            autoPlay
                            playsInline
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                            }}
                        />
                        :
                        <></>
                    }
                    <div style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                    }}>
                        
                    </div>

                </div>
                <div style={{
                    position: "absolute",
                    bottom: "5px",
                    right: "5px",
                    width: "120px",
                    height: "90px",
                    borderRadius: "5px",
                    zIndex: 100,
                    overflow: "hidden",
                }}>
                    {(selectedResident || isCallIncoming || isCallInProgress) && <Webcam
                        ref={webcamRef}
                        audio={true}
                        onUserMediaError={handleCameraError}
                        videoConstraints={videoConstraints}
                        style={{ width: "100%", height: "100%", objectFit: "cover" }}
                        onUserMedia={(stream) => {
                            localStream.current = stream;
                        }}
                    />
                    }
                </div>
            </Grid.Column>       
            {(!dontRender && fullScreen) && ResidentModal()}
        </Grid>
    }

    const ResidentModal = () => {
        return (
            <Modal size='fullscreen' closeIcon onClose={onModalClose} open={fullScreen} >
                <Modal.Header>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ fontSize: '12px' }}>
                        <ResidentDropDown />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center',marginLeft:'10px', fontSize:'14px' }}>
                        <CameraIcon />
                    </div>
                        <div style={{ background: '#f0f0f094', display: 'flex', alignItems: 'center', fontSize: '14px', marginLeft: '14px', borderRadius: '4px', padding: "5px" }}>
                    <div>
                        {selectedResident && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Image avatar src={selectedResident.Image ? selectedResident.Image : avatarUrl} />
                            <span style={{paddingLeft:'9px'}}>Room {(selectedResident.Unit && selectedResident.Unit.Name) || ''}</span>
                        </div>
                        )}
                    </div>
                    <div style={{marginLeft:'12px'}}>
                        {selectedResident && (
                        <UserItem
                            isFullScreen={fullScreen}
                            id={selectedResident._id}
                            name={`${selectedResident.FirstName} ${selectedResident.LastName}`}
                            details={selectedResident.SpecialHandling || selectedResident.Notes || ''}
                            onClick={() => {}}
                            source="home"
                            onPremiseChangeHandler={onPremiseChangeHandler}
                            residentPremise={selectedResident.onPremise}
                            onSocialOptOutChangeHandler={onSocialOptOutChangeHandler}
                            residentSocialOptOut={selectedResident.Private || false}
                            roomName={(selectedResident.Unit && selectedResident.Unit.Name) || ''}
                            isExternalResident={selectedResident.ExternalIntegrationSrc ? true : false}
                            isAlisResident={selectedResident.alisResidentId ? true : false}
                            externalIntegrationSource={selectedResident.ExternalIntegrationSrc}
                        />
                        )}
                    </div>
                    </div>
                    <div style={{marginLeft:'10px',fontSize:'14px', display: 'flex', alignItems: 'center'}}>
                        <AcceptCallButton />
                        <EndCallButton />
                    </div>
                    </div>

                </Modal.Header>
                <Modal.Content>
                {
                      isCallInProgress
                            ? <></>
                                : !selectedResident
                                    ? <p style={{ color: "black",display:'flex',fontSize:'10px' }}>Please select a resident to initiate the call.</p>
                                    : !isOutboundCallInitiated && !isCallIncoming
                                        ? <p style={{ color: "black",display:'flex',fontSize:'10px'  }}>Waiting to call the resident...</p>
                                        :<></>
                        }
                    <ResidentModalCalendarComponent
                    registrant={selectedResident ? selectedResident : null} key={selectedResident ? selectedResident._id : ""}
                     videoRefCallback={videoRefCallback} isCallInProgress={isCallInProgress}
                     isOutboundCallInitiated={isOutboundCallInitiated}
                         localStream={localStream}
                     isCallIncoming={isCallIncoming}
                     handleCameraError={handleCameraError}
                     videoConstraints={videoConstraints}
                     remoteVideo={remoteVideo}
                    />
                </Modal.Content>
            </Modal>
        )
    }

    return (
        renderResidentCall()
    )
}

export default ResidentsLiveCall