import API from './API';

export async function fetchFacilityEnabledForExternalIntegration({ facilityId, integrationType }: { facilityId: string, integrationType: string; }) {
    try {       
        const response = await API.lambdaPost('/externalIntegrationFacilities/get', { facilityId, integrationType });
        return response;
    } catch (error) {
        console.log("Error in fetchFacilityEnabledForExternalIntegration", error);
        throw error;
    }
}