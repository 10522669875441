import React, { useState, useEffect, Key } from 'react'
import { useAdminPanelRequests as useAssignableRequests } from '../../hooks/adminPanel'
import { useAdminPanelRequests as useBasicRequests } from '../../hooks/basicRequestPanel'
import { Button, Grid, Modal, Tab } from 'semantic-ui-react';
// import RequestList from '../../pages/AdminPanel/Home/RequestList'
// import BasicRequestList from './BasicRequestList'
import { OrderIdColorDict, ReportSummaryFilters, Request, reportRequestType } from '../../types'
import { BasicReportSummaryFilters } from '../../types'
import './style.less'
import { withRouter } from 'react-router'
// import ReportSummary from './ReportSummary'
// import ReportSummaryBasics from './ReportSummaryBasics'
import ReportSummaryAll from './ReportSummaryAll'
import AllRequestList from './AllRequestList'
import Requests from '../Requests';
import { formatRequestsDictForCalendarEvents, getOrderIdColor } from '../../util/requests';

const AllRequests = (props) => {
    const [upcomingRequestModal, setUpcomingRequestsModal] = useState<boolean>(false);
    const [openRequestModal, setOpenRequestModal] = useState<boolean>(false);
    const [showClosedAccReqModal, setShowClosedAccReqModal] = useState<boolean>(false);
    const [showAcceptedReqModal, setShowAcceptedReqModal] = useState<boolean>(false);
    const [showClosedReqModal, setShowClosedReqModal] = useState<boolean>(false);
    const [assignableRequestFilters, setAssignableRequestFilters] = useState<ReportSummaryFilters>({
        requestType: 'active',
        fromDate: (() => {
            const fromDate = new Date();
            fromDate.setDate(fromDate.getDate() - 7); // Subtract 7 days from the current date
            return fromDate;
        })(),
        toDate: (() => {
            const toDate = new Date();
            toDate.setDate(toDate.getDate() + 7); // Add 7 days to the current date
            return toDate;
        })(),
        disable: false,
        staff: 'all',
        resident: 'all',
        department: 'all',
        reload: 0,
        FetchCancelRequests: false
    })

    const [basicRequestFilters, setBasicRequestFilters] = useState<BasicReportSummaryFilters>({
        fromDate: new Date(),
        toDate: new Date(),
        resident: 'all',
        food: 'all',
        type: 'all',
        disable: false,
        reload: 0
    })
    const [sortBy, setSortBy] = useState([{ RequestedTime: 'asc' }]); // This should be a state nd not a normal variable because it ie used as a dependency in useEffects
    // const [upcomingRequestsDuePageNo, setUpcomingRequestsDuePageNo] = useState<number>(1);
    const [openPageNo, setOpenPageNo] = useState<number>(1);
    const [acceptedPageNo, setAcceptedPageNo] = useState<number>(1);
    const [closedPageNo, setClosedPageNo] = useState<number>(1);
    const [upcomingRequestsDueFetchAllData, setUpcomingRequestsDueFetchAllData] = useState<boolean>(false);
    const [openFetchAllData, setOpenFetchAllData] = useState<boolean>(false);
    const [acceptedFetchAllData, setAcceptedFetchAllData] = useState<boolean>(false);
    const [closedFetchAllData, setClosedFetchAllData] = useState<boolean>(false);
    const [isUpcomingReqListView, setIsUpcomingReqListView] = useState<boolean>(true);
    const [isOpenReqListView, setIsOpenReqListView] = useState<boolean>(true);
    const [isAcceptedAndClosedReqListView, setIsAcceptedAndClosedReqListView] = useState<boolean>(true);
    const [isAcceptedListView , setIsAcceptedListView] = useState<boolean>(true);
    const [isClosedView, setIsClosedView] = useState<boolean>(true);
    const requestSortOptions = [{ key: "asc", value: "desc", text: "Oldest" }, { key: "desc", value: "asc", text: "Newest" }]; // The backend is faulty. If we send descending we get ascending values and if we send ascending we get descending value so we have shifted it
    const [selectedOpenSortingValue, setSelectedOpenSortingValue] = useState<string>('asc');
    const [selectedAcceptedSortingValue, setSelectedAcceptedSortingValue] = useState<string>('asc');
    const [selectedClosedSortingValue, setSelectedClosedSortingValue] = useState<string>('asc');  
    const [orderIdColorDict, setOrderIdColorDict] = useState<OrderIdColorDict>({});
    const [alreadyGeneratedColors, setAlreadyGeneratedColors] = useState<string[]>([]);

    // const {
    //     requests: upcomingRequestsDue,
    //     error: upcomingRequestsDueError,
    //     isFetching: upcomingRequestsDueIsFetching,
    //     isPaginating: upcomingRequestsDueIsPaginating,
    //     hasMorePages: upcomingRequestsDueHasMorePages,
    //     totalDataLength: upcomingRequestsDueTotalDataLength,
    //     requestsCategoryDepCountBasedOnDateDict: upcomingRequestsDueCalendarRequests
    // } = useAssignableRequests(assignableRequestFilters, sortBy, true, false, upcomingRequestsDuePageNo, 20, props.history, upcomingRequestsDueFetchAllData, "openRequests", true)

    const {
        requests: openRequests,
        error: openError,
        isFetching: openIsFetching,
        isPaginating: openIsPaginating,
        hasMorePages: openHasMorePages,
        totalDataLength: openTotalDataLength,
        requestsCategoryDepCountBasedOnDateDict: openCalendarRequests
    } = useAssignableRequests(assignableRequestFilters, selectedOpenSortingValue === "asc" ? [{ RequestedTime: "asc" }] : [{ RequestedTime: "desc" }], true, false, openPageNo, 20, props.history, openFetchAllData, "openRequests")

    const {
        requests: acceptedRequests,
        error: acceptedError,
        isFetching: acceptedIsFetching,
        isPaginating: acceptedIsPaginating,
        hasMorePages: acceptedHasMorePages,
        totalDataLength: acceptedTotalDataLength,
        requestsCategoryDepCountBasedOnDateDict: acceptedCalendarRequests
    } = useAssignableRequests(assignableRequestFilters, selectedAcceptedSortingValue === "asc" ? [{ RequestedTime: "asc" }] : [{ RequestedTime: "desc" }], true, false, acceptedPageNo, 20, props.history, acceptedFetchAllData, "open");

    const {
        requests: closedRequests,
        error: closedError,
        isFetching: closedIsFetching,
        isPaginating: closedIsPaginating,
        hasMorePages: closedHasMorePages,
        totalDataLength: closedTotalDataLength,
        requestsCategoryDepCountBasedOnDateDict: closedCalendarRequests
    } = useAssignableRequests(assignableRequestFilters, selectedClosedSortingValue === "asc" ? [{ RequestedTime: "asc" }] : [{ RequestedTime: "desc" }], true, false, closedPageNo, 20, props.history, closedFetchAllData, "closed");

    useEffect(() => {
        const getQueryParams = () => {
            const queryParams = new URLSearchParams(props.location.search);
            const expandUpcomingReqDue = queryParams.get('expandUpcomingReqDue');
            const expandOpen = queryParams.get('expandOpen');
            const expandAccClosed = queryParams.get('expandAccClosed');
            const expandAccepted = queryParams.get('expandAccepted');
            const expandClosed = queryParams.get('expandClosed');

            setUpcomingRequestsModal(expandUpcomingReqDue === 'true');
            setOpenRequestModal(expandOpen === 'true');
            setShowClosedAccReqModal(expandAccClosed === 'true');
            setShowAcceptedReqModal(expandAccepted === 'true');
            setShowClosedReqModal(expandClosed === 'true');
        };

        getQueryParams();
    }, [props.location]);

    // const { //this is commented in case we want to revert back to the old way of showing requests
    //     requests: basicRequests,
    //     error: basicError,
    //     isFetching: basicIsFetching,
    //     isPaginating: basicIsPaginating,
    //     hasMorePages: basicHasMorePages,
    // } = useBasicRequests(basicRequestFilters, sortBy, true, false, pageNo, 40, props.history)


    const acceptedCalendarRequestsArr = formatRequestsDictForCalendarEvents(acceptedCalendarRequests);
    const closedCalendarRequestsArr = formatRequestsDictForCalendarEvents(closedCalendarRequests);
    // const upcomingRequestsDueCalendarRequestsArr = formatRequestsDictForCalendarEvents(upcomingRequestsDueCalendarRequests);
    const openCalendarRequestsArr = formatRequestsDictForCalendarEvents(openCalendarRequests);

    // const upcomingRequestsDueList: Request[] = [...upcomingRequestsDue];
    const openRequestsList: Request[] = [...openRequests]; //, ...basicRequests] // this is commented in case we want to revert back to the old way of showing requests
    const acceptedRequestsList: Request[] = [...acceptedRequests];
    const closedRequestsList: Request[] = [...closedRequests];
    const allRequests: Request[] = [...openRequestsList, ...acceptedRequestsList, ...closedRequestsList];
    allRequests.forEach((request) => {
        getOrderIdColor(orderIdColorDict, setAlreadyGeneratedColors, setOrderIdColorDict, alreadyGeneratedColors, request.OrderId);
    }
    );

    // sort by descending order based on date created
    // openRequestsList.sort(function (a, b) {
    //     return b.RequestedTime - a.RequestedTime;
    // });
    // const panes = [
    //     {
    //         menuItem: 'In Progress',
    //         render: () => (
    //             <Tab.Pane attached={false}>
    //                 <AllRequestList
    //                     requests={allRequests}
    //                     isFetching={assignableIsFetching} // || basicIsFetching} // this is commented in case we want to revert back to the old way of showing requests
    //                     isPaginateLoading={assignableIsPaginating} // || basicIsPaginating} // this is commented in case we want to revert back to the old way of showing requests
    //                     nextPage={() => {
    //                         setPageNo(pageNo + 1)
    //                     }}
    //                     hasMorePage={assignableHasMorePages} // || basicHasMorePages} // this is commented in case we want to revert back to the old way of showing requests
    //                 />
    //             </Tab.Pane>
    //         ),
    //     },
    // ]

    const onUpcomingReqViewChange = (isListView: boolean) => {
        setIsUpcomingReqListView(isListView);
    };

    const onOpenReqViewChange = (isListView: boolean) => {
        setIsOpenReqListView(isListView);
    };

    const onAcceptedAndClosedReqViewChange = (isListView: boolean) => {
        setIsAcceptedAndClosedReqListView(isListView);
    };

    const onAcceptedReqViewChange = (isListView: boolean) => {
        setIsAcceptedListView(isListView);
    }

    const onClosedReqViewChange = (isListView: boolean) => {
        setIsClosedView(isListView);
    }

    // const renderUpcomingRequestsDue = () => {
    //     return <Requests
    //         requests={ upcomingRequestsDueFetchAllData ? upcomingRequestsDueCalendarRequestsArr : upcomingRequestsDueList}
    //         count={upcomingRequestsDueTotalDataLength}
    //         setOpenFetchAllData={setUpcomingRequestsDueFetchAllData}
    //         limit={10}
    //         hasMorePages={upcomingRequestsDueHasMorePages}
    //         isFetching={upcomingRequestsDueIsFetching || upcomingRequestsDueIsPaginating}
    //         hasError={upcomingRequestsDueError || false}
    //         errorCode={null}
    //         userId={null}
    //         unassigned={false}
    //         onFetchRequests={() => { }}
    //         setPageNo={setUpcomingRequestsDuePageNo}
    //         page={upcomingRequestsDuePageNo}
    //         history={props.history}
    //         refresh={() => setAssignableRequestFilters({
    //             ...assignableRequestFilters,
    //             reload: assignableRequestFilters.reload + 1,
    //         })}
    //         location={props.location}
    //         match={props.match}
    //         powerAndFacilityUsers={true}
    //         requestTitle="Upcoming Requests Due"
    //         modalView={upcomingRequestModal || openRequestModal || showClosedAccReqModal || false}
    //         onViewUpdate={onUpcomingReqViewChange}
    //         isListView={isUpcomingReqListView}
    //         isUpcomingRequestsDue={true}
    //     />
    // }

    const renderOpenRequests = () => {
        return <Requests
            requests={ openFetchAllData ? openCalendarRequestsArr : openRequestsList}
            count={openTotalDataLength}
            setOpenFetchAllData={setOpenFetchAllData}
            limit={10}
            hasMorePages={openHasMorePages}
            isFetching={openIsFetching || openIsPaginating}
            hasError={openError || false}
            errorCode={null}
            userId={null}
            unassigned={false}
            onFetchRequests={() => { }}
            setPageNo={setOpenPageNo}
            page={openPageNo}
            history={props.history}
            refresh={() => setAssignableRequestFilters({
                ...assignableRequestFilters,
                reload: assignableRequestFilters.reload + 1,
            })}
            location={props.location}
            match={props.match}
            powerAndFacilityUsers={true}
            requestTitle="Open Requests"
            modalView={openRequestModal || upcomingRequestModal || showClosedAccReqModal || false}
            onViewUpdate={onOpenReqViewChange}
            isListView={isOpenReqListView}
            showSort={true}
            requestSortOptions={requestSortOptions}
            sortDropdownValue={selectedOpenSortingValue}
            setSortDropdownValue={setSelectedOpenSortingValue}
            orderIdColorDict={orderIdColorDict}
        />;
    };

    const renderAcceptedRequests = () => {
        return <Requests
            requests={[]}
            count={0}
            limit={40}
            hasMorePages={acceptedHasMorePages}
            isFetching={false}
            hasError={false}
            errorCode={null}
            userId={null}
            onFetchRequests={() => { }}
            setPageNo={setAcceptedPageNo}
            setAcceptedAndClosedFetchAllData={setAcceptedFetchAllData}
            page={acceptedPageNo}
            history={props.history}
            location={props.location}
            match={props.match}
            powerAndFacilityUsers={true}
            requestTitle="Accepted Requests"
            refresh={() => setAssignableRequestFilters({
                ...assignableRequestFilters,
                reload: assignableRequestFilters.reload + 1,
            })}
            unassignedRequests={acceptedFetchAllData ? acceptedCalendarRequestsArr : acceptedRequestsList}
            unassignedRequestCount={acceptedTotalDataLength}
            unassignedRequestLimit={40}
            unassignedRequestHasError={acceptedError || false}
            unassignedRequestErrorCode={null}
            unassignedRequestIsFetching={acceptedIsFetching || acceptedIsPaginating}
            unassigned={true}
            modalView={openRequestModal || upcomingRequestModal || showClosedAccReqModal || showAcceptedReqModal || false}
            onViewUpdate={onAcceptedReqViewChange}
            isListView={isAcceptedListView}
            acceptedRequest={true}
            showSort={true}
            requestSortOptions={requestSortOptions}
            sortDropdownValue={selectedAcceptedSortingValue}
            setSortDropdownValue={setSelectedAcceptedSortingValue}
            orderIdColorDict={orderIdColorDict}
        />;
    }


    const renderClosedRequests = () => {
        return <Requests
            requests={[]}
            count={0}
            limit={40}
            hasMorePages={closedHasMorePages}
            isFetching={false}
            hasError={false}
            errorCode={null}
            userId={null}
            onFetchRequests={() => { }}
            setPageNo={setClosedPageNo}
            setAcceptedAndClosedFetchAllData={setClosedFetchAllData}
            page={closedPageNo}
            history={props.history}
            location={props.location}
            match={props.match}
            powerAndFacilityUsers={true}
            requestTitle="Closed Requests"
            refresh={() => setAssignableRequestFilters({
                ...assignableRequestFilters,
                reload: assignableRequestFilters.reload + 1,
                FetchCancelRequests: false
            })}
            unassignedRequests={closedFetchAllData ? closedCalendarRequestsArr : closedRequestsList}
            unassignedRequestCount={closedTotalDataLength}
            unassignedRequestLimit={40}
            unassignedRequestHasError={closedError || false}
            unassignedRequestErrorCode={null}
            unassignedRequestIsFetching={closedIsFetching || closedIsPaginating}
            unassigned={true}
            modalView={openRequestModal || upcomingRequestModal || showClosedAccReqModal || showAcceptedReqModal || showClosedReqModal || false}
            onViewUpdate={onClosedReqViewChange}
            isListView={isClosedView}
            closedRequest={true}
            showSort={true}
            requestSortOptions={requestSortOptions}
            sortDropdownValue={selectedClosedSortingValue}
            setSortDropdownValue={setSelectedClosedSortingValue}
            orderIdColorDict={orderIdColorDict}
        />;
    }

    return (
        <div>
            <div className="requests">
                <ReportSummaryAll
                    filters={[assignableRequestFilters, basicRequestFilters]}
                    handleFilterChangeAssignable={(updatedFilters) => {
                        setAssignableRequestFilters(updatedFilters);
                        setOpenPageNo(1);
                        // setUpcomingRequestsDuePageNo(1);
                        setAcceptedPageNo(1);
                        setClosedPageNo(1);
                    }}
                    handleFilterChangeBasic={(updatedFilters) => {
                        setBasicRequestFilters(updatedFilters);
                        setOpenPageNo(1);
                        // setUpcomingRequestsDuePageNo(1);
                        setAcceptedPageNo(1);
                        setClosedPageNo(1);
                    }}
                    shouldCreateRequestBeShown={true}
                    history={props.history}
                    profile={props.profile}
                    isFetching={openIsFetching || acceptedIsFetching || closedIsFetching}
                    importedBy='Requests'
                    requests={[...acceptedRequestsList, ...closedRequestsList, ...openRequestsList]}
                    blockCloseInView={!isOpenReqListView || !isAcceptedAndClosedReqListView || !isUpcomingReqListView}
                />


                {openRequestModal && (
                    <Modal open={openRequestModal} onClose={() => {
                        props.history.push("/admin/requests");
                    }} size='fullscreen' dimmer="blurring">
                        <Modal.Content>
                            {renderOpenRequests()}
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => {
                                props.history.push("/admin/requests");
                            }}>Close</Button>
                        </Modal.Actions>
                    </Modal>
                )}

                {showAcceptedReqModal && (
                    <Modal open={showAcceptedReqModal} onClose={() => {
                        props.history.push("/admin/requests");
                    }} size='fullscreen' dimmer="blurring">
                        <Modal.Content>
                            {renderAcceptedRequests()}
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => {
                                props.history.push("/admin/requests");
                            }}>Close</Button>
                        </Modal.Actions>
                    </Modal>
                )}

                {showClosedReqModal && (
                    <Modal open={showClosedReqModal} onClose={() => {
                        props.history.push("/admin/requests");
                    }
                    } size='fullscreen' dimmer="blurring">
                        <Modal.Content>
                            {renderClosedRequests()}
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => {
                                props.history.push("/admin/requests");
                            }}>Close</Button>
                        </Modal.Actions>
                    </Modal>
                )}  

                {!upcomingRequestModal && !openRequestModal && !showAcceptedReqModal && !showClosedAccReqModal ? <Grid columns={2} style={{ marginTop: '2rem' }} >
                    <Grid.Row>
                        <Grid.Column>
                            {/* {renderUpcomingRequestsDue()} */}
                            {renderOpenRequests()}
                        </Grid.Column>
                        <Grid.Column>
                            {renderAcceptedRequests()} 
                            {renderClosedRequests()}
                        </Grid.Column>
                    </Grid.Row>
                </Grid> : null}
            </div>
        </div>
    )
}

export default withRouter(AllRequests)
