import React, { FC } from 'react';
import { Icon } from 'semantic-ui-react';
import './style.less';

interface Props {
    info: any;
};
const CalendarEventContent: FC<Props> = ({info}) => {
    const hasAudio = info.event.classNames.includes("audio");
    return <div className="audio-event">
            <div className="fc-daygrid-event-dot" style={{ borderColor: info.backgroundColor }}></div>
            <div className="fc-event-time">{info.timeText}</div>
            <p className='para' id='extended-box' style={{
                color: `${info.backgroundColor}`,
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
            }}>{info.event.title}</p>
            {hasAudio && <Icon name='microphone' />}
    </div>;
};

export default CalendarEventContent;