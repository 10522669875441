import { fetchRegistrants, fetchResidentCalendarEvents } from '../../services/Registrants';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import { Registrant, ResidentCalendar } from '../../types';
import React from 'react';
import { Button, Card, Dimmer, Grid, Loader, Message, Popup } from 'semantic-ui-react';
import { fetchAllStaffUsers } from '../../services/Users';
import { fetchResidentGroups } from '../../services/RoomGroups';
import Webcam from 'react-webcam';
import ListCalendar from '../ListCalendar';
import ResidentCalendarForm from '../ResidentCalendar/ResidentCalendarForm';
import { toast } from 'react-toastify';

type RoomOptions = {
    key: string;
    text: string;
    value: string;
};
interface State {
    showError: boolean;
    errorMessage: string;
    showResidentCalendarCreateModal: boolean;
    isLoadingResidentCalendar: boolean;
    residentCalendarDate: any;
    residentCalendarEntries: any;
    residentCalendarData: Partial<ResidentCalendar>;
    residentOptions: RoomOptions[] | [];
    staffOptions: RoomOptions[] | [];
    duration: number;
    isEdit: boolean;
    deleteResidentCalendarConfirmation: boolean;
    facilityTZ: string;
    pageSource: string;
}
interface Props {
    registrant: Registrant | null;
    videoRefCallback: any;
    isCallInProgress: any;
    isOutboundCallInitiated: any;
    isCallIncoming: any;
    handleCameraError: any;
    videoConstraints: any;
    localStream: any;
    remoteVideo: any;
}

class ResidentModalCalendarComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showError: false,
            errorMessage: '',
            showResidentCalendarCreateModal: false,
            isLoadingResidentCalendar: false,
            residentCalendarEntries: [],
            residentCalendarDate: moment().hour(9).minute(0).second(0).toDate(),
            residentCalendarData: {},
            residentOptions: [],
            staffOptions: [],
            duration: 0,
            isEdit: false,
            deleteResidentCalendarConfirmation: false,
            facilityTZ: 'America/New_York',
            pageSource: 'ResidentCalendar'
        };
    }

    setResdientCalendarDataField(key: keyof ResidentCalendar, value: any) {
        if (key === 'registrantIds') {
            if (Array.isArray(value)) {
                const handleGroups = value.map((residentId) => {
                    if (residentId.indexOf(',') !== -1) {
                        return residentId.split(',');
                    }
                    return residentId;
                });
                const rooms = handleGroups.flat();
                console.log({ rooms });
                this.setState({
                    residentCalendarData: {
                        ...this.state.residentCalendarData,
                        [key]: rooms,
                    },
                });
            }
            return;
        }
        this.setState(
            {
                residentCalendarData: {
                    ...this.state.residentCalendarData,
                    [key]: value,
                },
            },
            () => {
                // we need to update endDateTimeString whenever dateTimeString changes
                if (key === 'dateTimeString') {
                    const duration = this.state.duration;
                    const endDateTimeString = moment(this.state.residentCalendarData.dateTimeString)
                        .add(duration, 'm')
                        .format('YYYY-MM-DDTHH:mm:ss');
                    this.setResdientCalendarDataField('endDateTimeString', endDateTimeString);
                }
            },
        );
    }

    displayError = (errorMessage: string) => {
        if (errorMessage != "") {
            toast.error(errorMessage, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        }
    };

    fetchResidentCalendar = async (registrantId: string | null, date: Date = this.state.residentCalendarDate) => {
        this.setState({ isLoadingResidentCalendar: true });
        // fetch data for the seleted resident and date
        this.setState({
            residentCalendarDate: date,
        });
        const formattedDate = moment(date).format('YYYY-MM-DD');
        const startDate = formattedDate + 'T00:00:00'; // YYYY-MM-DDTHH:mm:ss
        const endDate = formattedDate + 'T23:59:59'; // YYYY-MM-DDTHH:mm:ss
        try {
            const data = await fetchResidentCalendarEvents(registrantId, startDate, endDate);
            this.setState({
                residentCalendarEntries: data,
            });
        } catch (error) {
            // do not throw error
            this.displayError('Unabled to fetch notepad events');
            console.error(error);
        }
        this.setState({ isLoadingResidentCalendar: false });
    };

    refreshResidentRooms = async () => {
        this.setState({ isLoadingResidentCalendar: true });
        // fetch residents of the facility and store in state
        try {
            const staffDetails = await fetchAllStaffUsers();
            const formatedStaffOptions = this.formatAndSortStaff(staffDetails);
            const residentsWithRooms = await fetchRegistrants();
            const formattedResidentOptions = this.formatAndSortResidents(residentsWithRooms);
            const roomGroups = await fetchResidentGroups();
            const sortedRoomGroups = this.formatAndSortRoomGroups(roomGroups);
            const roomOptions = [].concat(sortedRoomGroups).concat(formattedResidentOptions);
            this.setState({ residentOptions: roomOptions, staffOptions: formatedStaffOptions });
        } catch (error) {
            // do not throw error
            console.error(error);
            toast.error('Something went wrong', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        }
        this.setState({ isLoadingResidentCalendar: false });
    };

    formatAndSortResidents(residents) {
        const formattedResidents = residents.map((obj) => {
            return {
                key: `${obj._id}`,
                text:
                    obj.FirstName && obj.LastName
                        ? `${obj.FirstName} ${obj.LastName}`
                        : obj.FirstName
                        ? `${obj.FirstName}`
                        : `${obj.FirstName}`,
                value: `${obj._id}`,
            };
        });

        const sortedResidents = formattedResidents.sort((a, b) => {
            const A = a.text.toUpperCase();
            const B = b.text.toUpperCase();

            if (A < B) {
                return -1;
            }
            if (A > B) {
                return 1;
            }
            return 0;
        });

        return sortedResidents;
    }

    formatAndSortStaff = (staff) => {
        const formattedStaffs = staff.map((obj) => {
            return {
                key: `${obj._id}`,
                text:
                    obj.FirstName && obj.LastName
                        ? `${obj.FirstName} ${obj.LastName}`
                        : obj.FirstName
                            ? `${obj.FirstName}`
                            : `${obj.LastName}` || "",
                value: `${obj._id}`,
            };
        });

        const sortedStaffs = formattedStaffs.sort((a, b) => {
            const A = a.text.toUpperCase();
            const B = b.text.toUpperCase();
            if (A < B) {
                return -1;
            }
            if (A > B) {
                return 1;
            }
            return 0;
        });

        return sortedStaffs;
    };

    formatAndSortRoomGroups = (groups: any) => {
        const formattedRoomGroups = groups
            .map((obj) => {
                if (obj.ResidentIds && obj.ResidentIds.length <= 1) return; // don't show groups which does not have atleast 2 ResidentIds (To take care of wierd issues with residentGroups)
                return {
                    key: `${obj._id}`,
                    text: `${obj.Name} group`,
                    value: `${[obj.ResidentIds]}`,
                };
            })
            .filter((obj) => obj);

        const sortedRoomGroups = formattedRoomGroups.sort((a, b) => {
            const A = a.text.toUpperCase();
            const B = b.text.toUpperCase();

            if (A < B) {
                return -1;
            }
            if (A > B) {
                return 1;
            }
            return 0;
        });

        return sortedRoomGroups;
    };

    resetResidentCalendarData = () => {
        this.setState({
            residentCalendarData: {},
            isEdit: false,
        });
    };

    refreshResidentCalendar = async () => {
        try {
            await this.fetchResidentCalendar(this.props.registrant && this.props.registrant._id, this.state.residentCalendarDate);
        } catch (error) {
            console.error(error);
            this.displayError('Unable to refresh notepad');
        }
    };

    setShowResidentCalendarModal = (show: boolean, isEdit: boolean, dateRange: { start: string, end: string} | null = null, event: ResidentCalendar| null = null) => {
        if (isEdit) {
            console.log({event})
            if (event) {
                const duration = event.endDateTimeString
                    ? Math.floor((Date.parse(event.endDateTimeString) - Date.parse(event.dateTimeString)) / 1000 / 60)
                    : 0;
                this.setState({
                    residentCalendarData: {
                        ...this.state.residentCalendarData,
                        ...event,
                    },
                    duration:duration,
                    isEdit: true,
                });
            }
        } else {
            if (dateRange) {
                this.setState({
                    residentCalendarData: {
                        ...this.state.residentCalendarData,
                        dateTimeString: dateRange.start,
                        endDateTimeString: dateRange.end,
                    },
                    isEdit: false,
                });
            }
        }
        this.setState({
            showResidentCalendarCreateModal: show,
        });
    }

    async componentDidMount() {
        try {
            await Promise.all([
                this.refreshResidentRooms(),
                this.fetchResidentCalendar(this.props.registrant && this.props.registrant._id, new Date()),
            ]);
        } catch (error) {
            console.error(error);
            this.displayError('Unable to refresh notepad');
        }
        if (this.props && this.props.registrant) {
            this.setState({ isLoadingResidentCalendar: true });
            const currentResident = this.state.residentOptions.find(
                (resident) => resident.key === (this.props && this.props.registrant && this.props.registrant._id),
            );
            if (currentResident && typeof currentResident.value === 'string') {
                this.setState({
                    residentCalendarData: {
                        ...this.state.residentCalendarData,
                        registrantIds: [currentResident.value],
                    },
                });
            }
            this.setState({ isLoadingResidentCalendar: false });
        }
    }

    render() {
        return (
            <>
                <ResidentCalendarForm
                    setLoader={(value: boolean) => this.setState({ isLoadingResidentCalendar: value })}
                    showModal={this.state.showResidentCalendarCreateModal}
                    closeForm={() => this.setState({ showResidentCalendarCreateModal: false })}
                    isEdit={this.state.isEdit}
                    residentCalendarData={this.state.residentCalendarData}
                    setResdientCalendarDataField={this.setResdientCalendarDataField.bind(this)}
                    displayError={this.displayError}
                    refresh={this.refreshResidentCalendar}
                    resetResidentCalendarData={this.resetResidentCalendarData}
                    residentOptions={this.state.residentOptions}
                    staffOptions={this.state.staffOptions}
                    duration={this.state.duration}
                    setDuration={(duration: number) => this.setState({ duration })}
                    isStaff={false}
                    pageSource={this.state.pageSource}
                />
                <Grid>
                    <Grid.Column width={10}>
                        <Grid.Row>
                            <Grid.Column width={16} style={{ background: 'none', position: 'relative', padding: 0 }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        paddingTop: '56.25%', // 16:9 aspect ratio
                                        position: 'relative',
                                    }}
                                >
                                    {this.props.remoteVideo ? (
                                        <video
                                            ref={this.props.videoRefCallback}
                                            autoPlay
                                            playsInline
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                            }}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            background: '#9a949480',
                                            objectFit: 'cover',
                                        }}
                                    ></div>
                                </div>
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '5px',
                                        right: '5px',
                                        width: '120px',
                                        height: '90px',
                                        borderRadius: '5px',
                                        zIndex: 100,
                                        overflow: 'hidden',
                                    }}
                                >
                                    {(this.props.registrant ||
                                        this.props.isCallIncoming ||
                                        this.props.isCallInProgress) && (
                                        <Webcam
                                            audio={true}
                                            onUserMediaError={this.props.handleCameraError}
                                            videoConstraints={this.props.videoConstraints}
                                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                            onUserMedia={(stream) => {
                                                this.props.localStream.current = stream;
                                            }}
                                        />
                                    )}
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                {this.props.registrant && (
                                    <Card.Group itemsPerRow={3} style={{ marginTop: '5px', height: '258px' }}>
                                        <Card>
                                            <Card.Content>
                                                <Card.Header style={{ fontSize: '10px' }}>Quick Order</Card.Header>
                                                <Card.Description
                                                    style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%) rotate(-45deg)',
                                                    }}
                                                >
                                                    Comming Soon
                                                </Card.Description>
                                            </Card.Content>
                                        </Card>
                                        <Card>
                                            <Card.Content>
                                                <Card.Header style={{ fontSize: '10px' }}>Order status</Card.Header>
                                                <Card.Description
                                                    style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%) rotate(-45deg)',
                                                    }}
                                                >
                                                    Comming Soon
                                                </Card.Description>
                                            </Card.Content>
                                        </Card>
                                        <div style={{ padding: '10px' }}>
                                            <DatePicker
                                                open={true}
                                                onChange={async (date: Date) => {
                                                    await this.fetchResidentCalendar(
                                                        this.props &&
                                                            this.props.registrant &&
                                                            this.props.registrant._id &&
                                                            this.props.registrant._id,
                                                        date,
                                                    );
                                                }}
                                                style={{ border: '1px solid #183466' }}
                                                inline
                                                selected={this.state.residentCalendarDate}
                                                onMonthChange={async (date) => {
                                                    this.setState({
                                                        residentCalendarDate: date,
                                                    });
                                                    await this.fetchResidentCalendar(
                                                        this.props &&
                                                            this.props.registrant &&
                                                            this.props.registrant._id &&
                                                            this.props.registrant._id,
                                                        date,
                                                    );
                                                }}
                                            />
                                        </div>
                                    </Card.Group>
                                )}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Grid.Column style={{ height: '100vh', overflowY: 'auto' }}>
                            {this.props.registrant && (
                                <>
                                    <Dimmer active={this.state.isLoadingResidentCalendar} inverted>
                                        <Loader />
                                    </Dimmer>
                                    <Popup
                                        trigger={<Button className='searchBtnPlus' icon='plus' size='large' onClick={() =>  this.setShowResidentCalendarModal(true, false)} loading={false} />}
                                        content="Create a calendar event                                        "
                                    />
                                    <ListCalendar events={this.state.residentCalendarEntries} currentDay={this.state.residentCalendarDate} setShowResidentCalendarModal={this.setShowResidentCalendarModal}/>
                                </>
                            )}
                        </Grid.Column>
                    </Grid.Column>
                </Grid>
            </>
        );
    }
}

export default ResidentModalCalendarComponent;
